/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import 'firebase/auth';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import crud from '../../../api/crud';
import redemptions from '../../../api/redemptions';

import ContentWrapper from '../../global/ContentWrapper';
import { useAlert } from '../../providers/AlertProvider';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';

const statusMap = {
  1: {
    redemptionStatus: 'claimed',
    statusLabel: 'Registered',
    iconBg: 'bg-primary',
  },
  2: {
    redemptionStatus: 'returned',
    statusLabel: 'Processed',
    iconBg: 'bg-success',
  },
  3: {
    redemptionStatus: 'pending',
    statusLabel: 'In-Transit',
    iconBg: 'bg-success',
  },
};

const Customers = () => {
  const setAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);

  // data
  const [takeBackBags, setTakeBackBags] = useState([]);

  // start - filters
  const [emailFilter, setEmailFilter] = useState('');
  const [dateFilterStart, setDateFilterStart] = useState(null);
  const [dateFilterEnd, setDateFilterEnd] = useState(null);

  // start table and pagination
  const [tableInfo, setTableInfo] = useState({});
  const [intPageIndex, setIntPageIndex] = useState(0);
  const [intPageSize, setIntPageSize] = useState(100);

  // firebase user
  const { user } = useFirebaseAuth();

  // parse the data and prepare to export the csv function
  const { data, csvData } = React.useMemo(
    () => {
      if (!takeBackBags) {
        return {
          data: [],
          csvData: [],
        };
      }

      const { data: thisData, csvData: thisCsvData } = takeBackBags.reduce((acc, item) => {
        // parse the data for de csv export
        const newArr = [
          item?.email || '-',
          moment(item.updatedAt).format('YYYY-MM-DD'),
          statusMap[item.statusId].statusLabel,
          item?.code.split('-')[0] || 'N/A',
          item?.couponData || 'N/A',
        ];
        return (
          {
            data: [...acc.data, {
              id: <Form.Check />,
              email: item.email,
              updatedAt: moment(item.updatedAt).format('YYYY-MM-DD'),
              status: statusMap[item.statusId].statusLabel,
              code: item?.code.split('-')[0] || 'N/A',
            }],
            csvData: [...acc.csvData, newArr],
          }
        );
      }, { data: [], csvData: [] });

      // columns are added to the csv object
      thisCsvData.unshift(['Email', 'Redemption Date', 'Status', 'Code', 'Coupon Code']);

      // returns the data for the table and the CSV object
      return { data: thisData, csvData: thisCsvData };
    },
    [takeBackBags],
  );

  // Event and email validation
  const handleEmailFilterChange = debounce((e) => {
    const intEmailInput = e.target.value;
    if (intEmailInput === '' || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(intEmailInput))) {
      setEmailFilter(intEmailInput);
    }
  }, 500);

  // Event for the date range field
  const handleDateFilterStartChange = debounce((dates) => {
    const [start, end] = dates;
    setDateFilterStart(start);
    setDateFilterEnd(end);
  }, 500);

  // Table base configuration
  const table = useReactTable({
    data,
    columns: [
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Redemption Date',
        accessorKey: 'updatedAt',
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
      {
        header: 'Code',
        accessorKey: 'code',
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(32),
    pageCount: tableInfo.nPages,
  });

  // This function is responsible for communicating with the back to obtain the
  // data and configuration of the table for paging
  const fetchAndSetRedemptions = async (pageIndex = 0, pageSize = 100) => {
    if (!user || !user.uid) {
      return;
    }

    setIsLoading(true);
    setIntPageSize(pageSize);
    setIntPageIndex(pageIndex < 0 ? 0 : pageIndex);
    table.setPageIndex(pageIndex);

    try {
      // get user login info from postgresql
      const userData = await crud.get({
        path: '/users',
        options: {
          where: {
            uid: user.uid,
          },
        },
      });

      const storeId = userData[0]?.meta?.storeId;

      const storeData = storeId ? await crud.get({
        path: `/stores/${storeId}`,
      }) : undefined;

      // The object that will reach the back is built with the information
      // from the table (for pagination) and the applied filters.
      const takeBackBagsData = await redemptions.fetchPaginatedRedemptions({
        storeId: storeData.uid,
        pageIndex,
        pageSize,
        filters: {
          email: emailFilter,
          date: {
            start: dateFilterStart,
            end: dateFilterEnd,
          },
        },
      });

      setTakeBackBags(takeBackBagsData?.data || []);
      setTableInfo(takeBackBagsData?.table || {});
    } catch (error) {
      setAlert({
        type: 'notifications',
        message: `Unable to fetch redemptions. Error: ${error.message}`,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchAndSetRedemptions(0, intPageSize);
  }, [user, emailFilter, dateFilterEnd]);

  return (
    <ContentWrapper size="xl">
      <div className="bg-white border border-dark px-40 py-40">
        <div className="d-flex align-items-center py-8">
          <h3>Customers</h3>
          <CSVLink
            data={csvData}
            className="ms-auto btn btn-primary"
            filename="tbb_export.csv"
          >
            Export as CSV
          </CSVLink>
        </div>
        <div className="border border-dark mt-24">
          <div className="p-2">
            <div className="h-2" />
            <table className="w-100 table striped hover">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Redemption Date</th>
                  <th>Status</th>
                  <th>Code</th>
                </tr>
                <tr>
                  <th className="p-8">
                    <Form.Control
                      type="text"
                      className="w-100"
                      onChange={handleEmailFilterChange}
                      aria-label="email filter"
                    />
                  </th>
                  <th className="p-8">
                    <DatePicker
                      selected={dateFilterStart}
                      selectsRange
                      startDate={dateFilterStart}
                      endDate={dateFilterEnd}
                      wrapperClassName="custom-datepicker"
                      onChange={handleDateFilterStartChange}
                      customInput={<Form.Control className="w-100" />}
                      isClearable
                      aria-label="date filter"
                    />
                  </th>
                  <th aria-label="empty-header" />
                  <th aria-label="empty-header" />
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={4}>
                      <div className="w-100 py-16 d-flex align-items-center justify-content-center">
                        <span>Loading...</span>
                      </div>
                    </td>
                  </tr>
                )}
                {!isLoading && (data?.map(({ email, updatedAt, status, code }, idx) => (
                  <tr key={idx}>
                    <td>{email}</td>
                    <td>{updatedAt}</td>
                    <td>{status}</td>
                    <td>{code}</td>
                  </tr>
                )))}
                {(!isLoading && (!data || !data?.length))
                  && (
                    <tr>
                      <td colSpan={4}>
                        <div className="w-100 py-16 d-flex align-items-center justify-content-center">
                          <span>No Info.</span>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <div className="h-2" />
            {!isLoading && (
              <>
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="border rounded p-1"
                    onClick={async () => {
                      await fetchAndSetRedemptions(intPageIndex - 1, intPageSize);
                    }}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {'<'}
                  </button>
                  <button
                    type="button"
                    className="border rounded p-1"
                    onClick={async () => {
                      await fetchAndSetRedemptions(intPageIndex + 1, intPageSize);
                    }}
                    disabled={!table.getCanNextPage()}
                  >
                    {'>'}
                  </button>
                  <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                      {table.getState().pagination.pageIndex + 1}
                      {' '}
                      of
                      {' '}
                      {table.getPageCount()}
                    </strong>
                  </span>
                </div>
                <div>
                  Showing
                  {' '}
                  {data.length}
                  {' of '}
                  {tableInfo.quantity}
                  {' '}
                  Rows
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};
//
export default Customers;
