import { useLocation } from 'react-router-dom';

import MarketingInfo from '../../global/MarketingInfo';
import { isTrashieContainerPublicPath } from '../../utils/routes';

import './TrashieContainerPublic.scss';

const TrashieContainerPublic = ({
  children,
  leftContent = (<MarketingInfo />),
}) => {
  const location = useLocation();

  if (!isTrashieContainerPublicPath(location.pathname)) {
    return children;
  }

  return (
    <div className="TrashieContainerPublic">
      <div className="TrashieContainerPublic__content">
        {leftContent && (
          <div className="TrashieContainerPublic__content--left">
            {leftContent}
          </div>
        )}
        <div className="TrashieContainerPublic__content--right">
          {children}
        </div>
      </div>
    </div>
  );
};

export default TrashieContainerPublic;
