import { useMemo } from 'react';

import { isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';

import './Flag.scss';

/* Parent element MUST have 'position: relative' */

const Flag = ({ status, text = '', properties }) => {
  const {
    label,
    style,
  } = useMemo(() => {
    switch (true) {
      case isLimitErrorCode(status):
        return {
          label: properties?.soldOut?.label || 'SOLD OUT',
          style: properties?.soldOut?.style || {},
        };
      case isUserLimitErrorCode(status):
        return {
          label: properties?.claimed?.label || 'CLAIMED',
          style: properties?.claimed?.style || {},
        };
      case text === 'NEW':
        return {
          label: properties?.new?.label || 'NEW',
          style: properties?.new?.style || {},
        };
      case text === 'FREE':
        return {
          label: properties?.free?.label || '',
          style: properties?.free?.style || {},
        };
      case Boolean(text):
        return {
          label: text,
          style: properties?.flaggedValue?.style || {},
        };

      default:
        return {};
    }
  }, [status, text]);

  if (!label) {
    return null;
  }

  return (
    <div className="RewardFlag" style={{ ...style }}>
      <p>{label}</p>
    </div>
  );
};

export default Flag;
