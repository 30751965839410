import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import crud from '../../../api/crud';

const ForgotPassword = ({ onViewLinkClick }) => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState('');

  const handlePasswordReset = async (e, resetEmail) => {
    e.preventDefault();
    crud.post({
      path: '/email',
      body: {
        email: resetEmail,
        messageId: '26',
        messageData: {},
      },
    });
    setAlert(`a password reset email was sent to ${resetEmail}`);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <h1 className="text-center">Forgot Password</h1>
      <p className="text-center my-16">If you have an account, you will receive a password reset link. If you do not receive an email, you do not have a current account. Please create a new account and password.</p>
      <Form onSubmit={handlePasswordReset} className="d-flex flex-column align-items-center w-100 mb-24">
        <Form.Group className="w-100 mb-16">
          <Form.Control value={email} placeholder="email" onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>
        <Button type="submit" variant="dark" className="d-block w-100" onClick={(e) => handlePasswordReset(e, email)}>Send Reset Email</Button>
      </Form>
      <Button
        variant="link"
        className="text-decoration-none link-warning fs-6"
        onClick={() => onViewLinkClick('register')}
        type="button"
      >
        Create an Account
      </Button>
      <Button
        variant="link"
        className="text-decoration-none link-dark fs-6"
        onClick={() => onViewLinkClick('login')}
        type="button"
      >
        Back to Sign In
      </Button>
      <p className="text-danger text-center">{alert}</p>
    </div>
  );
};

export default ForgotPassword;
