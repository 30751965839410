import { UPS_FIND_LOCATIONS_URL, USPS_FIND_LOCATIONS_URL } from '../config/externalURL';
import Usps from '../images/USPS.svg';
import UpsIcon from '../images/UPS-icon.svg';
import HappyReturnsIcon from '../images/Happy-returns-icon.svg';

export const getNextButtonStyles = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    background: registerTbbFlow?.nextButton?.bgColor,
    color: registerTbbFlow?.nextButton?.textColor,
    border: registerTbbFlow?.nextButton?.bgColor,
  };
};

export const getOptionButtonStyles = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow?.nextButton?.bgColor,
  };
};

export const REDEMPTION_CODE_STATUS_IDS = {
  CLAIMED: 1,
  RETURNED: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const shippingMethodProperties = {
  'e84f78ec-a381-46ba-9789-7e4e1016c165': {
    shippingAndRewardTitle: 'NEXT: UPS drop off',
    shippingTitle: 'YOUR UPS LABEL',
    description: 'To ship, simply print your label and drop it at an UPS location.',
    icon: UpsIcon,
    ctaLabel: 'PRINT LABEL',
    ctaLabel2: 'find ups locations',
    findLocation: UPS_FIND_LOCATIONS_URL,
  },
  'UPS QR Code': {
    shippingAndRewardTitle: 'NEXT: UPS drop off',
    shippingTitle: 'YOUR UPS QR CODE',
    description: 'To ship, simply head to an UPS location, scan this QR code and drop the bag.',
    icon: UpsIcon,
    ctaLabel: 'FIND UPS LOCATIONS',
  },
  '7317916b-cde6-4da0-9b36-cad45c43c3f1': {
    shippingAndRewardTitle: 'NEXT: drop it at usps',
    shippingTitle: 'YOUR USPS LABEL',
    description: 'To ship, simply head to an USPS location, scan this QR code and drop the bag.',
    icon: Usps,
    ctaLabel: 'PRINT LABEL',
    ctaLabel2: 'find usps locations',
    findLocation: USPS_FIND_LOCATIONS_URL,
  },
  'b298ab5b-ce81-49d1-8858-a174ae603e68': {
    shippingAndRewardTitle: 'NEXT: drop it at a return bar location',
    shippingTitle: 'YOUR HAPPY RETURNS QR CODE',
    description: 'To ship, simply head to a Return Bar location, scan this QR code and drop the bag.',
    icon: HappyReturnsIcon,
    ctaLabel: 'OPEN QR CODE',
    ctaLabel2: 'RETURN BAR LOCATIONS',
  },
};

export const getCouponStyle = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow.rewardStep?.cardTextColor,
    background: registerTbbFlow.rewardStep?.cardBgColor,
    border: 'none',
  };
};
