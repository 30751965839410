import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ClockIcon from '../../../images/clock-icon.svg';
import { ROUTE_REWARD_INFO, ROUTE_DEAL_INFO, REWARD_ID_ROUTE_PARAM } from '../../../config/routes';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { isPastDate, formatMMDDYY, formatExpirationDate } from '../../../utils/dates';
import { getRewardCode, getUniqueLink } from '../../../utils/rewards';
import CopyButton from '../../CopyButton';
import Card, { CARD_TYPE } from '../../Rewards/Card';
import { isMobile } from '../../../../../hooks/useBreakPoint';

import './MyRewardItem.scss';

const {
  myRewards: {
    list: {
      titleLink: trackerTitleLink,
      copyCodeLink: trackerCopyCodeLink,
      copyPinLink: trackerCopyPinLink,
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const COPY_DATA_TYPES = {
  CODE: 'code',
  PIN: 'pin',
};

const MyRewardItem = ({
  rewardItem,
  isCopied,
  onCopy,
}) => {
  const timeoutRef = useRef(null);
  const isMobileSize = isMobile();
  const [copiedData, setCopiedData] = useState(null);

  const {
    uid,
    redeemedAt,
    expireAt,
    pin,
    redeemUrl,
    reward: {
      description,
      type,
      exchangeValue,
      store: {
        title: storeTitle,
        logoUrl,
        headerLogoProperties,
        cardBgColor,
      },
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const isDeal = useMemo(() => exchangeValue === '0', [exchangeValue]);

  const rewardCode = useMemo(() => (
    getRewardCode({ ...rewardItem, type })
  ), [rewardItem]);

  const uniqueLink = useMemo(() => (
    getUniqueLink(rewardItem.reward)
  ), [rewardItem]);

  const handleCopy = useCallback((dataType) => {
    const data = dataType === COPY_DATA_TYPES.PIN ? pin : rewardCode;

    setCopiedData(dataType);
    onCopy(uid);

    navigator.clipboard.writeText(data);

    timeoutRef.current = setTimeout(() => {
      window.location.href = uniqueLink;
    }, 500);
  }, [rewardCode, pin, uniqueLink]);

  useEffect(() => {
    if (!isCopied) {
      setCopiedData(null);
    }
  }, [isCopied]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div
      role="listitem"
      className="MyRewardItem"
    >
      <div className="MyRewardItem__card">
        <Card
          logoUrl={logoUrl}
          logoStyle={headerLogoProperties}
          backgroundColor={cardBgColor}
          type={CARD_TYPE.DEFAULT}
          cardLink={uniqueLink}
        />
      </div>
      {isMobileSize && (
        <div className="MyRewardItem__content content-mobile">
          <a
            className="MyRewardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {storeTitle}
          </a>
          <div className="MyRewardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        </div>
      )}
      <div className={classNames('MyRewardItem__content', { expired: expireAt && isPastDate(expireAt) })}>
        {!isMobileSize && (
          <a
            className="MyRewardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {storeTitle}
          </a>
        )}
        {(expireAt && !isPastDate(expireAt)) && (
          <div className="MyRewardItem__content--expiration">
            <img src={ClockIcon} alt="clock icon" />
            <span>{formatExpirationDate(expireAt)}</span>
          </div>
        )}
        {!isMobileSize && (
          <div className="MyRewardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        )}
        <div className="MyRewardItem__content--details details-highlight">
          <span className="details-label">
            {rewardCode ? 'CODE:' : `MY ${isDeal ? 'DEAL' : 'REWARD'}`}
          </span>
          <CopyButton
            className="details-highlight"
            data={rewardCode || (
              <a href={redeemUrl}>CLICK TO CLAIM</a>
            )}
            onClick={rewardCode ? () => handleCopy(COPY_DATA_TYPES.CODE) : undefined}
            copied={copiedData === COPY_DATA_TYPES.CODE}
            trackerProps={{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyCodeLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyCodeLink),
            }}
          />
        </div>
        {pin && (
          <div className="MyRewardItem__content--details details-highlight">
            <span className="details-label">PIN:</span>
            <CopyButton
              className="details-highlight"
              data={pin}
              onClick={() => handleCopy(COPY_DATA_TYPES.PIN)}
              copied={copiedData === COPY_DATA_TYPES.PIN}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyPinLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyPinLink),
              }}
            />
          </div>
        )}
        <div className="MyRewardItem__content--details">
          <span className="details-label details-small">Purchased:</span>
          <span className="details-small">{formatMMDDYY(redeemedAt)}</span>
          {expireAt && (
            <>
              <span className="details-label details-small">Expires:</span>
              <span className="details-small">{formatMMDDYY(expireAt)}</span>
            </>
          )}
        </div>
        <Link
          to={`${isDeal ? ROUTE_DEAL_INFO : ROUTE_REWARD_INFO}`.replace(REWARD_ID_ROUTE_PARAM, uid)}
          className="MyRewardItem__content--link"
        >
          {`${isDeal ? 'DEAL' : 'REWARD'} DETAILS`}
        </Link>
      </div>
      <a
        className="MyRewardItem__cta"
        href={uniqueLink}
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
        }}
      >
        SHOP NOW
      </a>
    </div>
  );
};

export default MyRewardItem;
