/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-constructed-context-values */
import { useState, createContext, useEffect } from 'react';
import { Row, Col, Collapse, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom';
import crud from '../../../api/crud';
import { formatMoney } from '../../../utils/formatMoney';
import useBalance from '../../../hooks/useBalance';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import poweredByFordays from '../../../images/redeem/powered_by_fordays.png';
import BrandCircle from '../../global/BrandCircle';
import circleLogo from '../../../images/redeem/logo-circle.svg';
import logo from '../../../images/for-days-logo-black.webp';
import Menu from '../../global/Menu';
import { useAlert } from '../../providers/AlertProvider';

export const WalletContext = createContext(undefined);

const WalletContainer = ({ children }) => {
  const history = useHistory();
  const { isSignedIn, user, userData } = useFirebaseAuth();
  const [userType, setUserType] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [circleLogoUrl, setCircleLogo] = useState(null);
  const value = [circleLogoUrl, setCircleLogo];
  const [transactions, setTransactions] = useState([]);
  const cashBalance = useBalance(transactions);
  const setAlert = useAlert();

  const location = useLocation();
  useEffect(() => {
    const init = async () => {
      if (!user || !user.uid) {
        return;
      }
      try {
        const uData = await crud.get({
          path: '/users',
          options: {
            where: {
              uid: user.uid,
            },
          },
        });

        if (!uData.length) {
          await crud.post({
            path: '/users',
            body: {
              data: {
                email: user.email,
                uid: user.uid,
                meta: {
                  migrated: false,
                },
              },
            },
          });
        }

        setUserType(uData[0].meta.type);
      } catch (e) {
        // do nothing
      }
      try {
        setTimeout(async () => {
          const fetchedTransactions = await crud.get({
            path: '/transactions',
            options: {
              where: {
                userId: user.uid,
              },
              select: {
                amount: true,
                typeId: true,
              },
            },
          });

          const transactionsToCashArray = fetchedTransactions.map(transaction => {
            let newTransaction;
            if (transaction.typeId === 1) {
              newTransaction = { ...transaction, type: 'withdraw' };
            } else if (transaction.typeId === 2) {
              newTransaction = { ...transaction, type: 'deposit' };
            }
            return newTransaction;
          });

          setTransactions(transactionsToCashArray);
        }, 2000);
      } catch (error) {
        setAlert({
          type: 'passive',
          message: error.message,
          error,
        });
      }
    };
    init();
  }, [user]);

  const handleMenuItemClicked = (item) => {
    history.push(`/${item}`);
    setIsMenuOpen(false);
  };

  if (location.pathname.includes('embed') || location.pathname === '/' || location.pathname === '/signup') {
    return children;
  }

  if (location.pathname === '/partner/customers' && userType !== null && userType === undefined) {
    return <Redirect to="/wallet" />;
  }

  const DesktopNav = () => (
    <div>
      <div className="container-fluid bg-white py-24 border-dark border-bottom d-lg-block d-none sticky-top">

        <Row>
          <Col xs="3" className={!userType || userType !== 'partner' ? 'text-start' : ''}>
            {!userType || userType !== 'partner' ? (
              <Link to={{ pathname: 'https://fordays.com' }} target="_blank" className="ms-3">
                <img src={logo} alt="logo" width="150" />
              </Link>
            ) : null}
          </Col>
          <Col xs="6" className="d-flex justify-content-between align-items-center">
            {userType && userType === 'partner' ? (
              <>
                <Link to={{ pathname: 'https://fordays.com/pages/how-we-recycle' }} target="_blank">About</Link>
                <Link to="/partner">Stats</Link>
                <Link to={{ pathname: 'https://fordays.com' }} target="_blank">
                  <img src={logo} alt="logo" width="150" />
                </Link>
                <Link to="/partner/customers">Customers</Link>
                <Link to={{ pathname: 'https://forms.gle/HmqDCb3h4mLBjiEs6' }} target="_blank">Order</Link>
              </>
            ) : (
              <>
                <Link to="/bags">Take Back Bags</Link>
                {/* <Link to="/closet">Closet</Link> */}
                <Link to="/wallet">Wallet</Link>
                <Link to="/orders">Order History</Link>
              </>
            )}
          </Col>
          <Col xs="3" className="text-end">
            <Link
              to="/wallet"
              className="me-3 font-600 bg-primary text-dark border border-dark p-2 rounded-pill font-size-12"
            >
              TrashieCash:
              {' '}
              {formatMoney(cashBalance)}
            </Link>
            <Link to="/settings">
              <FontAwesomeIcon
                className="me-3 selectable"
                icon={faUserAlt}
              />
            </Link>
          </Col>
        </Row>
      </div>
    </div>

  );

  const MobileNav = () => (
    <nav
      className="
        container-fluid
        bg-white
        py-24
        border-dark
        border-bottom
        d-lg-none
        align-items-center
        d-flex
        justify-content-between
        sticky-top
      "
    >
      <FontAwesomeIcon
        className="me-16 selectable"
        size="lg"
        icon={faBars}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      <Link
        className="d-flex justify-content-between align-items-center"
        to="/cash"
      >
        <BrandCircle circleLogo={circleLogo} height="40px" />
      </Link>
      <Link
        className="d-flex justify-content-between align-items-center"
        to="/cash"
      >
        <FontAwesomeIcon
          className="me-16 selectable"
          size="lg"
          icon={faUserAlt}
        />
      </Link>
    </nav>
  );

  if (location.pathname === '/closet') {
    return <Redirect to="/wallet" />;
  }

  if (!children.some(routes => routes.props.path === location.pathname)) return null;

  return (
    <WalletContext.Provider value={value}>
      <div className="d-flex flex-column min-vh-100">
        {isSignedIn && userData && (
          <>
            <DesktopNav />
            <MobileNav />
          </>
        )}
        <Collapse in={isMenuOpen} dimension="width">
          <div>
            <Menu
              isMenuOpen={isMenuOpen}
              onMenuClosed={() => setIsMenuOpen(false)}
              onMenuItemClicked={handleMenuItemClicked}
              cashBalance={formatMoney(cashBalance)}
            />
          </div>
        </Collapse>
        <div className="flex-fill">
          {children}
        </div>
        <div className="pb-32 pt-4 text-center">
          <Image alt="powered by fordays" src={poweredByFordays} className="justify-self-end mb-3" />
        </div>
      </div>
    </WalletContext.Provider>
  );
};
export default WalletContainer;
