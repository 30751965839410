import { Link } from 'react-router-dom';
import './NavigationItem.scss';
import { POSTHOG_CAPTURE_ATTRIBUTES } from '../../../config/tracker';

const NavigationItem = ({ key, option }) => {
  const { label, url, icon, isExternal, trackerId } = option;

  return (isExternal ?
    (
      <a
        href={url}
        key={key}
        className="NavigationItem"
        {...{ [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: trackerId }}
      >
        <div className="NavigationItem__icon">
          {icon}
        </div>
        <div className="NavigationItem__label">{label}</div>
      </a>
    )
    : (
      <Link
        to={url}
        key={key}
        className="NavigationItem"
        {...{ [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: trackerId }}
      >
        <div className="NavigationItem__icon">
          {icon}
        </div>
        <div className="NavigationItem__label">{label}</div>
      </Link>
    ));
};

export default NavigationItem;
