import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import RewardsList from './RewardsList';
import { TRASHIE_WEBSITE_URL } from '../../config/externalURL';
import {
  CATEGORY_SEARCH_PARAM,
  SORT_SEARCH_PARAM,
  FILTER_SEARCH_PARAMS,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../config/routes';
import {
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../../config/tracker';
import Tabs from '../../global/Mui/Tabs';
import Tab from '../../global/Mui/Tab';
import Slider from '../../global/Slider';
import Sorting from '../../global/Sorting';
import LoadingBar from '../../global/LoadingBar';
import GhostIcon from '../../images/ghost-icon.svg';
import { getSearchParam } from '../../utils/routes';
import { getCategories, getEarningsCarousel, getRewards, getConfigurations } from '../../utils/service';
import { getGenericError } from '../../utils/errors';
import { isProd } from '../../../../config/config';
import { LIST_PAGE_SIZE } from '../../config/config';
import { isMobile, SIZE_SM, SIZE_XS } from '../../../../hooks/useBreakPoint';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../global/Button';
import Filter from './Filter';
import {
  FIXED_CATEGORIES,
  FLAGS,
  getCategory,
  getFilter,
  getFilterParams,
  getCategoryParam,
  updateQueryFilters,
} from './utils';
import {
  getSort,
  getSortingParam,
  sortingOptions,
} from '../../utils/sorting';

import './Rewards.scss';

const {
  rewards: {
    marketing: {
      slide: trackerSlide,
    },
  },
} = TRACKER_IDS;

const LIST_ELEMENT_ID = 'List';

const Rewards = () => {
  const { search } = useLocation();
  const history = useHistory();
  const timeoutRef = useRef(null);
  const isMobileXsSm = isMobile([SIZE_XS, SIZE_SM]);
  const [categories, setCategories] = useState([]);
  const [categoryRewards, setCategoryRewards] = useState([]);
  const [categoryRewardsTotal, setCategoryRewardsTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [carousel, setCarousel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRewards, setIsLoadingRewards] = useState(true);
  const [isLoadingCarousel, setIsLoadingCarousel] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState('');
  const [rewardsError, setRewardsError] = useState('');
  const [hasLoadedRewards, setHasLoadedRewards] = useState(false);
  const [carouselError, setCarouselError] = useState('');
  const [sortingSelected, setSortingSelected] = useState(_.first(sortingOptions));
  const [isCheking, setIsCheking] = useState(false);
  const [filterSelected, setFilterSelected] = useState({});
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  const query = useMemo(() => (
    new URLSearchParams(search)
  ), [search]);

  const {
    rewardCategoryParam,
    rewardSortParam,
    rewardFilterTypeParam,
    rewardFilterMinValueParam,
    rewardFilterMaxValueParam,
    rewardFilterIsNewParam,
  } = useMemo(() => ({
    rewardCategoryParam: getSearchParam(query, CATEGORY_SEARCH_PARAM),
    rewardSortParam: getSearchParam(query, SORT_SEARCH_PARAM),
    rewardFilterMinValueParam: getSearchParam(query, FILTER_SEARCH_PARAMS.MIN_VALUE),
    rewardFilterMaxValueParam: getSearchParam(query, FILTER_SEARCH_PARAMS.MAX_VALUE),
    rewardFilterTypeParam: getSearchParam(query, FILTER_SEARCH_PARAMS.TYPE),
    rewardFilterIsNewParam: getSearchParam(query, FILTER_SEARCH_PARAMS.IS_NEW),
  }), [query]);

  const scrollToTop = useCallback(() => {
    const listElement = document.getElementById(LIST_ELEMENT_ID);

    if (listElement) {
      timeoutRef.current = setTimeout(() => {
        listElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const loadCategoryRewards = useCallback(async (routeName, sorting, filter, newCategories) => {
    setIsLoadingRewards(true);

    const newPage = (
      routeName === selectedCategory &&
      sorting.id === sortingSelected.id &&
      _.isEqual(filter, filterSelected)
    )
      ? page + 1
      : 1;

    if (newPage === 1) {
      setCategoryRewards([]);
      setCategoryRewardsTotal(0);
    }

    const currentTotal = newPage === 1 ? 0 : categoryRewardsTotal;
    const currentCategoryRewards = newPage === 1 ? [] : [...categoryRewards];
    const category = getCategory(newCategories ?? categories, routeName);
    const updatedFilter = { ...filter };

    if (!Object.prototype.hasOwnProperty.call(filter, 'minValue')) {
      updatedFilter.minValue = 1;
    }

    const {
      data,
      error: categoryRewardsError,
    } = await getRewards({
      pageSize: LIST_PAGE_SIZE,
      page: newPage,
      ...getCategoryParam(category.uid),
      ...getSortingParam(sorting),
      ...getFilterParams(updatedFilter),
    });

    if (categoryRewardsError) {
      setCategoryRewards(currentCategoryRewards);
      setCategoryRewardsTotal(currentTotal);
      setPage(page);
      setRewardsError(categoryRewardsError.message || getGenericError());
      setIsLoadingRewards(false);
      return;
    }

    setCategoryRewards([...currentCategoryRewards, ...data.rewards]);
    setCategoryRewardsTotal(data.total);
    setPage(newPage);
    setRewardsError('');
    setIsLoadingRewards(false);
    setHasLoadedRewards(true);
  }, [
    selectedCategory,
    sortingSelected,
    filterSelected,
    page,
    categories,
    categoryRewardsTotal,
    categoryRewards,
  ]);

  const loadCategories = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardCategoriesError,
    } = await getCategories({ minExchangeValue: 1 });

    if (rewardCategoriesError) {
      setCategories([]);
      setSelectedCategory('');
      setError(rewardCategoriesError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    const newCategories = [
      ...FIXED_CATEGORIES,
      ...data.categories,
    ];
    const initialSelectedCategory = getCategory(newCategories, rewardCategoryParam);
    const initialSort = getSort(rewardSortParam);
    const initialFilter = getFilter({
      type: rewardFilterTypeParam,
      minValue: rewardFilterMinValueParam,
      maxValue: rewardFilterMaxValueParam,
      other: {
        [FLAGS.IS_NEW]: rewardFilterIsNewParam,
      },
    });

    setCategories(newCategories);
    setSelectedCategory(initialSelectedCategory.routeName);
    setSortingSelected(initialSort);
    setFilterSelected(initialFilter);
    setError('');
    setIsLoading(false);
    loadCategoryRewards(
      initialSelectedCategory.routeName,
      initialSort,
      initialFilter,
      newCategories,
    );

    query.set(CATEGORY_SEARCH_PARAM, initialSelectedCategory.routeName);
    query.set(SORT_SEARCH_PARAM, initialSort.id);
    updateQueryFilters(query, initialFilter);

    history.push({
      pathname: ROUTE_REWARDS,
      search: query.toString(),
    });
  }, [
    rewardCategoryParam,
    rewardSortParam,
    rewardFilterTypeParam,
    rewardFilterMinValueParam,
    rewardFilterMaxValueParam,
    rewardFilterIsNewParam,
    loadCategoryRewards,
  ]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const loadCarousel = useCallback(async () => {
    setIsLoadingCarousel(true);

    const {
      data,
      error: loadCarouselError,
    } = await getEarningsCarousel();

    if (loadCarouselError) {
      setCarousel([]);
      setCarouselError(loadCarouselError.message || getGenericError());
      setIsLoadingCarousel(false);
      return;
    }

    setCarousel(data);
    setCarouselError('');
    setIsLoadingCarousel(false);
  }, []);

  const handleCategoryChange = useCallback((event, newValue) => {
    if (newValue === selectedCategory) {
      return;
    }

    const newCategory = getCategory(categories, newValue);
    setCategoryRewards([]);
    setSelectedCategory(newCategory.routeName);
    loadCategoryRewards(newCategory.routeName, sortingSelected, filterSelected);
    scrollToTop();

    if (event.target) {
      query.set(CATEGORY_SEARCH_PARAM, newValue);
      history.push({
        pathname: ROUTE_REWARDS,
        search: query.toString(),
      });
    }
  }, [
    history,
    query,
    selectedCategory,
    sortingSelected,
    filterSelected,
    categories,
    loadCategoryRewards,
  ]);

  const handleOnClick = useCallback(({ storeId, rewardId }) => {
    const relativePath = ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, storeId)
      .replace(REWARD_ID_ROUTE_PARAM, rewardId);

    history.push(relativePath);
  }, [history]);

  const handleSortingChange = useCallback((newValue) => {
    setSortingSelected(newValue);
    setCategoryRewards([]);
    loadCategoryRewards(selectedCategory, newValue, filterSelected);
    scrollToTop();

    query.set(SORT_SEARCH_PARAM, newValue.id);
    history.push({
      pathname: ROUTE_REWARDS,
      search: query.toString(),
    });
  }, [selectedCategory, filterSelected, query, history, loadCategoryRewards]);

  const handleFilterCheck = useCallback(async ({
    minValue = 1,
    maxValue,
    type,
    other,
  }) => {
    if (!hasLoadedRewards) {
      return false;
    }

    setIsCheking(true);

    const category = getCategory(categories, selectedCategory);

    const {
      data,
      error: categoryRewardsError,
    } = await getRewards({
      pageSize: 1,
      page: 1,
      ...getCategoryParam(category.uid),
      ...getSortingParam(sortingSelected),
      ...getFilterParams({
        minValue,
        maxValue,
        type,
        other,
      }),
    });

    if (categoryRewardsError) {
      setIsCheking(false);
      return false;
    }

    setIsCheking(false);

    return Boolean(data.total);
  }, [hasLoadedRewards, categories, selectedCategory, sortingSelected]);

  const handleFilterChange = useCallback((newFilter) => {
    setFilterSelected(newFilter);
    loadCategoryRewards(selectedCategory, sortingSelected, newFilter);

    updateQueryFilters(query, newFilter);
    history.push({
      pathname: ROUTE_REWARDS,
      search: query.toString(),
    });
  }, [query, selectedCategory, sortingSelected, history]);

  useEffect(() => {
    if (hasLoadedRewards && rewardCategoryParam) {
      handleCategoryChange({}, rewardCategoryParam);
    }
  }, [hasLoadedRewards, rewardCategoryParam]);

  useEffect(() => {
    loadCarousel();
    loadCategories();
    rewardsConfig();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className="RewardsView">
      <div className="RewardsView__content">
        {!isLoadingCarousel && !carouselError && carousel.length > 0 && (
          <div className="RewardsView__content--slider">
            <Slider
              slides={carousel.map(({
                name,
                desktopImageUrl,
                mobileImageUrl,
                redirectUrl,
                posthogId,
              }) => ({
                key: name,
                slide: (
                  <a
                    href={redirectUrl || TRASHIE_WEBSITE_URL}
                    alt="slide"
                    className="RewardsView__content--slider-slide"
                    {...{
                      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerSlide),
                      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: posthogId,
                    }}
                  >
                    <img
                      src={(isMobileXsSm && mobileImageUrl) || desktopImageUrl}
                      alt="slide"
                    />
                  </a>
                ),
              }))}
            />
          </div>
        )}
        <div className="RewardsView__content--header">
          <h2 className="RewardsView__content--header-title">
            TrashieCash™ Rewards
          </h2>
          <p className="RewardsView__content--header-subtitle">
            You’ve earned it — redeem TrashieCash for something awesome.
          </p>
        </div>
        {error || (isLoading ? (
          <LoadingBar className="RewardsView__content--loader" />
        ) : (
          <>
            <Box className={classNames('RewardsView__content--controls', { hasEnvBanner: !isProd() })}>
              <Tabs
                value={selectedCategory}
                onChange={handleCategoryChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="rewards categories"
              >
                {categories.map(({ uid, name, iconUrl, routeName }) => (
                  <Tab key={uid} value={routeName} label={name} icon={<img src={iconUrl} alt="tab icon" />} />
                ))}
              </Tabs>
              <Box className="RewardsView__content--controls-options">
                <Filter
                  filter={filterSelected}
                  onSubmit={handleFilterChange}
                  onCheck={handleFilterCheck}
                  isCheking={isCheking}
                  open={isOpenFilter}
                  onOpenChange={setIsOpenFilter}
                />
                <Sorting
                  sortingOptions={sortingOptions}
                  sortingOptionSelected={sortingSelected}
                  onChange={handleSortingChange}
                />
              </Box>
            </Box>
            <Box
              id={LIST_ELEMENT_ID}
              className={classNames('RewardsView__content--pane', { hasEnvBanner: !isProd() })}
            >
              {!isLoadingRewards && !isLoadingConfig && categoryRewards.length === 0 ? (
                <div className="RewardsView__content--empty">
                  <img src={GhostIcon} alt="ghost icon" />
                  <div className="RewardsView__content--empty-title">
                    No rewards found
                  </div>
                  <div className="RewardsView__content--empty-subtitle">
                    Tweak the filters to view available rewards.
                  </div>
                  <Button
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.QUATERNARY}
                    onClick={() => setIsOpenFilter(true)}
                  >
                    Tweak Filters
                  </Button>
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={categoryRewards.length}
                  next={
                  () => loadCategoryRewards(selectedCategory, sortingSelected, filterSelected)
                }
                  hasMore={categoryRewards.length < categoryRewardsTotal}
                  className="RewardsView__content--scroll"
                >
                  <RewardsList
                    rewardsList={categoryRewards}
                    onClick={handleOnClick}
                    rewardProperties={rewardProperties}
                  />
                </InfiniteScroll>
              )}
              {(isLoadingRewards || isLoadingConfig) && (
                <LoadingBar className="RewardsView__content--loader" />
              )}
              {rewardsError && rewardsError}
            </Box>
          </>
        ))}
      </div>
    </div>
  );
};

export default Rewards;
