import { useCallback, useEffect, useState } from 'react';

import OffersList from './OffersList';
import PageContent from '../../global/PageContent';
import { getGenericError } from '../../utils/errors';
import { getOffers } from '../../utils/service';

import './Offers.scss';

const Offers = () => {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadOffers = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: offersError,
    } = await getOffers();

    if (offersError) {
      setListData([]);
      setError(offersError.message ?? getGenericError());
      setIsLoading(false);
      return;
    }

    setListData(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOffers();
  }, []);

  return (
    <PageContent
      title="Earn TrashieCash™"
      subtitle="Maximize your rewards with every purchase."
      isLoading={isLoading}
      error={error}
      className="Offers"
    >
      {(listData.length && <OffersList offersList={listData} isDashBoard />) || (
        <span className="Ofers__noData">
          No offers available at the moment
        </span>
      )}
    </PageContent>
  );
};

export default Offers;
