import { getConfig } from '../config/config';

const REACT_APP_SWAP_API_ENDPOINT = getConfig('REACT_APP_SWAP_API_ENDPOINT');

const apiFetch = async ({ path, body = undefined, method = 'GET', token }) => {
  const methodToUse = method || (body ? 'POST' : 'GET');
  const res = await fetch(REACT_APP_SWAP_API_ENDPOINT + path, {
    method: methodToUse,
    body,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  });
  const result = await res.json();
  return result;
};

const apiFetchUnAuthenticated = async ({
  path,
  body = undefined,
  method = 'GET',
}) => {
  const methodToUse = method || (body ? 'POST' : 'GET');
  const requestOptions = {
    method: methodToUse,
    headers: {
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  };
  if (body) {
    requestOptions.body = body;
  }

  const res = await fetch(REACT_APP_SWAP_API_ENDPOINT + path, requestOptions);

  if (res.status > 399 && res.status < 500) {
    const errorRes = await res.json();
    const errorObj = {
      message: errorRes.message,
    };
    throw errorObj;
  }

  const result = await res.json();
  return result;
};

const fetchCloset = async ({ token, storeId }) => {
  const { data } = await apiFetch({
    path: `/swaps/userCloset?store=${storeId}`,
    method: 'GET',
    token,
  });
  return data;
};

const fetchTransactions = async ({ token }) => {
  const { data } = await apiFetch({
    path: '/swaps/user',
    method: 'GET',
    token,
  });
  return data;
};

const fetchCouponBank = async ({ token }) => {
  const data = await apiFetch({
    // Note: This end point does not send back data in an object
    path: '/swaps/coupon-bank',
    method: 'GET',
    token,
  });
  return data;
};

const fetchGiftCardData = async ({ token, id }) => {
  const data = await apiFetch({
    // Note: This end point does not send back data in an object
    path: `/giftcards/${id}`,
    method: 'GET',
    token,
  });
  return data;
};

const fetchUserGiftcards = async ({ token }) => {
  const data = await apiFetch({
    // Note: This end point does not send back data in an object
    path: '/swaps/gift-cards',
    method: 'GET',
    token,
  });
  return data;
};

const fetchStore = async storeId => {
  const data = await apiFetchUnAuthenticated({
    path: `/stores/${storeId}`,
    method: 'GET',
  });
  return data;
};

const fetchStoreConfig = async storeId => {
  // Note: This end point does not send back data in an object
  const data = await apiFetchUnAuthenticated({
    path: `/stores/${storeId}/config`,
    method: 'GET',
  });
  return data;
};

const fetchSkuValues = async ({ skuList, qtyList, storeId, productList }) => {
  const map = await fetchStoreConfig(storeId);

  const result = [];
  skuList.forEach((sku, idx) => {
    if (map[sku]) {
      let a = 0;
      while (a < qtyList[idx]) {
        // Create a swap for each individual item
        result.push({
          sku,
          amount: Number(map[sku].amount),
          storeId,
          variantId: map[sku].variantId,
          productName: productList[idx],
        });
        a++;
      }
    }
  });
  return result;
};

const createSwap = async ({ pendingSwaps, token, orderNumber, storeId }) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: '/swaps',
    method: 'POST',
    body: JSON.stringify({
      pendingSwaps,
      orderNumber,
      storeId,
    }),
    token,
  });
  return data;
};

const duplicateCoupon = async ({ token, storeId, amount }) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: '/checkout/discounts/duplicate',
    method: 'POST',
    body: JSON.stringify({
      storeId,
      amount,
    }),
    token,
  });
  return data;
};

const createQRSwap = async ({
  token,
  orderNumber,
  swap,
  storeId,
  redemptionCode,
}) => {
  const { data } = await apiFetch({
    path: '/swaps/qr-redemption',
    method: 'POST',
    body: JSON.stringify({
      orderNumber,
      swap,
      storeId,
      redemptionCode,
    }),
    token,
  });
  return data;
};

const createQRCoupon = async ({ token, amount, storeId, redemptionCode }) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: '/swaps/qr-coupon',
    method: 'POST',
    body: JSON.stringify({
      storeId,
      amount,
      redemptionCode,
    }),
    token,
  });
  return data;
};

const fetchRedemptionInfo = async redemptionCode => {
  // Note: This end point does not send back data in an object
  const data = await apiFetchUnAuthenticated({
    path: `/stores/qr-scan/${redemptionCode}`,
    method: 'GET',
  });
  return data;
};

const fetchExistingRedemption = async redemptionCode => {
  const { data } = await apiFetchUnAuthenticated({
    path: `/redemptions/${redemptionCode}`,
    method: 'GET',
  });
  return data;
};

const fetchRedemptionsByPartner = async (storeId, token) => {
  const { data } = await apiFetch({
    path: `/redemptions/partner?storeId=${storeId}`,
    method: 'GET',
    token,
  });
  return data;
};

const fetchBagsSoldByPartner = async (storeId, token) => {
  const data = await apiFetch({
    path: `/affiliates?storeId=${storeId}`,
    method: 'GET',
    token,
  });
  return data;
};

const updateRedemption = async (token, code, redemptionObj) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: `/redemptions/${code}`,
    method: 'POST',
    body: JSON.stringify(redemptionObj),
    token,
  });
  return data;
};

const fetchOrdersByToken = async (token, cursor) => {
  const { orders } = await apiFetch({
    path: `/orders${cursor ? `?cursor=${cursor}` : ''}`,
    method: 'GET',
    token,
  });
  return orders;
};

const fetchAddressesByToken = async (token) => {
  const addresses = await apiFetch({
    path: '/addresses',
    method: 'GET',
    token,
  });
  return addresses;
};

const fetchFromAddressByEmail = async (email) => {
  const addresses = await apiFetchUnAuthenticated({
    path: `/addresses/from?email=${email}`,
    method: 'GET',
  });
  return addresses;
};

const updateCustomerAddresses = async (email, customerId, addressData) => {
  const newAddress = apiFetch({
    path: '/addresses',
    method: 'POST',
    body: JSON.stringify({ ...addressData, customerId, email }),
  });
  return newAddress;
};

const sendRedemptionEmail = async (code) => {
  const data = await apiFetchUnAuthenticated({
    path: `/redemptions/email?code=${code}`,
    method: 'POST',
  });
  return data;
};

const createRedemption = async (token, redemptionObj) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: '/swaps/qr-coupon',
    method: 'POST',
    body: JSON.stringify(redemptionObj),
    token,
  });
  return data;
};

const fetchUserByEmail = async email => {
  const data = await apiFetchUnAuthenticated({
    path: '/auth/email',
    method: 'GET',
    body: JSON.stringify({ email }),
  });
  return data;
};

const updateSwapLabel = async ({ label, groupId, token }) => {
  const { data } = await apiFetch({
    path: '/swaps/update',
    body: JSON.stringify({ labelV2: label, groupId }),
    method: 'PUT',
    token,
  });
  return data;
};

const newSwap = async ({ swaps, token }) => {
  const { data } = await apiFetch({
    path: '/swaps/new',
    body: JSON.stringify({ swaps }),
    method: 'POST',
    token,
  });
  return data;
};

const newRegistration = async userId => {
  const { data } = await apiFetch({
    path: `/swaps/register/${userId}`,
    method: 'POST',
  });
  return data;
};

const sendForgotPasswordEmail = async email => {
  const { data } = await apiFetchUnAuthenticated({
    path: `/auth/reset?email=${email}`,
    method: 'GET',
  });
  return data;
};

const fetchVariantData = async ({ swapItemId, token }) => {
  const { data } = await apiFetch({
    path: `/swaps/sku?variantId=${swapItemId.toString()}`,
    token,
  });

  return data;
};

const sendVerificationCode = async ({ userEmail }) => {
  const { data } = await apiFetchUnAuthenticated({
    path: `/auth/code?email=${userEmail}`,
    method: 'GET',
  });
  return data;
};

/**
 * @deprecated Removed in service
 */
const sendSignupConfirmationEmail = async ({ userEmail }) => {
  const { data } = await apiFetchUnAuthenticated({
    path: `/auth/signup?email=${userEmail}`,
    method: 'GET',
  });
  return data;
};

const verifyLoginCode = async ({ userCode, email }) => {
  const { token } = await apiFetchUnAuthenticated({
    path: '/auth/magic',
    body: JSON.stringify({
      code: userCode,
      email,
      redirect: window.location.href,
    }),
    method: 'POST',
  });
  return token;
};

const updateDiscounts = async data => {
  const discountData = await apiFetchUnAuthenticated({
    path: '/checkout/giftcards',
    body: JSON.stringify(data),
    method: 'POST',
  });
  return discountData;
};

const generateReturnLabel = async ({ labelRequest }) => {
  const returnLabel = await apiFetch({
    path: '/ship/label',
    method: 'POST',
    body: JSON.stringify(labelRequest),
  });
  return returnLabel;
};

const createClaim = async claimData => {
  const { data } = await apiFetchUnAuthenticated({
    path: '/swaps/claim',
    method: 'POST',
    body: JSON.stringify(claimData),
  });
  return data;
};

const fetchShipmentQr = async ({ groupId, userId }) => {
  const results = await apiFetch({
    path: `/ship/qr?group=${groupId}&user=${userId}`,
    method: 'GET',
  });
  return results;
};

const addGiftCardToAccount = async (giftCardData) => {
  const results = await apiFetch({
    path: '/swaps/giftcard',
    method: 'POST',
    body: JSON.stringify(giftCardData),
  });
  return results;
};

const fetchUserRedemptions = async ({ token }) => {
  const results = await apiFetch({
    path: '/redemptions',
    method: 'GET',
    token,
  });
  return results;
};

const fetchStores = async () => {
  const { data } = await apiFetchUnAuthenticated({
    path: '/stores',
    method: 'GET',
  });
  return data;
};

const createRedemptionAndShippingLabels = async ({
  id,
  email,
  redemptionStatus,
  storeId,
  fromAddress,
}) => {
  const { data } = await apiFetchUnAuthenticated({
    path: '/redemptions',
    method: 'POST',
    body: JSON.stringify({ id, email, redemptionStatus, storeId, fromAddress }),
  });
  return data;
};

const updateBagsSold = async (storeData, token) => {
  // Note: This end point does not send back data in an object
  const data = await apiFetch({
    path: `/stores/${storeData.storeId}`,
    method: 'POST',
    body: JSON.stringify(storeData),
    token,
  });
  return data;
};

const createOrUpdateShopifyCustomer = async (email, addressObject) => {
  const data = await apiFetchUnAuthenticated({
    path: '/customer/address',
    method: 'POST',
    body: JSON.stringify({
      ...addressObject,
      email,
    }),
  });
  return data;
};

export default {
  fetchSkuValues,
  createSwap,
  fetchStoreConfig,
  duplicateCoupon,
  createQRSwap,
  createQRCoupon,
  fetchRedemptionInfo,
  createRedemption,
  updateRedemption,
  fetchStore,
  fetchTransactions,
  fetchCloset,
  fetchCouponBank,
  updateSwapLabel,
  newSwap,
  newRegistration,
  generateReturnLabel,
  apiFetchUnAuthenticated,
  sendVerificationCode,
  sendSignupConfirmationEmail,
  verifyLoginCode,
  updateDiscounts,
  fetchVariantData,
  createClaim,
  fetchShipmentQr,
  addGiftCardToAccount,
  fetchUserGiftcards,
  fetchGiftCardData,
  fetchUserRedemptions,
  fetchRedemptionsByPartner,
  sendForgotPasswordEmail,
  fetchUserByEmail,
  fetchStores,
  createRedemptionAndShippingLabels,
  fetchExistingRedemption,
  sendRedemptionEmail,
  fetchOrdersByToken,
  fetchAddressesByToken,
  updateCustomerAddresses,
  fetchBagsSoldByPartner,
  updateBagsSold,
  fetchFromAddressByEmail,
  createOrUpdateShopifyCustomer,
};
