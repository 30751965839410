import classNames from 'classnames';
import Style from '../Register.module.scss';
import Card from '../../../global/Mui/Card';

const {
  addressSelectCardName,
  addressSelectCardAddress,
  addressCardContent,
  addressSelectRadioButton,
  addressSelectedContainer,
} = Style;
function AddressSelectItemComponent({
  index,
  address,
  selectedIndex,
  onSelect,
}) {
  const {
    address1,
    city,
    provinceCode,
    zip,
    firstName,
    lastName,
  } = address;
  const isCurrentItemSelected = index === selectedIndex;
  return (
    <Card
      onClick={() => onSelect(index, address)}
      className={classNames({
        [addressSelectedContainer]: isCurrentItemSelected,
      })}
    >
      <div className={addressCardContent}>
        <div className={addressSelectCardName}>
          {firstName}
          {' '}
          {lastName}
        </div>
        <input
          type="radio"
          className={addressSelectRadioButton}
          readOnly
          checked={isCurrentItemSelected}
        />
      </div>
      <div className={addressSelectCardAddress}>
        <div>
          {address1}
        </div>
        <div>
          {city}
          {' '}
          {provinceCode}
          {' '}
          {zip}
        </div>
      </div>
    </Card>
  );
}

export default AddressSelectItemComponent;
