import { faArrowLeft, faArrowRight, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form, Modal, Tab } from 'react-bootstrap';
import BagIcon from '../../icons/BagIcon';

const modalTabs = {
  default: 'default',
  whereBag: 'whereBag',
  orderBag: 'orderBag',
};

const RegisterBagModal = ({ show, setShow }) => {
  const [bagId, setBagId] = useState('');
  const [showError, setShowError] = useState(false);
  const [tabs, setTabs] = useState(modalTabs.default);
  const handleOnHide = () => {
    setShow(false);
    setTabs(modalTabs.default);
  };
  const handleRegister = () => {
    if (!bagId) return;

    if (bagId.includes('-')) {
      if (
        bagId.split('-')[0].length !== 13 ||
        bagId.split('-')[1] !== 'bombas' ||
        bagId.split('-')[1] !== 'okko' ||
        bagId.split('-')[1] !== 'arvin' ||
        bagId.split('-')[1] !== 'mate'
      ) {
        setShowError(true);
        return;
      }
    } else if (bagId.length !== 13) {
      setShowError(true);
      return;
    }
    setShowError(false);
    window.location.href = `/redeem?code=${bagId}`;
  };
  return (
    <Modal show={show} onHide={handleOnHide}>
      <Tab.Container activeKey={tabs}>
        <Modal.Header className="border-0 mb-0 pb-0" closeButton>
          <Tab.Content>
            <Tab.Pane eventKey={modalTabs.whereBag}>
              <FontAwesomeIcon icon={faArrowLeft} className="link-dark cursor-pointer" onClick={() => setTabs(modalTabs.default)} />
            </Tab.Pane>
            <Tab.Pane eventKey={modalTabs.orderBag}>
              <FontAwesomeIcon icon={faArrowLeft} className="link-dark cursor-pointer" onClick={() => setTabs(modalTabs.whereBag)} />
            </Tab.Pane>
          </Tab.Content>
        </Modal.Header>
        <Modal.Body className="pb-32 px-md-32 text-center">
          <Tab.Content>
            <Tab.Pane eventKey={modalTabs.default}>
              <div className="px-md-24 mb-md-64 font-size-md-24 mb-24 font-size-18">
                Enter the Bag ID to Register
              </div>
              <div className={showError ? '' : 'pb-24'} />
              {showError ? <p className="mb-0 text-danger">Invalid bag ID</p> : <div />}
              <Form.Control value={bagId} onChange={({ target }) => setBagId(target.value)} />
              <div className="text-center mt-24 mb-32">
                <Button variant="dark" size="sm" className="px-32" onClick={handleRegister}>Register</Button>
              </div>
              <Button onClick={() => setTabs(modalTabs.whereBag)} variant="transparent" className="p-0 m-0 border-0 cursor-pointer link-dark"><u>Where do I find my Bag Id?</u></Button>
            </Tab.Pane>
            <Tab.Pane eventKey={modalTabs.whereBag}>
              <small className="font-size-md-16 text-center">
                Scan the QR Code on your Take Back Bag
                <br />
                or
                <br />
                Use the code in the URL:
                <br />
                <a href="https://closet.fordays.com/redeem?code=1265432674" target="_blank" rel="noreferrer">https://closet.fordays.com/redeem?code=1265432674</a>
              </small>
              <div className="position-relative" style={{ isolation: 'isolate' }}>
                <div className="position-absolute" style={{ left: '2.5%', top: '52.5%', zIndex: 2 }}>
                  <FontAwesomeIcon className="display-3" icon={faArrowRight} style={{ transform: 'rotate(45deg)' }} />
                  <FontAwesomeIcon className="display-3" icon={faQrcode} style={{ transform: 'translateY(100%)' }} />
                </div>
                <BagIcon style={{ fontSize: '400px', filter: 'contrast(70%)', maxWidth: '100%' }} />
              </div>
              <div className="text-center mt-32">
                <Button onClick={() => setTabs(modalTabs.orderBag)} variant="transparent" className="p-0 m-0 border-0 cursor-pointer link-dark"><u>Dont have a bag yet?</u></Button>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey={modalTabs.orderBag}>
              <div>
                If you ordered your bag recently you will receive it shortly.
                <p>
                  Once you receive your bag, scan the QR-Code
                  to register and redeem your closet cash.
                </p>
                <div>If you have not ordered your Take Back Bag yet,</div>
                <div className="text-center">
                  <a href="https://fordays.com/" target="_blank" className="cursor-pointer link-dark" rel="noreferrer"><u>click here to get a bag</u></a>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Modal.Body>
      </Tab.Container>
    </Modal>
  );
};

export default RegisterBagModal;
