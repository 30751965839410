import { impactConstants } from '../../../config/constants/impactConstants';

const getAmount = ({ bagsSold, metric, tbbPct = 1 }) => {
  if (metric === 'emissionsReduced') {
    return (bagsSold * impactConstants.EMISSIONS_REDUCED * tbbPct).toFixed(1);
  }

  if (metric === 'waterSaved') {
    return (bagsSold * impactConstants.WATER_SAVED * tbbPct).toFixed(1);
  }

  if (metric === 'clothingDiverted') {
    return (bagsSold * impactConstants.CLOTHING_DIVERTED * tbbPct).toFixed(1);
  }
  return 0;
};

const getFormatedAmount = (amount) => {
  if (amount >= 1000 && amount < 1000000) {
    return `${(amount / 1000).toFixed(0)}k`;
  }
  if (amount >= 1000000 && amount < 1000000000) {
    return `${(amount / 1000000).toFixed(0)} million`;
  }
  if (amount > 1000000000) {
    return `${(amount / 1000000).toFixed(0)} billion`;
  }
  return amount;
};

const metricTextGenerator = ({ bagsSold, metric, tbbPct }) => {
  const amount = getAmount({
    bagsSold,
    metric,
    tbbPct: tbbPct ?? 1,
  });

  const formatedAmount = getFormatedAmount(Number(amount));

  const metricUomMap = {
    emissionsReduced: 'lbs',
    waterSaved: 'gal',
    clothingDiverted: 'lbs',
  };

  return `${formatedAmount} ${metricUomMap[metric]}`;
};

export default metricTextGenerator;
