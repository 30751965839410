import Login from '../../global/Login';
import ContentWrapper from '../../global/ContentWrapper';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';

const Home = () => {
  const { isSignedIn, userData } = useFirebaseAuth();
  let redirect = '/wallet';
  if (userData?.type === 'partner') {
    redirect = '/partner';
  }

  if (isSignedIn) {
    window.location.href = redirect;
  }

  return (
    <ContentWrapper>
      <Login loginredirect={redirect} />
    </ContentWrapper>
  );
};

export default Home;
