import CloudIcon from '../../../../images/icon-cloudy.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { getNextButtonStyles } from '../../../../utils/tbbRegistration';

import './ShippingLabelGenerationError.scss';

const ShippingLabelGenerationError = ({
  onRetry,
  onGetSupport,
  storeConfig,
}) => (
  <div className="ShippingLabelGenerationError">
    <div className="ShippingLabelGenerationError__message">
      <div className="ShippingLabelGenerationError__message--logo">
        <img
          src={CloudIcon}
          alt="cloud icon"
        />
      </div>
      <div className="ShippingLabelGenerationError__message--title">
        WE’VE ENCOUNTERED DIFFICULTIES WHILE GENERATING THE LABEL
      </div>
    </div>
    <Button
      type={BUTTON_TYPE.QUATERNARY}
      size={BUTTON_SIZE.LARGE}
      onClick={onRetry}
      style={getNextButtonStyles(storeConfig)}
    >
      Try again
    </Button>
    <div className="ShippingLabelGenerationError__bottom-cta">
      <div className="ShippingLabelGenerationError__bottom-cta--title">
        <p>Still not working?</p>
      </div>
      <Button
        type={BUTTON_TYPE.LINK_QUINARY}
        onClick={onGetSupport}
        style={getNextButtonStyles(storeConfig)}
      >
        Contact support
      </Button>
    </div>
  </div>
);

export default ShippingLabelGenerationError;
