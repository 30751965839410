import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { createNewUser } from '../../../utils/auth';
import api from '../../../api/api';
import crud from '../../../api/crud';
import Loader from '../../global/Loader';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState('');
  const [giftCardCode, setGiftCardCode] = useState('');
  const [view, setView] = useState('register');

  const handleRegistration = async (e) => {
    try {
      e.preventDefault();

      if (password !== confirmPassword) {
        setAlert('Passwords do not match');
        return;
      }
      setView('loading');
      let emailExists;
      try {
        emailExists = await crud.get({
          path: `/auth/${email}`,
        });
        setAlert(`A Trashie account associated with ${emailExists} already exists`);
      } catch (error) {
        emailExists = false;
      }

      if (!emailExists) {
        const newUser = await createNewUser(email, password);

        // await crud.post({
        //   path: '/users',
        //   body: {
        //     data: {
        //       email,
        //       uid: newUser.user.uid,
        //       meta: {
        //         migrated: false,
        //       },
        //     },
        //   },
        // });

        const data = await api.newRegistration(newUser.user.uid);
        setGiftCardCode(data.code);
        setView('success');
      }
    } catch (error) {
      console.error(error);
      setAlert(error.message);
      setView('register');
    }
  };

  const handleContinue = async () => {
    window.parent.postMessage(`success:${giftCardCode}`, '*');
  };

  const handleClose = async () => {
    window.parent.postMessage('close', '*');
  };

  return (
    <div className="w-100 vh-100">
      {view === 'register' && (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center p-8">
          <h1 className="text-center">Get $10 in instant TrashieCash™!</h1>
          <h4 className="text-center">Register for a new TrashieCash™ Account:</h4>
          <Form
            onSubmit={handleRegistration}
            className="d-flex flex-column align-items-center w-75 mt-16"
          >
            <Form.Group className="mb-8 w-100">
              <Form.Control
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-8 w-100">
              <Form.Control
                type="password"
                value={password}
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-8 w-100">
              <Form.Control
                type="password"
                value={confirmPassword}
                placeholder="Re-enter Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="dark"
              type="submit"
              className="d-block w-100 mb-16"
              onClick={(e) => handleRegistration(e)}
            >
              Register
            </Button>
          </Form>
          <p className="text-center text-danger">{alert}</p>
          <a
            className="btn btn-block mt-1  py-2 w-100"
            href="https://www.fordays.com/pages/earn-closet-cash"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
          <Button
            type="submit"
            className="d-block w-100"
            id="close-iframe-button"
            onClick={(e) => handleClose(e)}
          >
            Continue to Checkout
          </Button>
        </div>
      )}
      {view === 'loading' && (
        <div className="w-100 h-100  d-flex align-items-center justify-content-center">
          <Loader message="...loading" />
        </div>
      )}
      {view === 'success' && (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
          <h1>Thanks for Registering!</h1>
          <h3 className="text-dark">{giftCardCode || null}</h3>
          <h4>Your $10 off will be applied automatically at checkout</h4>
          <Button variant="dark" type="button" onClick={handleContinue}>Continue to Checkout</Button>
        </div>
      )}
    </div>
  );
};
export default SignUp;
