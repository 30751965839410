import TrashieCoin from '../../../../trashie/images/TC-icon-white.svg';

const SignInHeader = ({ view }) => {
  let sideText = 'Log in / Sign up';
  if (view === 'verifyCode') {
    sideText = 'Log In';
  }
  return (
    <div className="container-content-sm flex flex-between w-100 mx-auto pt-3 px-3 pb-2">
      <div className="flex flex-center mr-auto">
        <img src={TrashieCoin} width={16} alt="TrashieCash Icon" />
        <span className="mx-2 font-small text-white-green">TrashieCash™</span>
      </div>
      <div className="flex flex-center mr-auto">
        <span className="mx-2 font-small text-white-green">{sideText}</span>
      </div>
    </div>
  );
};

export default SignInHeader;
