import { PROMO_ID_PARAM } from '../config/routes';
import { EVENT_STREAM_APP_TYPES, EVENT_STREAM_EVENT_TYPES } from '../config/service';
import { getSearchParam } from './routes';
import { postSubscribeStatus, sendEventStream } from './service';

const finishProcess = async ({
  userRegistered = false,
  userRegisteredMethod = 'manual', // manual | google
  subscribeEmail = '',
  optIn = false,
  search = '',
  wfExtensionFlow = false,
}) => {
  if (userRegistered) {
    window.gtag?.('event', 'sign_up', { method: userRegisteredMethod });
    window.fbq?.('trackCustom', 'sign_up');

    if (subscribeEmail) {
      await postSubscribeStatus(subscribeEmail, optIn);
    }

    const query = new URLSearchParams(search);
    const promoId = getSearchParam(query, PROMO_ID_PARAM);

    await sendEventStream({
      event: EVENT_STREAM_EVENT_TYPES.USER_REGISTERED,
      application: EVENT_STREAM_APP_TYPES.TRASHIE,
      data: {
        ...(promoId && { promoId }),
      },
    });

    return;
  }

  await sendEventStream({
    event: wfExtensionFlow ?
      EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN_WF_EXTENSION :
      EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN,
    application: EVENT_STREAM_APP_TYPES.TRASHIE,
  });
};

export default finishProcess;
