import { Button, Form } from 'react-bootstrap';

const EmailAndPasswordForm = ({
  email,
  password,
  setEmail,
  setPassword,
  onSubmit,
  onBackClick,
}) => (
  <div className="px-3">
    <p className="mb-24 text-white">
      Login with your Closet Cash Email and Password
    </p>
    <Form.Group>
      <Form.Control
        type="email"
        className="custom-input border-0 rounded-3_5 mb-2"
        id="loginEmail"
        value={email}
        placeholder="EMAIL"
        aria-describedby="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Form.Control
        type="password"
        className="custom-input border-0 rounded-3_5 mb-2"
        id="loginPassword"
        value={password}
        placeholder="PASSWORD"
        aria-describedby="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="dark"
        type="submit"
        className="mt-16 rounded-pill py-8 w-100"
        onClick={() => {
          onSubmit();
        }}
      >
        Login
      </Button>
    </Form.Group>
    <Button
      variant="link"
      onClick={onBackClick}
      type="button"
      className="custom-button--small w-100 mt-16 text-cc-newsprint text-center text-decoration-none"
    >
      Back to Sign In
    </Button>
  </div>
);

export default EmailAndPasswordForm;
