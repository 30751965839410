import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import SortedBy from '../SortedBy';
import ArrowBack from '../../images/arrow-back.svg';
import TitleSectionAccount from '../TitleSectionAccount';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../Button';
import style from './ListHeaders.module.scss';
import { ROUTE_MY_ACCOUNT } from '../../config/routes';

const {
  buttonContainer,
  titleContainer,
  buttonText,
} = style;
const BUTTON_BACK_TEXT = 'BACK TO MY ACCOUNT';
const ListHeaders = ({ title, subTitle, actionTitle, sortedText, backButtonText = BUTTON_BACK_TEXT, buttonClassName = '', returnActionRoute }) => {
  const history = useHistory();
  return (
    <div className={titleContainer}>
      <Button
        type={BUTTON_TYPE.SECONDARY}
        size={BUTTON_SIZE.MEDIUM}
        className={classNames(buttonContainer, buttonClassName)}
        onClick={() => history.push(returnActionRoute ?? ROUTE_MY_ACCOUNT)}
      >
        <img src={ArrowBack} alt="arrow-back" />
        {backButtonText && (
          <div className={buttonText}>
            {backButtonText}
          </div>
        )}
      </Button>
      <TitleSectionAccount title={title} subTitle={subTitle} actionTitle={actionTitle} />
      {sortedText && <SortedBy sortedText={sortedText} />}
    </div>
  );
};

export default ListHeaders;
