/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import crud from '../../../api/crud';
import ContentWrapper from '../../global/ContentWrapper';
import { useAlert } from '../../providers/AlertProvider';
import Loader from '../../global/Loader';
import QRCodeModal from '../../global/QRCodeModal';
import BagIcon from '../../icons/BagIcon';
import RegisterBagModal from '../../global/RegisterBagModal/RegisterBagModal';
import useTbbAmountsExchange from '../../../hooks/useTbbAmountsExchange';

const TakeBackBag = () => {
  const { user } = useFirebaseAuth();
  const [takeBackBags, setTakeBackBags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showRegisterBagModal, setShowRegisterBagModal] = useState(false);
  const [postageInfo, setPostageInfo] = useState({ email: '', qrUrl: '', labelUrl: '' });
  const setAlert = useAlert();
  const { tbbAmount } = useTbbAmountsExchange();

  useEffect(() => {
    (async () => {
      const redemptionData = await crud.get({
        path: '/redemptions',
        options: {
          where: {
            redeemedBy: user.uid,
          },
          include: {
            redemptionStatus: true,
          },
        },
      });
      const data = redemptionData.map((redemption) => {
        let parsedRedemption;
        if (redemption.labeldata) {
          parsedRedemption = {
            ...redemption,
            labeldata: JSON.parse(redemption.labeldata),
          };
        } else {
          parsedRedemption = redemption;
        }
        return parsedRedemption;
      });

      setTakeBackBags(data);
      setIsLoading(false);
    })();
  }, []);

  const handleShowShippingOptions = async (bag) => {
    try {
      setIsLoading(true);
      setPostageInfo({ email: '', qrUrl: '', labelUrl: '' });
      const { _id: code } = bag;
      let qrUrl = bag.labeldata.forms.find(({ form_type: formType }) => formType === 'label_qr_code')?.form_url;
      let labelUrl = bag.labeldata?.postage_label?.label_url;
      if (!qrUrl || !labelUrl) {
        const data = await crud.post({
          path: '/redemptions',
          body: {
            data: {
              id: code,
              email: user.email,
              redemptionStatus: (bag.redemptionStatus === 'returned' || bag.redemptionStatus === 2) ? 2 : 1,
              creditAmount: tbbAmount,
              storeId: 1,
            },
          },
        });

        setTakeBackBags(bags => bags.map(item => {
          if (item._id === code) {
            return { ...item, labelData: data };
          }
          return item;
        }));
        qrUrl = data.forms.find(({ form_type: formType }) => formType === 'label_qr_code').form_url;
        labelUrl = data.postage_label?.label_pdf_url ?
          data.postage_label.label_pdf_url :
          data.postage_label?.label_url;
      }
      setPostageInfo({
        code,
        labelUrl,
        qrUrl,
      });
      setShowQRModal(true);
    } catch (error) {
      console.log(error);
      setAlert({
        type: 'notification',
        message: 'Unable to create QR Code at this time. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectToTracker = (bag) => {
    window.open(
      bag.labeldata.tracker.public_url,
      '_blank',
    );
  };

  const handleRedirectToWebsite = () => {
    window.location.href = 'https://fordays.com/';
  };

  const handleRegisterBag = () => {
    setShowRegisterBagModal(true);
  };

  const statusMap = {
    claimed: {
      redemptionStatus: 'claimed',
      statusLabel: 'Registered',
      nextStepLabel: 'Send Back Bag',
      onClickNextStep: handleShowShippingOptions,
      buttonText: 'View Shipping Options',
      onClickButton: handleShowShippingOptions,
      iconBg: 'bg-primary',
    },
    returned: {
      redemptionStatus: 'returned',
      statusLabel: 'Processed',
      nextStepLabel: 'Use Closet Cash',
      onClickNextStep: handleRedirectToWebsite,
      buttonText: null,
      onClickButton: handleRedirectToWebsite,
      iconBg: 'bg-success',
    },
    pending: {
      redemptionStatus: 'pending',
      statusLabel: 'In Transit',
      nextStepLabel: 'Wait',
      onClickNextStep: null,
      buttonText: 'View Shipping Tracker',
      onClickButton: handleRedirectToTracker,
      iconBg: 'bg-info',
    },
  };

  if (isLoading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <Loader
          message="Loading..."
        />
      </div>
    );
  }

  return (
    <>
      <ContentWrapper>
        <div className="text-center mb-32">
          <div>Thanks for doing your part! Take the next steps to complete the process. </div>
          <small>Don’t see your bag?</small>
          <Button variant="transparent" onClick={handleRegisterBag} className="p-0 m-0 ms-8 border-0 cursor-pointer link-dark"><small><u>Click here to register an existing bag</u></small></Button>
        </div>
        <Row xs={1} className="bg-white p-32 border border-dark">
          {takeBackBags.map((bag, index) => {
            const { redemptionStatus, creditAmt, id } = bag;
            const { status } = redemptionStatus;
            return (
              <div key={id}>
                <Col>
                  <Row className="gx-32 gy-24">
                    <Col xs={12} md="auto" className="flex-md-column d-flex justify-content-center position-relative mt-lg-24 mt-0">
                      <div className="d-md-none position-absolute top-0 w-100 text-end">
                        <small>Bag ID: {parseInt(id, 10) || 'TBD'}</small>
                      </div>
                      <div
                        className={`${!status ? 'bg-info' : statusMap[status].iconBg} rounded-circle d-flex align-items-center justify-content-center border border-dark mt-lg-0 mt-32`}
                      >
                        <BagIcon className="m-16 font-size-60" />
                      </div>
                    </Col>
                    <Col>
                      <p className="mb-16">Closet Cash Earned: {`$${creditAmt || 'TBD'}`}</p>
                      <p className="mb-16">Status: {statusMap[status].statusLabel || 'Bag Shipped'}</p>
                      {
                        status && statusMap[status].redemptionStatus === 'pending'
                          ? <p className="p-0 m-0">Next Step: {statusMap[status].nextStepLabel}</p>
                          : <Button variant="transparent" className="p-0 m-0 border-0 cursor-pointer link-dark" onClick={() => statusMap[status].onClickNextStep(bag)}><u>Next Step: {statusMap[status].nextStepLabel}</u></Button>
                      }
                      {!status && <Button variant="transparent" className="p-0 m-0 border-0 cursor-pointer link-dark" onClick={handleRegisterBag}><u>Next Step: Register Bag</u></Button>}
                    </Col>
                    <Col xs={12} md={4} lg={3} className="d-flex flex-column">
                      {status && statusMap[status].buttonText && (
                        <Button
                          size="sm"
                          variant="dark w-100 px-0"
                          type="button"
                          onClick={() => statusMap[status].onClickButton(bag)}
                        >
                          {statusMap[status].buttonText}
                        </Button>
                      )}
                      {!status && (
                        <Button
                          size="sm"
                          variant="info w-100"
                          type="button"
                          onClick={handleRegisterBag}
                        >
                          Register This Bag
                        </Button>
                      )}
                      <small className="d-none d-md-block mt-auto ms-auto">Bag ID: {parseInt(id, 10) || 'TBD'}</small>
                    </Col>
                  </Row>
                </Col>
                {index !== takeBackBags.length - 1 && <Col className="mt-24 mb-32 border-dark border-top" />}
              </div>
            );
          })}
        </Row>
      </ContentWrapper>
      <QRCodeModal
        show={showQRModal}
        setShow={setShowQRModal}
        postageInfo={postageInfo}
      />
      <RegisterBagModal show={showRegisterBagModal} setShow={setShowRegisterBagModal} />
    </>
  );
};

export default TakeBackBag;
