import { Form, Modal, Button, ModalBody, ModalFooter, ModalTitle, ModalHeader } from 'react-bootstrap';

const UpdateBagsSoldModal = ({
  bagsSold,
  onChange,
  onSave,
  isShow,
  onCancel,
}) => (
  <Modal show={isShow}>
    <ModalHeader>
      <ModalTitle>Update Number of Bags Sold</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <Form.Control
        value={bagsSold}
        onChange={onChange}
      />
    </ModalBody>
    <ModalFooter>
      <Button onClick={onSave}>Save</Button>
      <Button variant="outline-dark" onClick={onCancel}>Cancel</Button>
    </ModalFooter>
  </Modal>
);

export default UpdateBagsSoldModal;
