import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ShippingInfo from '../ShippingInfo';
import BackArrow from '../../../../images/circle-arrow-left-gray.svg';
import { ROUTE_TAKE_BACK_BAGS } from '../../../../config/routes';
import { shippingMethodProperties } from '../../../../utils/tbbRegistration';

const ShippingDetails = ({
  shippingMethod,
  code,
  expirationLabelDate,
  qrCodeImgUrl,
  printLabelUrl }) => {
  const { shippingTitle } =
   useMemo(() => shippingMethodProperties[shippingMethod] ?? {}, [shippingMethod]);

  return (
    <div className="ShippingDetails">
      <div className="ShippingDetails__container">
        <div className="ShippingInfo__container--backbutton">
          <Link to={ROUTE_TAKE_BACK_BAGS} className="ShippingInfo__container--backbutton--link">
            <img src={BackArrow} alt="back-arrow" />
            GO TO ALL TBBS
          </Link>
        </div>
        <div className="ShippingInfo__container--title">
          {shippingTitle}
        </div>
        <div className="ShippingDetails__container--content">
          <ShippingInfo
            code={code}
            shippingMethod={shippingMethod}
            expirationLabelDate={expirationLabelDate}
            qrCodeImgUrl={qrCodeImgUrl}
            printLabelUrl={printLabelUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default ShippingDetails;
