import { Image } from 'react-bootstrap';

import LoadingBar from '../../../global/LoadingBar';
import TrashieHeart from '../../../images/red-heart-icon.svg';

import './RewardContent.scss';

const ModalContentLoading = () => (
  <div className="body-style loading">
    <div className="body-top">
      <div className="trashie-logo">
        <Image
          className="trashie-coin-logo"
          src={TrashieHeart}
          alt="trashie-image"
        />
      </div>
      <div className="loading-text">
        doing trashie
        things
      </div>
      <LoadingBar />
    </div>
  </div>
);

export default ModalContentLoading;
