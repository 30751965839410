const POSTHOG_DATA_ATTRIBUTE = 'data-ph-capture-attribute-';

const POSTHOG_PROPERTIES = {
  TRACKER_ID: 'tracker-id',
  ACTION_ID: 'action-id',
  MODIFIER_ID: 'modifier-id',
  REWARD_ID: 'reward-id',
  REWARD_NAME: 'reward-name',
  OFFER_MERCHANT_ID: 'offer-merchant-id',
  OFFER_MERCHANT_NAME: 'offer-merchant-name',
};

const POSTHOG_CAPTURE_ATTRIBUTES = {
  TRACKER_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.TRACKER_ID}`,
  ACTION_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.ACTION_ID}`,
  MODIFIER_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.MODIFIER_ID}`,
  REWARD_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.REWARD_ID}`,
  REWARD_NAME: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.REWARD_NAME}`,
  OFFER_MERCHANT_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.OFFER_MERCHANT_ID}`,
  OFFER_MERCHANT_NAME: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.OFFER_MERCHANT_NAME}`,
};

const TRACKER_EVENT_TYPES = {
  CLICKED_REWARD: 'Clicked Reward',
  CLICKED_AFFILIATE_LINK: 'Clicked Affiliate Link',
  REDEEMED_REWARD: 'Redeemed Reward',
};

const TRACKER_ACTION_TYPES = {
  REWARD_ITEM: 'reward-item',
  AFFILIATE_LINK: 'affiliate-link',
  CONFIRM_BUTTON: 'confirm-button',
  OFFER_ITEM: 'offer-item',
};

const MODIFIERS = {
  WF_LINK: 'wf-link',
};

function setTrackerData() {
  // eslint-disable-next-line no-restricted-syntax
  for (const i in this) {
    if (typeof this[i] === 'object') {
      this[i].init = this.init;
      this[i].init();
      this[i].parent = this;
      this[i].trackerKey = i;
    }
  }

  return this;
}

const TRACKER_IDS = {
  init: setTrackerData,
  dashboard: {
    hero: {
      trashiecashRewards: {
        selectable: true,
      },
      earnTrashiecash: {
        selectable: true,
      },
      shopTakeBackBag: {
        selectable: true,
      },
      shippingLabels: {
        selectable: true,
      },
      instantDeals: {
        selectable: true,
      },
    },
    instantDeals: {
      viewAllButton: {
        selectable: true,
      },
    },
    trashiecashRewards: {
      viewAllButton: {
        selectable: true,
      },
    },
    earnTrashiecash: {
      viewAllButton: {
        selectable: true,
      },
    },
  },
  rewards: {
    list: {
      rewardItem: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_REWARD,
        action: TRACKER_ACTION_TYPES.REWARD_ITEM,
      },
    },
    marketing: {
      slide: {
        selectable: true,
      },
    },
    singleMerchantPage: {
      rewardItem: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_REWARD,
        action: TRACKER_ACTION_TYPES.REWARD_ITEM,
      },
      confirmButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.REDEEMED_REWARD,
        action: TRACKER_ACTION_TYPES.CONFIRM_BUTTON,
      },
    },
  },
  myRewards: {
    list: {
      cardLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      titleLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      claimLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
    detail: {
      imageLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      cardLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      uniqueLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      codeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      pinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      claimLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
    terms: {
      imageLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
  },
  offers: {
    offerItem: {
      selectable: true,
      action: TRACKER_ACTION_TYPES.OFFER_ITEM,
    },
  },
}.init();

const getTrackerKeys = (trackerKeys = [], tracker = {}) => {
  if (tracker.parent) {
    return getTrackerKeys([...trackerKeys, tracker.trackerKey], tracker.parent);
  }

  return trackerKeys;
};

const getTrackerId = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  const trackerKeys = getTrackerKeys([], tracker);

  return trackerKeys.reverse().join('.');
};

const getEventName = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  return tracker.event;
};

const getActionType = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  return tracker.action;
};

const getConditionalModifier = (attribute, modifier = '', condition = false) => (condition ? { [attribute]: modifier } : {});

export {
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
  TRACKER_EVENT_TYPES,
  POSTHOG_CAPTURE_ATTRIBUTES,
  MODIFIERS,
  getTrackerId,
  getEventName,
  getActionType,
  getConditionalModifier,
};
