import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MetricCard = ({
  icon,
  subtext,
  metricText,
  className,
}) => (
  <div className={`p-32 bg-white border border-dark rounded-0 ${className}`}>
    <FontAwesomeIcon
      className="mb-24"
      icon={icon}
      size="3x"
    />
    <div className="text-secondary mb-16">
      {subtext}
    </div>
    <h1>
      {metricText}
    </h1>
  </div>
);

export default MetricCard;
