import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from '../../global/ContentWrapper';
import Loader from '../../global/Loader';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';

const Logout = () => {
  const history = useHistory();
  const { signOut } = useFirebaseAuth();

  useEffect(() => {
    signOut();
    sessionStorage.removeItem('logged_into_closet_cash');
    sessionStorage.removeItem('closet_cash_balance');
    history.push('/');
  }, []);
  return (
    <ContentWrapper>
      <Loader message="...logging out" />
    </ContentWrapper>
  );
};

export default Logout;
