import './EarnRewardBanner.scss';
import { TAKE_BACK_BAG_COLLECTION_URL } from '../../config/externalURL';

const EarnRewardBanner = () => (
  <div
    className="EarnRewardBanner"
    onClick={() => { window.location.href = TAKE_BACK_BAG_COLLECTION_URL; }}
    aria-hidden="true"
  >
    <div className="EarnRewardBanner__container">
      <div className="EarnRewardBanner__container--title">
        Earn rewards for recycling
      </div>
      <div className="EarnRewardBanner__container--description">
        Buy a take back bag for $20 and get 30 TrashieCash™ to spend on brands you love.
      </div>
    </div>
  </div>
);

export default EarnRewardBanner;
