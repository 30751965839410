import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Pagination, Table, Placeholder, Tab, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import crud from '../../../api/crud';
import ContentWrapper from '../../global/ContentWrapper';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../providers/AlertProvider';

export const intArray = length => Array.from(new Array(length));

const tabs = {
  orderList: 'orderList',
  orderDetail: 'orderDetail',
};

const Orders = () => {
  const [pageTab, setPageTab] = useState(tabs.orderList);
  const [orders, setOrders] = useState([]);
  const [LoadingOrders, setLoadingOrders] = useState(false);
  const [cursors, setCursors] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState([]);
  const { user } = useFirebaseAuth();
  const setAlert = useAlert();

  const [selectedOrderIndexes, setSelectedOrderIndexes] = useState([null, null]);
  const selectedOrder = useMemo(() => {
    if (selectedOrderIndexes[0] === null || selectedOrderIndexes[1] === null) {
      return {};
    }
    return orders[selectedOrderIndexes[0]][selectedOrderIndexes[1]].node;
  }, [selectedOrderIndexes, orders]);

  useEffect(() => {
    (async () => {
      if (!orders[page] && !LoadingOrders) {
        try {
          setLoadingOrders(true);
          const currCursor = page ? cursors[page - 1] : '';
          const data = await crud.post({
            path: '/shopify/orders',
            body: {
              email: user.email,
              cursor: currCursor,
            },
          });
          console.log(data);
          setOrders((currOrders) => [...currOrders, data.edges]);
          setCursors((currCursors) => [...currCursors, data.edges[0]?.cursor]);
          setHasNextPage((currHasNextPage) => [...currHasNextPage, data.pageInfo.hasNextPage]);
        } catch {
          setAlert({
            type: 'notification',
            message: 'Unable to get new Orders page. Please try again later',
          });
        } finally {
          setLoadingOrders(false);
        }
      }
    })();
  }, [page]);

  const handleLoadNextPage = () => {
    if (hasNextPage[page]) { setPage(currPage => currPage + 1); }
  };

  const handleLoadPreviousPage = () => {
    if (page !== 0) { setPage(currPage => currPage - 1); }
  };

  const handleOpenDetailsPage = (indexes) => {
    setSelectedOrderIndexes(indexes);
    setPageTab(tabs.orderDetail);
  };

  const handleBackToOrdersListPage = () => {
    setSelectedOrderIndexes([null, null]);
    setPageTab(tabs.orderList);
  };

  return (
    <ContentWrapper>
      <Tab.Container activeKey={pageTab}>
        <Tab.Content>
          <Tab.Pane eventKey={tabs.orderList}>
            <Card>
              <Card.Body>
                <h1 className="mb-24">Order History</h1>
                <Table responsive variant="primary" hover className="w-100">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Payment Status</th>
                      <th>Fulfillment Status</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(LoadingOrders && !orders[page]) && intArray(10).map(() => (
                      <tr>
                        {intArray(5).map(() => (
                          <td className="px-0 py-16">
                            <Placeholder animation="glow"><Placeholder className="w-100 py-16" aria-label="placeholder" /></Placeholder>
                          </td>
                        ))}
                      </tr>
                    ))}
                    {(!LoadingOrders && (orders?.length === 0 || orders[page]?.length === 0)) && (
                    <tr>
                      <td className="p-0" colSpan={5}>
                        No Orders Found!
                      </td>
                    </tr>
                    )}
                    {orders[page]?.map(({ node: order }, index) => (
                      <tr className="cursor-pointer" onClick={() => handleOpenDetailsPage([page, index])}>
                        <td className="py-24">{order.name}</td>
                        <td className="py-24">{moment(order.createdAt).format('MMMM Do, YYYY')}</td>
                        <td className="text-capitalize py-24">
                          {`${order.displayFinancialStatus?.replace('_', ' ')}`.toLowerCase()}
                        </td>
                        <td className="text-capitalize py-24">
                          {`${order.displayFulfillmentStatus?.replace('_', ' ')}`.toLowerCase()}
                        </td>
                        <td className="py-24">
                          $
                          {order.totalPriceSet?.presentmentMoney?.amount || '0'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination>
                  {page !== 0 && <Pagination.Prev onClick={handleLoadPreviousPage} />}
                  <Pagination.Item active>{page + 1}</Pagination.Item>
                  {hasNextPage[page] && <Pagination.Next onClick={handleLoadNextPage} />}
                </Pagination>
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey={tabs.orderDetail} mountOnEnter unmountOnExit>
            <Card>
              <Card.Body>
                <Button variant="link" className="px-0 text-decoration-none" onClick={handleBackToOrdersListPage}>
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" className="me-8" />
                  Back to Order History
                </Button>
                <header className="d-flex align-items-center mt-16">
                  <h1>
                    Order
                    {' '}
                    {selectedOrder.name}
                  </h1>
                </header>
                <main>
                  <p>
                    Placed on
                    {' '}
                    {moment(selectedOrder.createdAt).format('MMMM Do, YYYY - h:mm:ss a')}
                  </p>
                  <Table borderless className="text-end" striped={false}>
                    <thead>
                      <tr>
                        <th className="text-start">Product</th>
                        <th>SKU</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrder.lineItems?.edges.map(({ node: item }) => (
                        <tr>
                          <td className="text-start">{item.name}</td>
                          <td>{item.sku}</td>
                          <td>
                            $
                            {' '}
                            {item.discountedUnitPriceSet.presentmentMoney.amount}
                          </td>
                          <td>{item.currentQuantity}</td>
                          <td>
                            $
                            {' '}
                            {item.discountedTotalSet.presentmentMoney.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr><td className="py-16">{}</td></tr>
                      {selectedOrder.totalDiscountsSet?.presentmentMoney?.amount !== '0.0' && (
                        <tr>
                          <td className="text-start">Discount</td>
                          <td colSpan={4}>
                            $
                            -
                            {selectedOrder.totalDiscountsSet?.presentmentMoney?.amount}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="text-start">Subtotal</td>
                        <td colSpan={4}>
                          $
                          {selectedOrder.subtotalPriceSet?.presentmentMoney?.amount}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">Shipping</td>
                        <td colSpan={4}>
                          $
                          {selectedOrder.totalShippingPriceSet?.presentmentMoney?.amount}
                        </td>
                      </tr>
                      <tr className="fw-bold">
                        <td className="text-start">Total</td>
                        <td colSpan={4}>
                          $
                          {selectedOrder.totalPriceSet?.presentmentMoney?.amount}
                          <span className="ms-8">USD</span>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <hr />
                  {selectedOrder?.billingAddress && (
                    <>
                      <h2 className="fw-bold">Billing Address</h2>
                      <div>
                        {selectedOrder?.billingAddress.address1}
                        ,
                      </div>
                      <div>{selectedOrder?.billingAddress.address2}</div>
                      <div>{selectedOrder?.billingAddress.city}</div>
                      <div>{selectedOrder?.billingAddress.province}</div>
                      <div>{selectedOrder?.billingAddress.zip}</div>
                      <p>{selectedOrder?.billingAddress.country}</p>
                      <hr />
                    </>
                  )}
                  {selectedOrder?.shippingAddress && (
                    <>
                      <h2 className="fw-bold">Shipping Address</h2>
                      <div>
                        {selectedOrder?.shippingAddress.address1}
                        ,
                      </div>
                      <div>{selectedOrder?.shippingAddress.address2}</div>
                      <div>{selectedOrder?.shippingAddress.city}</div>
                      <div>{selectedOrder?.shippingAddress.province}</div>
                      <div>{selectedOrder?.shippingAddress.zip}</div>
                      <p>{selectedOrder?.shippingAddress.country}</p>
                      <hr />
                    </>
                  )}
                  <p>
                    <span className="fw-bold me-8">Payment Status:</span>
                    <span className="text-capitalize">
                      {`${selectedOrder?.displayFinancialStatus?.replace('_', ' ')}`.toLowerCase()}
                    </span>
                  </p>
                  <p>
                    <span className="fw-bold me-8">Fulfillment Status:</span>
                    <span className="text-capitalize">
                      {`${selectedOrder?.displayFulfillmentStatus?.replace('_', ' ')}`.toLowerCase()}
                    </span>
                  </p>
                </main>
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </ContentWrapper>
  );
};

export default Orders;
