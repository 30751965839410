import InvalidIcon from '../../../../images/invalid-icon.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';

import './Invalid.scss';

const Invalid = ({
  onClick,
  storeConfig = {},
  message,
}) => (
  <div className="RegisterInvalid">
    <div className="RegisterInvalid__message">
      <div className="RegisterInvalid__message--logo">
        <img
          src={InvalidIcon}
          alt="invalid icon"
        />
      </div>
      <div className="RegisterInvalid__message--title">
        {message ?? 'THE BAG CODE YOU ARE TRYING TO REGISTER WAS NOT FOUND'}
      </div>
      <div className="RegisterInvalid__message--subtitle">
        Please verify it or contact support.
      </div>
    </div>
    <Button
      type={BUTTON_TYPE.QUATERNARY}
      size={BUTTON_SIZE.LARGE}
      onClick={onClick}
      style={!storeConfig.isTrashie ? ({
        background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
        color: storeConfig.registerTbbFlow?.nextButton?.textColor,
        border: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
      }) : {}}
    >
      CONTACT SUPPORT
    </Button>
  </div>
);

export default Invalid;
