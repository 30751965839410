import { useLocation } from 'react-router-dom';

import { isRedeemPath, isContainerPath } from '../../utils/routes';

import styles from './Container.module.scss';

const {
  main,
  blank,
  content,
} = styles;

const Container = ({ children }) => {
  const location = useLocation();

  if (!isContainerPath(location.pathname)) {
    return children;
  }

  return (
    <div className={`${main} ${isRedeemPath(location.pathname) && blank}`}>
      <div className={content}>
        {children}
      </div>
    </div>
  );
};

export default Container;
