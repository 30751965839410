import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import LoadingBar from '../LoadingBar';

import './MessageContent.scss';

const MessageContent = ({
  image,
  title,
  subtitle,
  loader,
  cta = {},
}) => (
  <div className="MessageContent">
    <img
      className="MessageContent__image"
      src={image}
      alt="message icon"
    />
    {(title || subtitle) && (
      <div className="MessageContent__text">
        {title && (
          <p className="MessageContent__text-title">
            {title}
          </p>
        )}
        {subtitle && (
          <p
            className="MessageContent__text-subtitle"
            title={subtitle}
          >
            {subtitle}
          </p>
        )}
      </div>
    )}
    {loader && (
      <div className="MessageContent__loader">
        <LoadingBar />
      </div>
    )}
    {cta?.text && cta?.onClick && (
      <Button
        type={BUTTON_TYPE.QUATERNARY}
        size={BUTTON_SIZE.LARGE}
        onClick={cta.onClick}
      >
        {cta.text}
      </Button>
    )}
  </div>
);

export default MessageContent;
