import { useMemo } from 'react';
import { formatMMDDYY } from '../../../utils/dates';
import HourGlassDoneIcon from '../../../images/hourglass-done-icon.svg';
import NewCircledIcon from '../../../images/new-circled-icon.svg';
import PersonNoIcon from '../../../images/person-no-icon.svg';
import TrollIcon from '../../../images/troll-icon.svg';
import WarningIcon from '../../../images/warning-icon.svg';
import HeavyDollar from '../../../images/heavy-dollar-sign.svg';
import TrashieCoin from '../../../images/TC-icon-black.svg';

const RewardSummary = ({ rewardItem, title }) => {
  const {
    exchangeValue,
    expireAt,
    forFirstTimeCustomerOnly,
    description,
    isValidOnDiscountedItems,
    isStackable,
  } = rewardItem;

  const summaryItems = useMemo(() => [
    {
      id: 'rewardDescription',
      icon: HeavyDollar,
      label: description,
      enabled: true,
    },
    {
      id: 'expires',
      icon: HourGlassDoneIcon,
      label: `Expires on: ${formatMMDDYY(expireAt)}`,
      enabled: Boolean(expireAt),
    },
    exchangeValue > 0 &&
    {
      id: 'price',
      icon: TrashieCoin,
      label: `Price: ${exchangeValue > 0 ? `${exchangeValue} TrashieCash™` : ''}`,
      enabled: true,
    },
    {
      id: 'firstTime',
      icon: NewCircledIcon,
      label: 'First time customers only',
      enabled: forFirstTimeCustomerOnly,
    },
    {
      id: 'saleExcluded',
      icon: TrollIcon,
      label: 'Offer excludes discounted and sale items',
      enabled: !isValidOnDiscountedItems,
    },
    {
      id: 'notStackable',
      icon: PersonNoIcon,
      label: 'Cannot be combined with other discounts',
      enabled: !isStackable,
    },
    {
      id: 'nonRefundable',
      icon: WarningIcon,
      label: 'Non-refundable',
      enabled: true,
    },
  ], [
    description,
    exchangeValue,
    forFirstTimeCustomerOnly,
    isValidOnDiscountedItems,
    isStackable,
    expireAt,
  ]);

  return (
    <div className="summary">
      {title && (
        <p className="title">{title}</p>
      )}
      {summaryItems.map(({
        id,
        icon,
        label,
        enabled,
      }) => (
        enabled ? (
          <div
            key={id}
            className="message"
          >
            <div className="icon-container">
              <img src={icon} alt={`${id} icon`} />
            </div>
            <p>{label}</p>
          </div>
        ) : null
      ))}
    </div>
  );
};

export default RewardSummary;
