import { useMemo } from 'react';

import { REWARDS_VALIDATED_STATE } from '../../../config/rewards';
import { isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';
import CheckCircleIcon from '../../../images/check-circle-icon.svg';
import SkullCrossbonesIcon from '../../../images/skull-crossbones-icon.svg';
import TrashieCashCircledIcon from '../../../images/trashie-cash-circled-icon.svg';

import './Flag.scss';

/* Parent element MUST have 'position: relative' */

const Flag = ({ status, text = '', rewardProperties }) => {
  const {
    svgImg,
    label,
    style,
  } = useMemo(() => {
    switch (true) {
      case isLimitErrorCode(status):
        return {
          type: REWARDS_VALIDATED_STATE.SOLD_OUT,
          svgImg: SkullCrossbonesIcon,
          label: rewardProperties?.soldOut?.label || 'SOLD OUT',
          style: rewardProperties?.soldOut?.style || {},
        };
      case isUserLimitErrorCode(status):
        return {
          type: REWARDS_VALIDATED_STATE.USER_LIMIT,
          svgImg: CheckCircleIcon,
          label: rewardProperties?.claimed?.label || 'CLAIMED',
          style: rewardProperties?.claimed?.style || {},
        };
      case Boolean(text):
        if (text === 'FREE') {
          return {
            svgImg: TrashieCashCircledIcon,
            label: rewardProperties?.free?.label || '',
            style: rewardProperties?.free?.style || {},
          };
        }
        return {
          svgImg: TrashieCashCircledIcon,
          label: text,
          style: rewardProperties?.flaggedValue?.style || {},
        };
      default:
        return {};
    }
  }, [status, text]);

  if (!label) {
    return null;
  }
  return (
    <div className="RewardFlag" style={{ ...style }}>
      <img src={svgImg} alt="icon" />
      <p>{label}</p>
    </div>
  );
};

export default Flag;
