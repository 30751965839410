import { useMemo } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { formatCurrencyNumber } from '../../utils/numbers';
import { TAKE_BACK_BAG_COLLECTION_URL } from '../../config/externalURL';
import TrashieCoin from '../../images/TC-icon.svg';
import './UserBalance.scss';

const UserBalance = () => {
  const { isLoading, userData } = useFirebaseAuth();
  const {
    currentBalance = 0,
  } = useMemo(() => {
    if (isLoading || !userData?.balance) {
      return {};
    }

    return userData.balance;
  }, [isLoading, userData]);

  return (
    <div className="UserBalance">
      <div className="UserBalance__container">
        <div className="UserBalance__container--title">TrashieCash™ Balance</div>
        <div className="UserBalance__container--balance">
          {formatCurrencyNumber(currentBalance, new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
          }))}
          <img src={TrashieCoin} className="UserBalance__container--icon" alt="trashie cash icon" />
        </div>
        {
            currentBalance > 0 ?
              <Link to="/transactions" className="UserBalance__container--link">VIEW ALL TRANSACTIONS</Link>
              : <a href={TAKE_BACK_BAG_COLLECTION_URL} className="UserBalance__container--link">BUY A TAKE BACK BAG</a>
        }
      </div>
    </div>
  );
};

export default UserBalance;
