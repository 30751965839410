/* eslint-disable react/jsx-one-expression-per-line */
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faWater, faTrash, faQrcode } from '@fortawesome/free-solid-svg-icons';
import '../../../scss/donut.scss';
import { Button, Row, Col, Image } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../global/ContentWrapper';
import Card from '../../global/Card/Card';
import MetricCard from './MetricCard';
import metricTextGenerator from './metricTextGenerator';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import UpdateBagsSoldModal from './UpdateBagsSoldModal';
import { useAlert } from '../../providers/AlertProvider';
import BagIcon from '../../icons/BagIcon';
import crud from '../../../api/crud';
import { TOTAL_IMPACT_PATH, STORE_ID_PARAM } from '../../trashie/config/service';

const PartnerShipPortal = () => {
  const setAlert = useAlert();
  const { user } = useFirebaseAuth();
  const [takeBackBags, setTakeBackBags] = useState(0);
  const [emissionsReduced, setEmissionsReduced] = useState(0);
  const [waterSaved, setWaterSaved] = useState(0);
  const [bagsSold, setBagsSold] = useState(0);
  const [storeData, setStoreData] = useState(0);
  const [clothingDiverted, setClothingDiverted] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowUpdateBagsSoldModal, setIsShowUpdateBagsSoldModal] = useState(false);

  const getAndSetTakeBackBagData = useCallback(async () => {
    if (!user || !user.uid) {
      return;
    }
    setIsLoading(true);
    try {
      const userData = await crud.get({
        path: '/users',
        options: {
          where: {
            uid: user.uid,
          },
        },
      });

      const fetchedStoreData = await crud.get({
        path: `/stores/${userData[0].meta.storeId}`,
      });

      const { completionValues } = await crud.get({
        path: `${TOTAL_IMPACT_PATH}`.replace(STORE_ID_PARAM, fetchedStoreData.uid),
      });

      const totalBags = completionValues[0] ? Number(completionValues[0].redeemedCodes) : 0;

      setStoreData(fetchedStoreData);
      setTakeBackBags(totalBags);
      setBagsSold(fetchedStoreData?.meta?.tbbSold);
    } catch (error) {
      setAlert({
        type: 'notification',
        message: 'Unable to fetch sales data',
      });
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    getAndSetTakeBackBagData();
  }, []);

  useEffect(() => {
    setWaterSaved(metricTextGenerator({
      metric: 'waterSaved',
      bagsSold: takeBackBags || 0,
      tbbPct: storeData.meta?.tbbPct,
    }));

    setEmissionsReduced(metricTextGenerator({
      metric: 'emissionsReduced',
      bagsSold: takeBackBags || 0,
      tbbPct: storeData.meta?.tbbPct,
    }));

    setClothingDiverted(metricTextGenerator({
      metric: 'clothingDiverted',
      bagsSold: takeBackBags || 0,
      tbbPct: storeData.meta?.tbbPct,
    }));
  }, [takeBackBags]);

  const handleUpdateBagsClick = () => {
    setIsShowUpdateBagsSoldModal(true);
  };

  const handleBagsSoldChange = (e) => {
    setBagsSold(e.target.value);
  };

  const handleSaveBagsSold = async () => {
    setIsLoading(true);
    try {
      await crud.post({
        path: '/stores',
        body: {
          id: storeData.id,
          data: {
            meta: {
              ...storeData.meta,
              tbbSold: Number(bagsSold),
            },
          },
        },
      });

      await getAndSetTakeBackBagData();
    } catch (error) {
      setAlert({
        type: 'alert',
        message: `Unable to save bags sold total. Error: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
      setIsShowUpdateBagsSoldModal(false);
    }
  };

  const _percentBagsRedeemed = Number(((takeBackBags / bagsSold) * 100).toFixed());
  const percentBagsRedeemed = Number.isNaN(_percentBagsRedeemed) ? 0 : _percentBagsRedeemed;
  return (
    <ContentWrapper size="xl" isLoading={isLoading}>
      <Row>
        <Col lg={4}>
          <Card>
            <div className="my-16">
              Welcome,
            </div>
            <Image
              className="mx-auto mb-32 w-100"
              alt="logo"
              src={storeData.meta?.circleLogoUrl}
              style={{ maxWidth: '225px' }}
            />
            <div className="text-secondary mb-40">As of {moment().format('YYYY-MM-DD')}</div>
            <div className="mb-16">
              <CircularProgressbarWithChildren value={percentBagsRedeemed}>
                <h1>
                  {percentBagsRedeemed}%
                </h1>
                of bags sold registered
              </CircularProgressbarWithChildren>
            </div>
            <hr />
            <Row style={{ marginLeft: -40, marginRight: -40 }}>
              <Col>
                <BagIcon style={{ fontSize: '100px', filter: 'contrast(70%)', maxWidth: '100%' }} />
                <h4 className="mt-16">Bags Sold <br />{bagsSold}</h4>
              </Col>
              <Col>
                <Link to="/partner/customers">
                  <FontAwesomeIcon size="6x" icon={faQrcode} className="link-dark cursor-pointer" />
                  <h4 className="mt-16">Bags Registered <br /> {takeBackBags}</h4>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={4} className="mt-lg-0 mt-24">
          <MetricCard
            icon={faCar}
            subtext="CO2 emissions reduced"
            metricText={emissionsReduced}
            className="mb-24"
          />
          <MetricCard
            icon={faWater}
            subtext="Water Saved"
            metricText={waterSaved}
            className="mb-24"
          />
          <MetricCard
            icon={faTrash}
            subtext="Clothing Diverted"
            metricText={clothingDiverted}
          />
        </Col>
        <Col className="text-center mt-lg-0 mt-24 offset-lg-1" lg={3}>
          <Link
            to={{
              pathname: 'https://forms.gle/HmqDCb3h4mLBjiEs6',
            }}
            target="_blank"
          >
            <Button variant="dark" type="button" className="w-100">Order More Bags</Button>
          </Link>
          <Button
            variant="primary"
            type="button"
            className="mt-24 w-100"
            onClick={handleUpdateBagsClick}
          >
            Update Number of Bags Sold
          </Button>
        </Col>
      </Row>
      <UpdateBagsSoldModal
        bagsSold={bagsSold}
        onSave={handleSaveBagsSold}
        onChange={handleBagsSoldChange}
        isShow={isShowUpdateBagsSoldModal}
        onCancel={() => setIsShowUpdateBagsSoldModal(false)}
      />
    </ContentWrapper>
  );
};

export default PartnerShipPortal;
