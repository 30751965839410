import crud from './crud';

// This function will return the data and the appropriate configuration for the redemption code
// table (partner/customers)
const fetchPaginatedRedemptions = async (options) => {
  const qtyRedemptions = await crud.get({
    path: '/redemptions/table/custom',
    options,
  });

  return qtyRedemptions;
};

export default {
  fetchPaginatedRedemptions,
};
