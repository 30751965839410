import style from './TakeBackBagsNoData.module.scss';
import tbbFrontAll from '../../images/take-back-bag-empty-state_2x.webp';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../Button';
import { TAKE_BACK_BAG_URL } from '../../config/externalURL';

const { noDataContainer, noDataImage, noDataButton } = style;
const TakeBackBagsNoData = () => (
  <div className={noDataContainer}>
    <img src={tbbFrontAll} alt="tbb bags" className={noDataImage} />
    <div>YOU HAVEN’T REGISTERED ANY TAKE BACK BAGS YET</div>
    <Button
      type={BUTTON_TYPE.PRIMARY}
      size={BUTTON_SIZE.LARGE}
      className={noDataButton}
      onClick={() => { window.location.href = TAKE_BACK_BAG_URL; }}
    >
      BUY A TAKE BACK BAG
    </Button>
  </div>
);

export default TakeBackBagsNoData;
