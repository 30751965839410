import { useCallback, useMemo } from 'react';
import { usePostHog } from 'posthog-js/react';
import classNames from 'classnames';

import Flag from '../Flag';
import {
  getActionType,
  getEventName,
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
} from '../../../config/tracker';
import { REWARD_AMOUNT_TYPES } from '../../../config/rewards';
import { isLimitErrorCode, isUserLimitErrorCode } from '../../../utils/rewards';
import { useFirebaseAuth } from '../../../../global/FirebaseProvider/FirebaseProvider';
import TCCoinSvg from '../../../global/SvgComponents/TCCoin_svg';

import './RewardItem.scss';

const {
  rewards: {
    list: {
      rewardItem: trackerRewardItem,
    },
  },
} = TRACKER_IDS;

const RewardItem = ({ rewardItem, onClick, rewardProperties }) => {
  const { isSignedIn, user } = useFirebaseAuth();
  const posthog = usePostHog();
  const { flaggedValue } = rewardProperties || {};

  const {
    uid: rewardId,
    name: rewardName,
    description: rewardDescription,
    amountType,
    rewardAmount,
    exchangeValue,
    status,
    isNew,
    store: {
      id: storeId,
      title: storeTitle,
      logoUrl,
      imgUrl,
      RewardLogoProperties,
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const disabled = useMemo(() => (
    isLimitErrorCode(status) || isUserLimitErrorCode(status)
  ), [status]);

  const amountText = useMemo(() => (
    REWARD_AMOUNT_TYPES[amountType]?.getText(rewardAmount, true) ?? ''
  ), [amountType, rewardAmount]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      posthog.capture(
        getEventName(trackerRewardItem),
        {
          [POSTHOG_PROPERTIES.REWARD_ID]: rewardId,
          [POSTHOG_PROPERTIES.REWARD_NAME]: rewardName,
          ...(isSignedIn && user && ({
            $set: { email: user.email },
          })),
        },
      );

      onClick({
        storeId,
        rewardId,
      });
    }
  }, [rewardId, rewardName, onClick, isSignedIn, user, storeId]);

  const isFreeReward = Number(exchangeValue) === 0;

  return (
    <button
      className={classNames('RewardsViewItem', { disabled })}
      id={`RewardsViewItem_${rewardItem.uid}`}
      onClick={handleOnClick}
      type="button"
      aria-label="reward item"
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleOnClick();
        }
      }}
      {...{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerRewardItem),
        [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerRewardItem),
        [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardId,
        [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardName,
      }}
    >
      <Flag
        status={status}
        text={disabled ? (
          undefined
        ) : (
          (isNew && 'NEW') ||
          (isFreeReward && 'FREE') ||
          (Number(exchangeValue) <= flaggedValue?.exchangeValueFlagged && `${flaggedValue.label}${exchangeValue}`) ||
          undefined
        )}
        properties={rewardProperties}
      />
      <div
        className="RewardsViewItem__cover"
        alt="store image"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${imgUrl})`,
        }}
      >
        <img
          className="RewardsViewItem__cover--logo"
          src={logoUrl}
          style={{
            ...RewardLogoProperties,
            ...(RewardLogoProperties?.height ? { maxHeight: RewardLogoProperties.height, height: 'unset' } : {}),
          }}
          alt="store logo"
        />
      </div>
      <div
        className="RewardsViewItem__content"
      >
        <div className="RewardsViewItem__content--text">
          <p className="RewardsViewItem__content--title" title={storeTitle}>{storeTitle}</p>
          <p className="RewardsViewItem__content--subtitle" title={rewardDescription}>{rewardDescription}</p>
        </div>
        <div className="RewardsViewItem__content--chip">
          <p className="RewardsViewItem__content--amount" title={typeof amountText === 'string' && amountText}>
            {amountText}
          </p>
          {!isFreeReward ? (
            <div className="RewardsViewItem__content__trashieCash">
              <p className="RewardsViewItem__content__trashieCash--exchangeValue">
                {exchangeValue}
              </p>
              <TCCoinSvg width="14" height="14" />
            </div>
          ) : null}
        </div>
      </div>
    </button>
  );
};

export default RewardItem;
