import { useCallback, useEffect, useState } from 'react';
import AddressSelectItemComponent from '../Components/AddressSelectItemComponent';
import Style from '../Register.module.scss';
import { getUserAddresses } from '../../../utils/service';
import LoadingBar from '../../../global/LoadingBar';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import InfoSection from '../../../global/InfoSection';

const {
  addressSelectContinueButton,
  addressSelectItemComponentContainer,
  addressSelectViewContainer,
  bottomSectionContainer,
  bottomSectionTitle,
  bottomSectionCtaContainer,
  addressSelectInfoSection,
} = Style;

const SelectAddressView = ({ onSubmit, onNewAddress, storeConfig, onEmptyAddresses }) => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({});

  const loadAddresses = useCallback(async () => {
    setIsLoading(true);

    const { data: addressesData, error: addressesDataError } = await getUserAddresses({
      pageSize: 3,
    });

    if (addressesDataError || addressesData?.userAddresses?.length === 0) {
      setAddresses([]);
      setIsLoading(false);
      onEmptyAddresses();

      return;
    }

    const {
      userAddresses,
    } = addressesData;

    setAddresses(userAddresses);
    setSelectedAddress(userAddresses[0] || null);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadAddresses();
  }, []);

  const onSelectAddress = (index, address) => {
    setSelectedIndex(index);
    setSelectedAddress(address);
  };

  const mapAddress = useCallback((address) => ({
    firstName: address?.firstName,
    lastName: address?.lastName,
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city,
    provinceCode: address?.provinceCode,
    zip: address?.zip,
  }), [selectedAddress]);

  return (
    <div className={addressSelectViewContainer}>
      <div className={addressSelectInfoSection}>
        <InfoSection
          titleText="Return Address"
          descriptionText={'We\'ll use this address to create your pre-paid shipping label'}
        />
      </div>
      {isLoading ? (
        <LoadingBar />
      ) : (
        <div className={addressSelectItemComponentContainer}>
          {addresses.map((address, index) => (
            <AddressSelectItemComponent
              key={JSON.stringify(address)}
              index={index}
              address={address}
              selectedIndex={selectedIndex}
              onSelect={onSelectAddress}
            />
          ))}
        </div>
      )}
      <div className={addressSelectContinueButton}>
        <Button
          type={BUTTON_TYPE.QUATERNARY}
          size={BUTTON_SIZE.LARGE}
          onClick={() => onSubmit(mapAddress(selectedAddress))}
          disabled={false}
          style={!storeConfig.isTrashie ? ({
            background: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            color: storeConfig.registerTbbFlow?.nextButton?.textColor,
            border: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
          }) : {}}
        >
          Continue
        </Button>
      </div>
      <div className={bottomSectionContainer}>
        <div className={bottomSectionTitle}>
          <p>Different address?</p>
        </div>
        <div className={bottomSectionCtaContainer}>
          <Button
            type={BUTTON_TYPE.LINK_QUINARY}
            onClick={onNewAddress}
            style={!storeConfig.isTrashie ? ({
              color: storeConfig.registerTbbFlow?.nextButton?.bgColor,
            }) : {}}
          >
            ENTER NEW ADDRESS
          </Button>
        </div>
      </div>
    </div>

  );
};

export default SelectAddressView;
