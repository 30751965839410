import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatMoney } from '../../../utils/formatMoney';
import { useAlert } from '../../providers/AlertProvider';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import useBalance from '../../../hooks/useBalance';
import ContentWrapper from '../../global/ContentWrapper';
import crud from '../../../api/crud';

const Cash = () => {
  const { user } = useFirebaseAuth();
  const [swaps, setSwaps] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const cashBalance = useBalance(swaps);
  const [isLoading, setIsLoading] = useState(false);
  const setAlert = useAlert();

  const transactionsToRows = (swapObjs = []) => {
    const ledgerItems = [];
    let bal = 0;

    const sortedSwaps = swapObjs.map((obj) => {
      const newObj = { ...obj };
      newObj.createdAt = newObj.createdAt || '2020-01-01T00:00:00.000Z';
      return newObj;
    }).sort((a, b) => {
      if (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()) {
        return 1;
      }

      return -1;
    });

    const groupedTransactions = [];

    sortedSwaps.forEach((swap, i) => {
      if (i === 0) {
        groupedTransactions.push([swap]);
        return;
      }

      const prevSwap = groupedTransactions[groupedTransactions.length - 1];

      if (
        prevSwap[0].groupId === swap.groupId &&
        swap.groupId !== undefined &&
        swap.productName !== 'Closet Cash' &&
        swap.type === prevSwap[0].type
      ) {
        groupedTransactions[groupedTransactions.length - 1].push(swap);
        return;
      }
      groupedTransactions.push([swap]);
    });

    groupedTransactions.forEach((groupedTransaction) => {
      const date = (groupedTransaction[0].createdAt)
        ? new Date(groupedTransaction[0].createdAt.split('T')[0])
        : new Date('2020-01-01');
      let netGroupCredit = 0;
      let label = '';
      let type = 'deposit';
      const productData = [];

      groupedTransaction.forEach((transaction) => {
        type = transaction.transactionType.type; // All of the items should have the same type
        const credit = (transaction.type === 'deposit' || transaction.type === '2') ? Number(transaction.amount) : Number(transaction.amount) * -1;
        netGroupCredit += credit;
        label = transaction.meta.label ? transaction.meta.label : label;

        productData.push({
          swapItemName: transaction.productName,
          swapItemId: transaction.uid,
          swapValue: Number(transaction.amount),
          swapTransactionId: transaction.orderId,
        });
      });

      bal += Number(netGroupCredit);
      ledgerItems.push({
        action: (type === 'deposit' || type === '2') ? `Earned cash from ${groupedTransaction.length === 1 ? groupedTransaction[0].productName : `${groupedTransaction.length} products`}` : 'Spent cash',
        date: `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`,
        credit: netGroupCredit,
        balance: bal,
        label,
        type,
        groupId: groupedTransaction[0].groupId,
        swapData: productData,
        productName: groupedTransaction[0].productName,
      });
    });

    return ledgerItems.reverse();
  };

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        setIsLoading(true);
        const fetchedTransactions = await crud.get({
          path: '/transactions',
          options: {
            where: {
              userId: user.uid,
            },
            include: {
              transactionType: true,
            },
            orderBy: {
              createdAt: 'desc',
            },
          },
        });

        const transactionsToCashArray = fetchedTransactions.map(transaction => {
          let newTransaction;
          if (transaction.typeId === 1) {
            newTransaction = { ...transaction, type: 'withdraw' };
          } else if (transaction.typeId === 2) {
            newTransaction = { ...transaction, type: 'deposit' };
          }
          return newTransaction;
        });
        const ledgerItems = transactionsToRows(transactionsToCashArray);
        if (mounted || false) {
          setTransactions(ledgerItems);
          setSwaps(transactionsToCashArray);
        }
      } catch (error) {
        setAlert({
          type: 'notification',
          message: error.message,
          error,
        });
      } finally {
        setIsLoading(false);
      }
    };
    init();

    return () => { mounted = false; };
  }, []);

  return (
    <ContentWrapper isLoading={isLoading} loadingMessage="Loading Cash Balance">
      <div className="border-dark border-bottom pb-24">
        <h4 className="text-center">TrashieCash™ Balance:</h4>
        <h1 className="text-center text-dark">
          {formatMoney(cashBalance)}
        </h1>
      </div>
      <Row>
        <Col md="6" xs="12" className="my-lg-64 my-8 px-lg-48 px-48 text-center offset-md-3">
          <h5>Shop with TrashieCash™</h5>
          <a
            className="btn btn-sm btn-block btn-dark border-dark rounded-pill d-lg-none"
            href="https://fordays.com"
            target="_blank"
            rel="noreferrer"
          >
            Redeem at fordays.com
          </a>
          <a
            className="btn btn-dark rounded-pill border-dark mt-4 "
            href="https://fordays.com"
            target="_blank"
            rel="noreferrer"
          >
            Shop fordays.com
          </a>
          <a
            className="btn btn-dark rounded-pill border-dark mt-4 "
            href="https://www.trashie.io/spend-offers"
            target="_blank"
            rel="noreferrer"
          >
            Shop Rewards on Trashie.io
          </a>
        </Col>
        {/* Todo: Unhide this section once we get it working */}
        <Col md="6" xs="12" className="my-lg-64 my-16 px-lg-48 px-48 text-center d-none">
          <h5>Sharing is caring!</h5>
          <a
            className="btn btn-sm btn-block btn-dark border-dark rounded-pill d-lg-none"
            href="/transfer"
            rel="noreferrer"
          >
            Gift your TrashieCash™
          </a>
          <a
            className="btn btn-dark border-dark rounded-pill d-none d-lg-block mt-4"
            href="/transfer"
            target="_blank"
            rel="noreferrer"
          >
            Gift your TrashieCash™
          </a>
        </Col>
      </Row>
      <div className="bg-white p-24 border border-dark">
        <h4 className="ms-8 text-center mb-16">Transaction History</h4>
        <Row className="mb-16">
          <Col xs="4" sm="3" className="font-weight-bold">Date</Col>
          <Col xs="5" sm="6" className="font-weight-bold">Description</Col>
          <Col xs="3" className="font-weight-bold text-center">Amount</Col>
        </Row>
        {transactions?.map((data, idx) => (
          <Row key={`transaction-history-${idx.toString()}`} className="row border-dark border-top py-16">
            <Col xs="4" sm="3">{data.date}</Col>
            <Col xs="5" sm="6">{data.action}</Col>
            <Col xs="3" className={`col-3 text-center ${data.credit > 0 ? 'text-dark' : 'text-danger'}`}>
              {formatMoney(data.credit)}
            </Col>
          </Row>
        ))}
      </div>
    </ContentWrapper>
  );
};

export default Cash;
