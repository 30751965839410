import { useEffect, useState, useCallback, useRef } from 'react';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import { ANCHOR_MY_REWARDS } from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { getUserRewards } from '../../utils/service';
import { setDocumentTitle } from '../../utils/utils';
import ListHeaders from '../../global/ListHeaders';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyRewardsList from '../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';

import './MyRewards.scss';

const MyRewards = () => {
  const timeoutRef = useRef(null);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const { data: rewardsData, error: rewardsDataError } = await getUserRewards({
      pageSize: LIST_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setIsLoading(false);

      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_MY_REWARDS);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  useEffect(() => {
    loadRewards();
  }, [page]);

  useEffect(() => {
    setDocumentTitle('My Wallet');

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return (
    <div id={ANCHOR_MY_REWARDS} className="MyRewards">
      <div className="MyRewards__content">
        <div className="MyRewards__content--container">
          <ListHeaders
            title="My Wallet"
            sortedText=""
            backButtonText=""
            buttonClassName="MyRewards__backButton"
          />
          <div className="MyRewards__content--section">
            {!isLoading && !listData.length ? (
              <ZeroState />
            ) : (
              <>
                {(isLoading && (
                <LoadingBar className="MyRewards__content--loader" />
                )) || (
                <MyRewardsList rewardsList={listData} />
                )}
                <ListPaginator
                  pageSize={LIST_PAGE_SIZE}
                  totalItems={totalItems}
                  currentPage={page}
                  isLoading={isLoading}
                  onPageSelection={handlePageSelection}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
