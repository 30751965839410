import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { REWARD_ID_PARAM, REWARD_ID_ROUTE_PARAM, ROUTE_REWARD_ID } from '../../config/routes';
import HeroSection from '../../global/HeroSection';
import OffersPreview from '../Offers/OffersPreview';
import RewardsPreview from '../Rewards/RewardsPreview';
import DealsPreview from '../Deals/DealsPreview';
import { setDocumentTitle } from '../../utils/utils';
import useScrollToSection from '../../../../hooks/useScrollToSection';

import './DashboardNew.scss';
import ImpactMetrics from '../../global/ImpactMetrics/ImpactMetrics';

const DashboardNew = () => {
  const history = useHistory();
  useScrollToSection();

  useEffect(() => {
    // Start support for old rewards url from webflow
    const searchParams = new URLSearchParams(history.location.search);
    const searchParamRewardId = searchParams.get(REWARD_ID_PARAM);

    if (searchParamRewardId) {
      history.replace(ROUTE_REWARD_ID.replace(REWARD_ID_ROUTE_PARAM, searchParamRewardId));
      return;
    }
    // End support for old rewards url from webflow

    setDocumentTitle('Dashboard');
  }, []);

  return (
    <div className="DashboardNew">
      <HeroSection />
      <div className="DashboardNew__container">
        <DealsPreview />
        <RewardsPreview />
        <OffersPreview />
        <ImpactMetrics />
      </div>
    </div>
  );
};

export default DashboardNew;
