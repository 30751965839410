import { useState, useEffect, useCallback } from 'react';
import 'firebase/auth';
import firebase from 'firebase/app';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import Input from '../../global/Input';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../global/Button';
import { sendSignInEmailLink } from '../../utils/service';
import DotsLoader from '../../global/DotsLoader';
import { ROUTE_HOME } from '../../config/routes';

export const viewType = {
  VIEW_EMAIL_FORM: 'emailForm',
  VIEW_EMAIL_SENT: 'emailSent',
  VIEW_EMAIL_CONFIRM: 'emailConfirm',
};

const LoginWithEmailLink = () => {
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();

  const [view, setView] = useState(viewType.VIEW_EMAIL_FORM);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleRedirect = useCallback(() => {
    window.location.href = ROUTE_HOME;
  }, []);

  const handleOnEmailChange = useCallback((newValue) => {
    setErrorMessage('');
    setEmail(newValue);
  }, []);

  const signInWithEmailLink = useCallback(async (emailForSignIn) => {
    setIsLoading(true);

    firebase.auth().signInWithEmailLink(emailForSignIn, window.location.href)
      .then(() => {
        window.localStorage.removeItem('emailForSignIn');
        handleRedirect();
        setIsLoading(false);
      })
      .catch(() => {
        setErrorMessage('Oops! something went wrong. Please try again later or contact support.');
        setIsLoading(false);
      });
  }, []);

  const requestSignInWithEmailLink = useCallback(async (emailForSignIn) => {
    if (!emailForSignIn) {
      setErrorMessage('Please enter your email');
      return;
    }

    setIsLoading(true);

    const { error } = await sendSignInEmailLink({ email: emailForSignIn });

    if (error) {
      setErrorMessage('Oops! something went wrong. Please try again later or contact support.');
      setIsLoading(false);
      return;
    }

    window.localStorage.setItem('emailForSignIn', emailForSignIn);
    setView(viewType.VIEW_EMAIL_SENT);
    setErrorMessage('');
    setIsLoading(false);
  }, []);

  const handleOnClick = useCallback(() => {
    if (view === viewType.VIEW_EMAIL_CONFIRM) {
      signInWithEmailLink(email);
      return;
    }

    requestSignInWithEmailLink(email);
  }, [view, email, signInWithEmailLink, requestSignInWithEmailLink]);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth) {
      handleRedirect();
    }
  }, [isSignedIn, userData, isLoadingFirebaseAuth, isLoading]);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setIsLoading(true);
      const emailForSignIn = window.localStorage.getItem('emailForSignIn');

      if (!emailForSignIn) {
        setView(viewType.VIEW_EMAIL_CONFIRM);
        setEmail('');
        setErrorMessage('Please enter your email');
        setIsLoading(false);
        return;
      }

      setEmail(emailForSignIn);
      setIsLoading(false);
      signInWithEmailLink(emailForSignIn);
    }
  }, []);

  return (
    <div className="Login">
      {view === viewType.VIEW_EMAIL_SENT ? (
        <>
          <span>Link Sent to:</span>
          <span>{email}</span>
        </>
      ) : (
        <>
          <Input
            type="text"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleOnEmailChange}
            errorMessage={errorMessage}
            disabled={isLoading || isLoadingFirebaseAuth}
          />
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={handleOnClick}
            disabled={!email || isLoading || isLoadingFirebaseAuth}
          >
            {isLoading || isLoadingFirebaseAuth ? (
              <DotsLoader />
            ) : (
              (view === viewType.VIEW_EMAIL_CONFIRM && 'Confirm email') || 'Send me a login link'
            )}
          </Button>
        </>
      )}
    </div>
  );
};

export default LoginWithEmailLink;
