import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_SETTINGS, WF_RETURN_URL_SEARCH_PARAM } from '../../config/routes';
import { WILDLINK_AFFILIATE_LINK_URL } from '../../config/externalURL';
import { viewType } from '../../global/GlobalLogin';
import { getSearchParam } from '../../utils/routes';

const UUID_SEARCH_PARAM = 'uuid';
const URI_SEARCH_PARAM = 'uri';
const WF_DEVICE_ID_SEARCH_PARAM = 'wfedid';
const AFFILIATE_LINK_QUERY_PARAM = 'tc';
const AFFILIATE_LINK_DEVICE_PARAM = 'd';

function isAffiliateLinkRedirect(uriParam) {
  return uriParam.startsWith(WILDLINK_AFFILIATE_LINK_URL);
}

/**
 * Get URI param from query
 * @param query
 * @returns {string}
 */
function getURIParam(query) {
  return query.has(URI_SEARCH_PARAM) ? decodeURIComponent(query.get(URI_SEARCH_PARAM)) : '';
}

/**
 *
 * @param query
 * @returns {string}
 */
function getDeviceId(query) {
  return query.has(AFFILIATE_LINK_DEVICE_PARAM) ? decodeURIComponent(query.get(AFFILIATE_LINK_DEVICE_PARAM)) : '';
}

function getWildfireDeviceId(query) {
  return query.has(WF_DEVICE_ID_SEARCH_PARAM) ? decodeURIComponent(query.get(WF_DEVICE_ID_SEARCH_PARAM)) : '';
}

const getViewType = (typeParam) => (
  (
    typeParam !== viewType.VIEW_EMAIL_FORM &&
    typeParam !== viewType.VIEW_EMAIL_SENT &&
    typeParam !== viewType.VIEW_EMAIL_PASSWORD_FORM
  ) ? undefined : typeParam
);

/**
 *
 * @param query
 * @param uuid
 * @param {History<LocationState>} history
 * @return {string}
 */
function getRedirectPath(query, uuid, history, view, from) {
  let redirectPath = view === viewType.VIEW_EMAIL_SENT ? ROUTE_SETTINGS : ROUTE_DASHBOARD;
  const uriParam = getURIParam(query);
  const deviceId = getDeviceId(query);
  const wfreturnUrl = getSearchParam(query, WF_RETURN_URL_SEARCH_PARAM);

  const { state: locationState } = history.location;
  if (from) {
    redirectPath = from;
  } else if (locationState?.from) {
    const { pathname, search: fromSearch, hash } = locationState.from;

    if (pathname !== ROUTE_LOGIN) {
      redirectPath = `${pathname}${fromSearch && `?${fromSearch}`}${hash}`;
    }
  }

  if (uriParam) {
    redirectPath = isAffiliateLinkRedirect(uriParam) ?
      `${uriParam}&${AFFILIATE_LINK_DEVICE_PARAM}=${deviceId}&${AFFILIATE_LINK_QUERY_PARAM}=${uuid}` :
      `${uriParam}?${UUID_SEARCH_PARAM}=${uuid}`;
  }

  if (wfreturnUrl) {
    redirectPath += `${redirectPath.indexOf('?') === -1 ? '?' : '&'}${WF_RETURN_URL_SEARCH_PARAM}=${wfreturnUrl}`;
  }

  return redirectPath;
}

export {
  getURIParam,
  getViewType,
  getRedirectPath,
  getWildfireDeviceId,
  WF_DEVICE_ID_SEARCH_PARAM,
};
