/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import 'firebase/auth';
import firebase from 'firebase/app';
import { useHistory, useLocation } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js';
import { Button, Form, Tab } from 'react-bootstrap';
import { fbKey } from '../../../utils/firebase';
import { getConfig } from '../../../config/config';
import crud from '../../../api/crud';
import { useAlert } from '../../providers/AlertProvider';
import Loader from '../Loader';
import { signIn, signInWithGoogle } from '../../../utils/auth';
import GoogleButton from '../GoogleButton';

const Login = ({
  loginredirect = '/wallet',
  handleRedirect = true,
  showBranding = true,
}) => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [view, setView] = useState('emailAndPassword');
  const [loginEmail, setloginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const initialEmail = query.get('email') || '';

  const setAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(false);

  if (isPhoneConfirmed) {
    window.location.reload();
  }

  const handleSendEmail = async (userEmail) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Sending verification code to your email');
      await crud.post({
        path: '/email',
        body: {
          email: userEmail,
          messageId: 'send verification',
          messageData: {},
        },
      });

      setIsLoading(false);
      setIsEmailSent(true);
      setEmail(userEmail);
    } catch (error) {
      setIsEmailSent(false);
      console.error(error);
      setAlert({
        message: error.message,
        type: 'notification',
      });
      setIsLoading(false);
    }
  };

  const handleSendPhoneCode = async ({ phone: userPhone }) => {
    try {
      setLoadingMessage('Sending code to your phone...');
      setIsLoading(true);

      window.appVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );

      const { appVerifier } = window;
      const phoneData = parsePhoneNumber(userPhone.toString(), 'US');

      if (!phoneData || !phoneData.isValid()) {
        const errorObj = {
          message: 'Invalid Phone number',
        };
        throw (errorObj);
      }

      const result = await firebase
        .auth()
        .signInWithPhoneNumber(phoneData.number, appVerifier);

      if (result) {
        setIsCodeSent(true);
        setConfirmationResult(result);
        setIsLoading(false);
      } else {
        const errorObj = { message: 'Unable to send SMS' };
        throw (errorObj);
      }
    } catch (error) {
      console.error(error);
      setAlert({
        message: error.message || 'Unable to send SMS',
        type: 'alert',
      });
    }
  };

  const handleVerifyCodeEmail = async (userCode) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Verifying Code...');
      const token = await crud.post({
        path: '/auth',
        body: {
          email,
          code: userCode,
        },
      });

      await firebase.auth().signInWithCustomToken(token);
      if (handleRedirect && loginredirect) {
        window.location.href = loginredirect;
      }
    } catch (error) {
      setIsLoading(false);
      setAlert({
        message: error.message || 'Unable to validate email',
        type: 'alert',
      });
    }
  };

  const handleVerifyCodePhone = async (userCode) => {
    try {
      setLoadingMessage('Verifying Code...');
      setIsLoading(true);
      await confirmationResult.confirm(userCode);
      setIsPhoneConfirmed(true);
    } catch (error) {
      setIsLoading(false);
      setAlert({
        message: error.message || 'Unable to validate phone number',
        type: 'alert',
      });
    }
  };

  const handleUserLogin = async () => {
    try {
      setErrorMessage('');
      await signIn(loginEmail, password);

      if (handleRedirect) {
        window.location.href = loginredirect;
      }
    } catch (error) {
      // TODO - Implement error code validation utils
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        setErrorMessage('Invalid email or password.');
      }
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();

      if (handleRedirect) {
        // TODO - Implement login success validation utils
        if (loginredirect !== '/embed/loginsuccess') {
          history.push('/wallet');
        }
        window.location.href = loginredirect;
      }
    } catch (error) {
      setAlert({
        type: 'notification',
        message: 'Unable to sign in with google',
        error,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (initialEmail) {
        setEmail(initialEmail);
        handleSendEmail(initialEmail);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (isCodeSent) {
      setView('confirmCode');
    }
    if (isEmailSent) {
      setView('emailSent');
    }
  }, [isCodeSent, isEmailSent]);

  useEffect(() => {
    if (isCodeSent) {
      setView('confirmCode');
    }
    if (isEmailSent) {
      setView('emailSent');
    }
  }, [isCodeSent, isEmailSent]);

  window.addEventListener('storage', () => {
    const dbRequest = window.indexedDB.open('firebaseLocalStorageDb', 1);

    dbRequest.onsuccess = async () => {
      const db = dbRequest.result;
      const stores = ['firebaseLocalStorage'];

      const tx = db.transaction(stores);
      const req = tx.objectStore('firebaseLocalStorage').getAll();
      req.onsuccess = () => {
        const localUserData = req.result.find(({ fbase_key: fbBaseKey }) => fbBaseKey === fbKey);
        if (handleRedirect && localUserData) {
          window.location.href = loginredirect;
        }
      };
    };
  });

  if (isLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
        <Loader message={loadingMessage} className="text-center" />
      </div>
    );
  }

  return (
    <Tab.Container activeKey={view}>
      <div className="container-content-xs mx-auto text-center mt-5 card rounded-0 border border-dark px-8 py-24 p-md-40 bg-white">
        {showBranding && <img className="mx-auto mb-48" alt="logo" src="//cdn.shopify.com/s/files/1/0503/2601/2056/files/for-days-logo-black_140x.png?v=1614290300" style={{ width: '150px' }} />}
        <Tab.Content>
          <Tab.Pane eventKey="phoneForm">
            <h5 className="mb-64">Verify your identity to continue</h5>
            <Form.Group>
              <Form.Control
                type="phone"
                className="form-control border-dark rounded-0 mb-32"
                id="phone"
                value={phone}
                placeholder="Phone number"
                aria-describedby="phone"
                onChange={(e) => setPhone(e.target.value)}
              />
              <Button
                variant="dark"
                type="button"
                disabled={!phone}
                className="modal-button w-100 rounded-pill py-8 mb-32"
                onClick={(event) => handleSendPhoneCode({ event, email, phone })}
              >
                Send Code
              </Button>
              <Button
                variant="link"
                className="modal-button w-100 rounded-pill py-8 link-dark"
                type="button"
                onClick={() => { setView('emailForm'); }}
              >
                Use email instead
              </Button>
            </Form.Group>
          </Tab.Pane>
          <Tab.Pane eventKey="emailForm">
            <h5 className="mb-24">Verify your identity to continue</h5>
            <small className="d-block mb-64">We will send you a code to your email to verify access to your closet.</small>
            <Form.Group>
              <Form.Control
                type="text"
                className="border border-dark rounded-0 mb-32"
                id="email"
                value={email}
                placeholder="Email"
                aria-describedby="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="dark"
                type="button"
                className="mb-8 modal-button w-100 rounded-pill py-2"
                onClick={() => handleSendEmail(email.toLowerCase())}
                disabled={!email}
              >
                Send Code
              </Button>
              <Button
                variant="link"
                className="modal-button w-100 rounded-pill py-8 link-dark"
                type="button"
                onClick={() => { window.location.reload(); }}
              >
                Go Back
              </Button>
            </Form.Group>
          </Tab.Pane>
          <Tab.Pane eventKey="confirmCode">
            <h5 className="mb-24">Let&lsquo;s verify it&lsquo;s you</h5>
            <p className="mb-56">We sent a code to the number you provided. Please enter the code below.</p>
            <Form.Group>
              <Form.Control
                type="text"
                className="border-dark rounded-0 mb-24"
                id="code"
                value={code}
                placeholder="Code"
                aria-describedby="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                variant="dark"
                type="button"
                className="mb-32 modal-button w-100 rounded-pill py-8"
                onClick={() => { handleVerifyCodePhone(code); }}
                disabled={!code}
              >
                Verify Code
              </Button>
              <Button
                variant="link"
                className="modal-button w-100 rounded-pill py-8 link-dark"
                type="button"
                onClick={() => { window.location.reload(); }}
              >
                Go Back
              </Button>
            </Form.Group>
          </Tab.Pane>
          <Tab.Pane eventKey="emailSent">
            <h5 className="mb-24">Let&lsquo;s verify it&lsquo;s you</h5>
            <p className="mb-56">We sent a code to the email you provided. Please enter the code below.</p>
            <Form.Group>
              <Form.Control
                type="text"
                className="border-dark rounded-0 mb-24"
                id="code"
                value={code}
                placeholder="Code"
                aria-describedby="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <Button
                variant="dark"
                type="button"
                className="modal-button w-100 rounded-pill py-8 mb-32"
                onClick={() => { handleVerifyCodeEmail(code); }}
                disabled={!code}
              >
                Verify Code
              </Button>
              <Button
                variant="link"
                className="modal-button w-100 rounded-pill py-8 link-dark"
                type="button"
                onClick={() => { window.location.reload(); }}
              >
                Go Back
              </Button>
            </Form.Group>
          </Tab.Pane>
          <Tab.Pane eventKey="emailAndPassword">
            <h5 className="mb-24">Login with Email</h5>
            <p className="mb-56">Login with your Email and Password</p>
            <p className="text-danger">{errorMessage}</p>
            <Form.Group>
              <Form.Control
                type="email"
                className="border-dark rounded-0 mb-8"
                id="userEmail"
                value={loginEmail}
                placeholder="Email"
                aria-describedby="email"
                onChange={(e) => setloginEmail(e.target.value)}
              />
              <Form.Control
                type="password"
                className="border-dark rounded-0 mb-24"
                id="password"
                value={password}
                placeholder="Password"
                aria-describedby="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="dark"
                type="button"
                className="modal-button w-100 rounded-pill py-8 mb-8"
                onClick={() => { handleUserLogin(); }}
                disabled={!password}
              >
                Login
              </Button>
              <GoogleButton onClick={() => handleSignInWithGoogle()} />
              <Button
                variant="link"
                className="modal-button w-100 rounded-pill py-8 link-dark"
                type="button"
                onClick={() => { setView('emailForm'); }}
              >
                Forgot Password?
              </Button>
            </Form.Group>
          </Tab.Pane>
        </Tab.Content>
        <a className="mt-16 link-dark" href={`/signup${search}`}>Create Account</a>
        <div className="mt-8">
          <small>
            By continuing you agree to our
            <a className="mx-1 text-dark" target="_blank" href={`${getConfig('WEB_URL')}/policies/terms-of-service`} rel="noreferrer">
              <u>terms of service</u>
            </a>
            and
            <a className="mx-1 text-dark" target="_blank" href={`${getConfig('WEB_URL')}/pages/privacy-policy`} rel="noreferrer">
              <u>privacy policy</u>
            </a>
          </small>
        </div>
      </div>
    </Tab.Container>
  );
};

export default Login;
