const ColorOverlay = ({ backgroundColor, opacity }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '8px',
      height: '100%',
      width: '100%',
      opacity,
      backgroundColor,
    }}
  />
);

export default ColorOverlay;
