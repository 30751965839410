/* eslint-disable react/no-unescaped-entities */
import { Button, Form } from 'react-bootstrap';

import GoogleIcon from '../../../../icons/GoogleIcon';
import GoogleButton from '../../../../global/GoogleButton';
import FacebookButton from '../../../../global/FacebookButton';

const showFacebookLogin = false;

const EmailForm = ({
  email,
  setEmail,
  setView,
  onSubmit,
  onGoogleSubmit,
  onFacebookSubmit,
  offerText,
}) => (
  <div className="py-2 px-3">
    {offerText && (
      <h2 className="text-white text-bold">
        Get access to this offer, log in to your Closet Cash Account.
      </h2>
    )}
    <div className="forms flex flex-col mt-4">
      <div className="forms--embed">
        <span className="container-content-xs block text-white">
          Login / Create an account with Google
        </span>
        <GoogleButton
          className="w-100 border-0 rounded-3_5 mt-2 mb-3 py-8 bg-white shadow-google-btn custom-button--google"
          onClick={() => onGoogleSubmit()}
        >
          <GoogleIcon className="font-size-24 me-8" />
          <div>Login with Google</div>
        </GoogleButton>
        {!showFacebookLogin ? null : (
          <FacebookButton
            className="w-100 border-0 rounded-3_5 mb-3 py-8 custom-button--facebook"
            onClick={() => onFacebookSubmit()}
          />
        )}
      </div>

      <div className="forms--email mt-3">
        <span className="container-content-xs block text-white">
          Login with Email
        </span>
        <Form.Group className="mt-2">
          <Form.Control
            type="email"
            value={email}
            id="email"
            className="custom-input border-0 rounded-3_5 mb-2"
            placeholder="ENTER YOUR EMAIL"
            aria-describedby="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="dark"
            type="button"
            className="custom-button w-100 rounded-3_5 py-8 text-upper"
            onClick={() => onSubmit(email)}
            disabled={!email}
          >
            Get your verification code
          </Button>
        </Form.Group>
      </div>
    </div>

    <div className="flex justify-between">
      <Button
        variant="link"
        className="custom-button--small modal-button w-100 rounded-pill py-8 px-0 text-link text-cc-newsprint text-left"
        type="button"
        onClick={() => {
          setView('createAccount');
        }}
      >
        Create an account
      </Button>

      <Button
        variant="link"
        className="custom-button--small modal-button w-100 rounded-pill py-8 px-0 text-link text-cc-newsprint text-right"
        type="button"
        onClick={() => {
          setView('emailAndPassword');
        }}
      >
        Password help?
      </Button>
    </div>
  </div>
);

export default EmailForm;
