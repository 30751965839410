/* eslint-disable no-debugger */
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import LogRocket from 'logrocket';

import GlobalLogin from '../../global/GlobalLogin';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';

import {
  EMAIL_SEARCH_PARAM,
  REGISTER_FROM_PARAM,
  REGISTER_OPTIN_PARAM,
  REGISTER_SIGNUP_PARAM,
  TYPE_SEARCH_PARAM,
} from '../../config/routes';
import { getDecryptedData } from '../../utils/crypto-utils';
import finishProcess from '../../utils/login';
import { getUserName } from '../../utils/utils';
import { getSearchParam } from '../../utils/routes';
import { getGenericError } from '../../utils/errors';
import {
  getRedirectPath,
  getURIParam,
  getViewType,
  getWildfireDeviceId,
  WF_DEVICE_ID_SEARCH_PARAM,
} from './utils';

import './Login.scss';

// TODO - Implement URI whitelist from env var
/* const uriWhitelist = [
  'chrome-extension://extensionidhere/auth.html',
  'https://closet.fordays.com', // Only for testing purposes
]; */

const Login = () => {
  const { search } = useLocation();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, userData } = useFirebaseAuth();
  const setAlert = useAlert();
  const posthog = usePostHog();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState('');

  const query = new URLSearchParams(search);
  const uriParam = getURIParam(query);
  const wfedidParam = getWildfireDeviceId(query);
  const typeParam = getSearchParam(query, TYPE_SEARCH_PARAM);
  const emailParam = getSearchParam(query, EMAIL_SEARCH_PARAM);

  const redirect = useCallback(async () => {
    if (!isSignedIn || !userData || isLoadingFirebaseAuth || isLoading) {
      return;
    }

    setIsLoading(true);

    const {
      affiliate_cid: affiliateCID,
      uid: userUid,
      email,
      error,
    } = userData;

    if (error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });

      setIsLoading(false);
      return;
    }

    const uuid = affiliateCID.replaceAll('-', '');

    // Posthog start
    posthog.identify(
      uuid,
      {
        email,
        [WF_DEVICE_ID_SEARCH_PARAM]: wfedidParam,
      },
    );

    if (wfedidParam) {
      posthog.alias(wfedidParam, uuid);
    }
    // Posthog end

    // Logrocket start
    LogRocket.identify(String(uuid), {
      email,
      name: getUserName(userData),
    });
    // Logrocket end

    const data = await getDecryptedData(query, userUid);
    await finishProcess({
      userRegistered: !!data[REGISTER_SIGNUP_PARAM],
      subscribeEmail: email,
      optIn: !!data[REGISTER_OPTIN_PARAM],
      search,
      wfExtensionFlow: uriParam && wfedidParam,
    });

    window.location.href = getRedirectPath(query, uuid, history, view, data[REGISTER_FROM_PARAM]);
  }, [isSignedIn, userData, search, isLoadingFirebaseAuth, isLoading]);

  const handleOnViewChange = useCallback((currentView) => {
    setView(currentView);
  }, []);

  useEffect(() => {
    if (isSignedIn && userData && !isLoadingFirebaseAuth) {
      redirect();
    }
  }, [isSignedIn, userData, isLoadingFirebaseAuth, isLoading]);

  return (
    <div className="Login">
      <GlobalLogin
        initialView={getViewType(typeParam)}
        initialEmail={emailParam}
        onViewChange={handleOnViewChange}
        isParentLoading={isLoading || isLoadingFirebaseAuth}
      />
    </div>
  );
};

export default Login;
