import { useCallback, useMemo, useState } from 'react';

import InfoSection from '../../../../global/InfoSection/InfoSection';
import crud from '../../../../../../api/crud';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import DotsLoader from '../../../../global/DotsLoader';
import InputPin from '../../../../global/InputPin';
import { getNextButtonStyles, getOptionButtonStyles } from '../../../../utils/tbbRegistration';

import './Pin.scss';

const Pin = ({
  storeConfig,
  setPinValues,
  pinValues,
  registerCode,
  onSubmit,
  onCancel,
}) => {
  const [isValidPin, setIsValidPin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState(5);

  const {
    registerTbbFlow,
  } = useMemo(() => storeConfig, [storeConfig]);

  const handlePinCode = useCallback(async () => {
    setLoading(true);

    const response = await crud.post({
      path: '/redemptions/validatePinCode',
      body: {
        data: {
          id: registerCode,
          pin: pinValues.join(''),
        },
      },
    });

    if (response.invalid) {
      setIsValidPin(false);
      setAttempts(response.attempts);
    } else {
      onSubmit();
    }

    setLoading(false);
  }, [pinValues, registerCode, onSubmit]);

  const onHandlePinChange = useCallback((pin) => {
    setIsValidPin(true);
    setPinValues(pin);
  }, [setPinValues]);

  return (
    <div className="RegisterPin">
      <InfoSection
        titleText="Enter your bag’s pin"
        descriptionText={registerTbbFlow?.enterPinStep?.descriptionText}
      />
      <InputPin
        className="RegisterPin__pin"
        isValidPin={isValidPin}
        pinValues={pinValues}
        setPinValues={onHandlePinChange}
        errorMessage={
          `Invalid Pin. You have ${attempts} ${registerTbbFlow?.enterPinStep?.wrongPinText || 'more attempts.'}
          ${attempts === 0 ? ' Please contact support to get help.' : ''}`
        }
      />
      <Button
        type={BUTTON_TYPE.QUATERNARY}
        size={BUTTON_SIZE.LARGE}
        onClick={attempts === 0 ? onCancel : handlePinCode}
        style={getNextButtonStyles(storeConfig)}
        disabled={attempts !== 0 && (pinValues.join('').length < 6 || loading)}
      >
        {loading ? (
          <DotsLoader />
        ) : (
          (attempts === 0 && 'CONTACT SUPPORT') ||
          registerTbbFlow?.landingPageStep?.button?.text ||
          'CONTINUE'
        )}
      </Button>
      {attempts !== 0 && (
        <div className="RegisterPin__option">
          <p className="RegisterPin__option--text">
            PIN code not working?
          </p>
          <Button
            type={BUTTON_TYPE.LINK_QUINARY}
            onClick={onCancel}
            style={getOptionButtonStyles(storeConfig)}
          >
            CONTACT US FOR HELP
          </Button>
        </div>
      )}
    </div>
  );
};

export default Pin;
