import ColorOverlay from '../ColorOverlay/ColorOverlay';

const Pill = ({
  onClick,
  ImageCap,
  children,
  Footer,
  value,
  disabled,
  footerClass,
  className,
}) => {
  const displayColorOverlay = () => {
    if (disabled) {
      return <ColorOverlay backgroundColor="white" opacity=".5" />;
    }
    return null;
  };
  return (
    <div
      role="button"
      className={`shadow bg-white ${className}`}
      tabIndex={0}
      onKeyDown={() => onClick(value)}
      onClick={() => onClick(value)}
      style={{ borderRadius: '25px' }}
    >
      {displayColorOverlay()}
      <div className="d-flex border-dark border-bottom pt-4 pb-3 flex-column justify-content-between" style={{ minHeight: '90px' }}>
        {ImageCap}
        {children}
      </div>
      <div className={`py-3 ${footerClass}`} style={{ borderEndEndRadius: '25px', borderEndStartRadius: '25px' }}>
        {Footer}
      </div>
    </div>
  );
};

export default Pill;
