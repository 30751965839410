/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import Register from '../../global/Register';
import {
  signInWithGoogle,
  createNewUser,
} from '../../../utils/auth';
import ContentWrapper from '../../global/ContentWrapper';
import { useAlert } from '../../providers/AlertProvider';
import crud from '../../../api/crud';

const Signup = () => {
  const history = useHistory();
  const setAlert = useAlert();

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      history.push('/wallet');
      window.parent.postMessage('close', '*');
    } catch (error) {
      setAlert({
        type: 'notification',
        message: `Error: ${error.message}`,
        error,
      });
    }
  };

  const handleRegister = async ({
    email, password,
  }) => {
    try {
      const newUser = await createNewUser(email, password);
      // await crud.post({
      //   path: '/users',
      //   body: {
      //     data: {
      //       email,
      //       uid: newUser.user.uid,
      //       meta: {
      //         migrated: false,
      //       },
      //     },
      //   },
      // });
      await crud.post({
        path: '/email',
        body: {
          email,
          messageId: '39',
          messageData: {},
        },
      });
      history.push('/wallet');
      window.parent.postMessage('close', '*');
    } catch (error) {
      setAlert({
        type: 'notification',
        message: `Error: ${error.message}`,
        error,
      });
    }
  };

  return (
    <ContentWrapper>
      <Register
        onSignInWithGoogle={handleSignInWithGoogle}
        onRegister={(user) => handleRegister(user)}
        onBackClick={() => history.push('/')}
      />
    </ContentWrapper>
  );
};

export default Signup;
