import { useMemo } from 'react';
import CardSection from '../../Components/CardSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import TbbIcon from '../../../../images/TBB.svg';
import Box from '../../../../images/box.svg';
import User from '../../../../images/user-circle.svg';
import { formatMMDDYY } from '../../../../utils/dates';
import { shippingMethodProperties } from '../../../../utils/tbbRegistration';
import { HELP_URL } from '../../../../config/externalURL';
import { shippingProviderStatus } from '../../../../utils/easyPost';

import './ShippingInfo.scss';

const ShippingInfo = ({
  shippingMethod,
  code,
  expirationLabelDate,
  qrCodeImgUrl,
  printLabelUrl,
  shippingState = shippingProviderStatus.unknown,
}) => {
  const { icon, description, ctaLabel, ctaLabel2, findLocation } =
    useMemo(() => shippingMethodProperties[shippingMethod] ?? {}, [shippingMethod]);

  const isShipped = useMemo(() => (
    shippingState === shippingProviderStatus.in_transit ||
      shippingState === shippingProviderStatus.out_for_delivery
  ), [shippingState]);

  const dateTitle = useMemo(() => {
    if (shippingState === shippingProviderStatus.delivered) {
      return 'Delivered on';
    }
    if (isShipped) {
      return 'Shipped on';
    }
    return 'Ship by';
  }, [shippingState]);

  const shippingStateName = useMemo(
    () => {
      if (shippingState === shippingProviderStatus.delivered) {
        return 'Delivered';
      }
      if (isShipped) {
        return 'Shipped';
      }
      return 'Ship by';
    },
    [shippingState],
  );

  return (

    <div className="ShippingInfo">
      <div className="ShippingInfo__container">
        <div className="ShippingInfo__container--content">

          <div className="ShippingInfo__container--content--cards">
            <div className="ShippingInfo__container--content--cards--shippingInfo">
              {(shippingMethod === '7317916b-cde6-4da0-9b36-cad45c43c3f1' &&
               (!isShipped && shippingState !== shippingProviderStatus?.delivered)) && (
               <CardSection>
                 <div className="ShippingInfo__container--content--cards--qrCard">
                   <img src={qrCodeImgUrl} alt="qr-code" className="ShippingInfo__container--content--cards--card--qr" />
                 </div>
               </CardSection>
              )}
              <CardSection classNameContainer="ShippingInfo__container--content--cardContainer">
                <div className="ShippingInfo__container--content--cards--card">
                  <div className="ShippingInfo__container--content--cards--card--icon">
                    <img src={icon} alt="shipping-icon" />
                  </div>
                  <div className="ShippingInfo__container--content--cards--card--description">
                    { (!isShipped && shippingState !== shippingProviderStatus?.delivered) && (
                    <div className="ShippingInfo__container--content--cards--card--description--title">
                      How to ship
                    </div>
                    )}
                    <div className="ShippingInfo__container--content--cards--card--description--text">
                      { (isShipped || shippingState === shippingProviderStatus?.delivered) ? `This bag was already ${shippingStateName}.` : description}
                    </div>
                  </div>
                </div>
              </CardSection>
            </div>
            <div className="ShippingInfo__container--content--cards--tbbinfo">
              <CardSection classNameContainer="ShippingInfo__container--content--cardContainer">
                <div className="ShippingInfo__container--content--cards--card">
                  <div className="ShippingInfo__container--content--cards--card--icon">
                    <img src={TbbIcon} alt="shipping-icon" />
                  </div>
                  <div className="ShippingInfo__container--content--cards--card--description">
                    <div className="ShippingInfo__container--content--cards--card--description--title">
                      Take Back Bag
                    </div>
                    <div className="ShippingInfo__container--content--cards--card--description--text">
                      {`ID: *******${code.slice(-6)}`}
                    </div>
                  </div>
                </div>
              </CardSection>
              <CardSection classNameContainer="ShippingInfo__container--content--cardContainer">
                <div className="ShippingInfo__container--content--cards--card">
                  <div className="ShippingInfo__container--content--cards--card--icon">
                    <img src={Box} alt="shipping-icon" />
                  </div>
                  <div className="ShippingInfo__container--content--cards--card--description">
                    <div className="ShippingInfo__container--content--cards--card--description--title">
                      { dateTitle}
                    </div>
                    <div className="ShippingInfo__container--content--cards--card--description--text">
                      {formatMMDDYY(expirationLabelDate)}
                    </div>
                  </div>
                </div>
              </CardSection>
            </div>
          </div>
          {
            (!isShipped && shippingState !== shippingProviderStatus?.delivered) && (
              <div className="ShippingInfo__container--ctacontainer">
                <Button
                  type={BUTTON_TYPE.QUATERNARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={() => window.open(printLabelUrl, '_blank')}
                >
                  {ctaLabel}
                </Button>
                {
              ctaLabel2 && (
                <Button
                  type={BUTTON_TYPE.QUINARY}
                  size={BUTTON_SIZE.LARGE}
                  onClick={() => window.open(findLocation, '_blank')}
                >
                  {ctaLabel2}
                </Button>
              )
            }
              </div>
            )
          }

        </div>
        <div className="ShippingInfo__container--footer">
          <div className="ShippingInfo__container--footer--title">
            Need help?
          </div>
          <a href={HELP_URL} className="ShippingInfo__container--footer--content">
            <img src={User} alt="user" />
            <div className="ShippingInfo__container--footer--content--description">
              <div className="ShippingInfo__container--footer--content--description--title">
                Help Center
              </div>
              <div className="ShippingInfo__container--footer--content--description--text">
                Get answers to TBB questions and more
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;
