import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo } from 'react';
// import moment from 'moment';
import { formatMoney } from '../../../utils/formatMoney';
import Pill from '../Pill/Pill';

const GiftCardPill = ({
  giftCardData: {
    giftCardCode,
    note,
    createdAt,
  },
  onClick,
  balance,
}) => {
  const ImageCap = useMemo(() => (
    <img
      src="//cdn.shopify.com/s/files/1/0503/2601/2056/files/for-days-logo-black_140x.png?v=1614290300"
      alt="Card cap"
      className="mx-auto"
      style={{ height: '25px', width: 'auto' }}
    />
  ), []);

  const Footer = useMemo(() => (
    <>
      <div>
        <small>
          Created At:
          {' '}
          {/* {moment(createdAt).format().split('T')[0]} */}
          {createdAt}
        </small>
      </div>
      <div>
        <small>
          {note}
        </small>
      </div>
    </>
  ), [createdAt, note]);

  const formatGiftCard = useCallback((code) => {
    const letters = code.split('');
    const result = [];
    letters.forEach((letter, idx) => {
      if (idx % 4 === 0) {
        result.push(<span className="ms-2">{letter}</span>);
      } else {
        result.push(<span>{letter}</span>);
      }
    });
    return result;
  }, []);

  return (
    <Pill
      Footer={Footer}
      ImageCap={ImageCap}
      value={giftCardCode}
      disabled={false}
      onClick={onClick}
      className="border text-center"
    >
      <div className="text-warning font-weight-bold my-3">
        Balance:
        {formatMoney(balance)}
      </div>
      <h6 className="card-title">
        {giftCardCode && <span>{formatGiftCard(giftCardCode)}</span>}
        <FontAwesomeIcon
          className="ms-3 selectable"
          size="xs"
          icon={faCopy}
          onClick={() => onClick(giftCardCode)}
          onKeyPress={() => onClick(giftCardCode)}
        />
      </h6>
    </Pill>
  );
};

export default GiftCardPill;
