/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import { useFirebaseAuth } from '../../FirebaseProvider/FirebaseProvider';
import Loader from '../../Loader';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn, isLoading } = useFirebaseAuth();

  if (isLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
        <Loader message="Loading..." />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        (isSignedIn && !isLoading) ? <Component {...props} /> : <Redirect to="/" />
      )}
    />
  );
};

export default PrivateRoute;
