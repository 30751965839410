import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import LoadingBar from '../../../global/LoadingBar';

import './DashboardNewSection.scss';

const DashboardNewSection = ({
  anchor = '',
  title = '',
  subTitle = '',
  ctaLabel = '',
  onClick = () => {},
  isLoading,
  error = '',
  noData = '',
  ctaTrackerProps = {},
  children,
}) => (
  <div id={anchor} className="DashboardNewSection">
    <div className="DashboardNewSection__container">
      <h2 className="DashboardNewSection__container--title">{title}</h2>
      <p className="DashboardNewSection__container--subTitle">{subTitle}</p>
      <div className="DashboardNewSection__container--content">
        {isLoading ? (
          <LoadingBar className="DashboardNewSection__container--loader" />
        ) : (
          error || noData || children
        )}
      </div>
      <div className="DashboardNewSection__container--bottom">
        <Button
          size={BUTTON_SIZE.LARGE}
          type={BUTTON_TYPE.PRIMARY}
          onClick={onClick}
          className="DashboardNewSection__container--cta"
          trackerProps={ctaTrackerProps}
        >
          {ctaLabel}
        </Button>
      </div>
    </div>
  </div>
);

export default DashboardNewSection;
