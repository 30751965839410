import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import ContentWrapper from '../../global/ContentWrapper';

const Settings = () => {
  const { user, signOut } = useFirebaseAuth();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [alert, setAlert] = useState('');

  const resetPassword = async (event) => {
    try {
      event.preventDefault();
      if (newPassword !== passwordConfirmation) {
        return setAlert('Passwords Do Not Match. Try Again');
      }
      await user.updatePassword(newPassword);
      setNewPassword('');
      setPasswordConfirmation('');
      return setAlert('Your Password Has Been Reset');
    } catch (error) {
      setNewPassword('');
      setPasswordConfirmation('');
      return setAlert(error.message);
    }
  };

  const handleLogout = () => {
    signOut();
    sessionStorage.removeItem('logged_into_closet_cash');
    sessionStorage.removeItem('closet_cash_balance');
    history.push('/');
  };

  const alertColor = () => {
    if (alert === 'Your Password Has Been Reset') {
      return true;
    }
    return false;
  };

  return (
    <ContentWrapper>
      <div className="py-5 px-152 bg-white border border-dark text-center">
        <Form onSubmit={resetPassword}>
          <h5 className="w-100">Password Reset</h5>
          <Form.Group className="my-16">
            <Form.Control
              type="password"
              id="newPassword"
              value={newPassword}
              placeholder="New Password"
              aria-describedby="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="my-16">
            <Form.Control
              type="password"
              id="passwordConfirmation"
              value={passwordConfirmation}
              placeholder="Confirm New Password"
              aria-describedby="passwordConfirmation"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="dark"
            type="submit"
            className="d-block w-100"
          >
            Reset Password
          </Button>
          <div className={`${alertColor() ? 'text-dark' : 'text-danger'} w-100 text-center`}>{alert}</div>
        </Form>
        <hr className="my-32" />
        <Link to="/addresses">
          <Button
            variant="secondary"
            type="submit"
            className="d-block w-100 mt-16"
          >
            Addresses
          </Button>
        </Link>
        <Button
          variant="warning"
          className="mt-3 d-block w-100"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </ContentWrapper>
  );
};

export default Settings;
