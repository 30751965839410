/* eslint-disable react/jsx-props-no-spreading */

const BgContainer = ({
  bgSrc, bgClassName, bgStyle, containerClassName, containerStyle, noContainer, children, onClick,
}) => (
  <div
    className={bgClassName}
    {...(bgSrc || bgStyle) && {
      style: {
        backgroundImage: `url(${bgSrc})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...(bgStyle && { ...bgStyle }),
      },
    }}
    {...onClick && {
      onClick,
      onKeyPress: onClick,
      tabIndex: 0,
      role: 'button',
    }}
  >
    <div className={`${!noContainer ? 'container' : ''} ${containerClassName || ''}`.trim()} {...containerStyle && { ...containerStyle }}>
      {children}
    </div>
  </div>
);

export default BgContainer;
