const EarnSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill={color}
        d="M9.4 14.858v-.524c-.825-.075-1.425-.375-1.95-.75-.45-.375-.75-.825-.75-1.275 0-.45.375-.9.825-.9.45 0 .45.074.675.3.6.6 1.275.975 2.175.975.9 0 1.2-.3 1.2-.825 0-.525-.375-.675-.825-.75l-1.575-.3C7.75 10.508 7 9.834 7 8.483s.975-2.325 2.475-2.55v-.525c0-.525.375-.9.825-.9.45 0 .825.375.825.9v.525c.6.15 1.125.375 1.5.6.525.3.75.825.75 1.275 0 .45-.3.825-.75.825s-.6-.15-.9-.375a2.514 2.514 0 0 0-1.8-.75c-.825 0-1.125.225-1.125.75s.375.675.975.75l1.5.375c1.2.3 2.175.75 2.175 2.325 0 1.575-.9 2.25-2.325 2.55v.524c0 .526-.375.9-.825.9-.45 0-.825-.374-.825-.9l-.075.075Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.05 2.483h15.9M16.45 2.483H3.625v15.525H16.45V2.483Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M1 1.208h18v18H1z" />
      </clipPath>
    </defs>
  </svg>
);
export default EarnSvg;
