import './HeroSection.scss';
import UserBalance from '../UserBalance';
import EarnRewardBanner from '../EarnRewardBanner';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import EarnSvg from '../SvgComponents/Earn_svg';
import TbbSvg from '../SvgComponents/Tbb_svg';
import RewardSvg from '../SvgComponents/Reward_svg';
import WandSparklesSvg from '../SvgComponents/WandSparkles_svg';
import { ROUTE_OFFERS, ROUTE_REWARDS, ANCHOR_BALANCE, ROUTE_DEALS } from '../../config/routes';
import { getTrackerId, TRACKER_IDS } from '../../config/tracker';
import { TAKE_BACK_BAG_COLLECTION_URL } from '../../config/externalURL';

const {
  dashboard: {
    hero: {
      trashiecashRewards: trackerTrashiecashRewards,
      earnTrashiecash: trackerEarnTrashiecash,
      shopTakeBackBag: trackerShopTakeBackBag,
      instantDeals: trackerInstantDeals,
    },
  },
} = TRACKER_IDS;

const navigationOptions = [
  {
    label: 'Instant Deals',
    url: ROUTE_DEALS,
    icon: <WandSparklesSvg color="#545354" />,
    trackerId: getTrackerId(trackerInstantDeals),
  },
  {
    label: 'TrashieCash Rewards',
    url: ROUTE_REWARDS,
    icon: <RewardSvg color="#545354" />,
    trackerId: getTrackerId(trackerTrashiecashRewards),
  },
  {
    label: 'Earn more TrashieCash',
    url: ROUTE_OFFERS,
    icon: <EarnSvg color="#545354" />,
    trackerId: getTrackerId(trackerEarnTrashiecash),
  },
  {
    label: 'Shop Take Back Bag',
    url: TAKE_BACK_BAG_COLLECTION_URL,
    icon: <TbbSvg color="#545354" />,
    isExternal: true,
    trackerId: getTrackerId(trackerShopTakeBackBag),
  },
];

const HeroSection = () => (
  <div className="HeroSection">
    <div className="HeroSection__container" id={ANCHOR_BALANCE}>
      <UserBalance />
      <EarnRewardBanner />
    </div>
    <div className="HeroSection__navigation">
      <NavigationMenu navigationOptions={navigationOptions} />
    </div>
  </div>
);

export default HeroSection;
