import { Button, Form } from 'react-bootstrap';

const CodeForm = ({ code, setCode, setView, onSubmit }) => (
  <div className="px-3">
    <div className="forms--code-verification">
      <span className="block text-white pr-0_5">
        Check your email for your 6 digit code
      </span>
      <Form.Group className="mt-2">
        <Form.Control
          type="text"
          value={code}
          id="code"
          className="custom-input border-0 rounded-3_5 mb-2"
          placeholder="ENTER YOUR CODE"
          aria-describedby="code"
          onChange={(e) => setCode(e.target.value)}
        />
        <Button
          variant="dark"
          type="button"
          className="custom-button--dark modal-button w-100 rounded-3_5 py-8 text-upper"
          onClick={() => onSubmit(code)}
          disabled={!code}
        >
          Login Now!
        </Button>
        <Button
          variant="link"
          className="custom-button--small modal-button w-100 rounded-pill py-8 text-link text-white"
          type="button"
          onClick={() => {
            setView('emailForm');
          }}
        >
          Login to a different account ?
        </Button>
      </Form.Group>
    </div>
  </div>
);

export default CodeForm;
