import NavigationItem from './NavigationItem';
import './NavigationMenu.scss';

const NavigationMenu = ({ navigationOptions }) => (
  <div className="NavigationMenu">
    <div className="NavigationMenu__container">
      {navigationOptions.map((option) => (
        <NavigationItem option={option} />
      ))}
    </div>
  </div>
);

export default NavigationMenu;
