import { useHistory } from 'react-router-dom';

import { ROUTE_DASHBOARD_REWARDS } from '../../../config/routes';
import { SUPPORT_URL } from '../../../config/externalURL';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import TitleSection from '../../../global/TitleSection';
import {
  REWARDS_NO_MORE_CODES,
  REWARDS_NO_MORE_CODES_PER_USER,
} from '../../../utils/errors';

import './RewardContent.scss';

const errorMessages = {
  [REWARDS_NO_MORE_CODES]: {
    title: 'We’re sorry!',
    subtitle: 'We’ve run out of this reward',
    instruction: 'Check out other great rewards available in your dashboard',
  },
  [REWARDS_NO_MORE_CODES_PER_USER]: {
    title: 'reward limit reached',
    subtitle: 'You’ve reached the limit for this reward',
    instruction: 'Check out other great rewards available in your dashboard',
  },
};

const ModalContentError = ({ errorId }) => {
  const history = useHistory();

  return (
    <div className="body-style error-modal">
      <div className="body-top">
        <div className="error-title">
          <TitleSection
            title={errorMessages[errorId]?.title || 'OOPS!'}
            subtitle={!errorId && 'Something went wrong with your Reward processing'}
          />
        </div>
        <div className="error-text">
          <p className="title" style={errorId && { textAlign: 'center' }}>
            { errorMessages[errorId]?.subtitle || 'What should I do?'}
          </p>
          <p className="description" style={errorId && { textAlign: 'center' }}>
            {
              errorMessages[errorId]?.instruction || 'Go back to your dashboard and try again by selecting the Reward you’re interested in.'
            }
          </p>
          <p className="description">
            { !errorId && 'If the issue persists, please contact support.'}
          </p>
        </div>
        <div className="error-footer">
          <Button
            type={BUTTON_TYPE.PRIMARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => history.push(ROUTE_DASHBOARD_REWARDS)}
          >
            BACK TO MY DASHBOARD
          </Button>
          {!errorId && (
          <a className="contact-support-btn" href={SUPPORT_URL} target="_blank" rel="noreferrer">
            Contact support
          </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalContentError;
