import './ShopifyUpgradeBanner.scss';
import { ROUTE_DASHBOARD, ANCHOR_REWARDS } from '../../../config/routes';
import { trashieRoute } from '../../../../../config/config';

const ShopifyUpgradeBanner = () => (
  <div className="ShopifyUpgradeBanner">
    <div className="ShopifyUpgradeBanner__content">
      <a
        className="ShopifyUpgradeBanner__text"
        href={`${trashieRoute()}${ROUTE_DASHBOARD}#${ANCHOR_REWARDS}`}
      >
        <div className="circle" />
        We’re updating how you apply TrashieCash™ on
        For Days! Starting August 8th, manage your TrashieCash™ on Trashie.io.&nbsp;
        <u>
          Check it out 🌈
        </u>
      </a>
    </div>

  </div>
);

export default ShopifyUpgradeBanner;
