/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Button, Container, Form, Image } from 'react-bootstrap';
import BgContainer from '../../global/BgContainer/BgContainer';

const TbbContactForm = () => {
  // leaving this console log here so eslint won't scream at me
  const hello = 'hi';
  console.log(hello);
  return (
    <>
      <BgContainer
        bgSrc="images/lady-in-white.jpg"
        bgClassName="py-152"
        bgStyle={{ backgroundSize: 'contain', backgroundPosition: 'center right' }}
      >
        <div className="display-1 text-black" style={{ fontSize: '12rem' }}>FOR DAYS, FOR MORE</div>
      </BgContainer>
      <div className="position-relative py-40">
        <Container>
          <div className="col-md-9 col-12">
            <p>Become a wholesale partner today!</p>
            <p>
              Please fill out the form below and someone will get
              back to you within 48 hours to verify your account.
              Thanks for your interest in spreading better days!
            </p>
            <Form>
              <Form.Group>
                <h1 className="mb-5">Contact Information</h1>
                <label htmlFor="name">Name*:</label>
                <Form.Control type="text" id="name" required />
              </Form.Group>
              <Form.Group>
                <label htmlFor="company">Company:</label>
                <Form.Control type="text" id="company" />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Email*:</label>
                <Form.Control type="email" id="email" required />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Phone Number:</label>
                <Form.Control type="text" id="phone-number" />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Industry:</label>
                <Form.Control type="text" id="industry" />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Do you have a physical or online store?:</label>
                <Form.Control type="text" id="name" />
              </Form.Group>
              <div className="form-group mt-5">
                <h1 className="mb-5">Order Information</h1>
                <label htmlFor="name">How many bags?:</label>
                <Form.Control type="text" id="bags" />
              </div>
              <Form.Group>
                <label htmlFor="name">Delivery Date?:</label>
                <Form.Control type="text" id="delivery" />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Are you looking for custom bags?:</label>
                <Form.Control type="text" id="name" />
              </Form.Group>
              <Form.Group>
                <label htmlFor="name">Anything else we should know?:</label>
                <Form.Control as="textarea" type="text" id="name" />
              </Form.Group>
              <Button variant="dark" className="mt-4 mb-3" type="submit">Submit</Button>
            </Form>
          </div>
        </Container>
        <div className="position-absolute w-100 d-flex justify-content-end" style={{ top: '25%', zIndex: '-1' }}>
          <Image
            src="images/recycle-icon-yellow.png"
            alt="recycle"
          />
        </div>
      </div>
    </>
  );
};

export default TbbContactForm;
