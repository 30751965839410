import 'firebase/auth';
import firebase from 'firebase/app';
import { Tab } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import crud from '../../../api/crud';
import { fbKey } from '../../../utils/firebase';
import {
  signIn,
  signInWithFacebook,
  signInWithGoogle,
  createNewUser,
} from '../../../utils/auth';
import { useAlert } from '../../providers/AlertProvider';
import Loader from '../../global/Loader';

import EmailForm from './components/EmailForm';
import CreateForm from './components/CreateForm';
import SignInHeader from './components/SignInHeader';
import CodeForm from './components/CodeForm/CodeForm';
import DisclaimerNote from './components/DisclaimerNote';
import EmailAndPasswordForm from './components/EmailAndPasswordForm';

const SignIn = () => {
  const loginredirect = '/embed/loginsuccess?embeeded=true';
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const initialEmail = query.get('email') || '';
  const isOfferEmbed = query.get('offerLogin') === 'true';

  const setAlert = useAlert();
  const [view, setView] = useState('emailForm');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const handleSendEmail = async (userEmail) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Sending verification code to your email');
      await crud.post({
        path: '/email',
        body: {
          email: userEmail,
          messageId: 'send verification',
          messageData: {},
        },
      });

      setView('verifyCodeEmail');
      setIsLoading(false);
      setIsEmailSent(true);
      setEmail(userEmail);
    } catch (error) {
      setIsEmailSent(false);
      console.error(error);
      setAlert({
        message: error.message,
        type: 'notification',
      });
      setIsLoading(false);
    }
  };

  const handleVerifyCodeEmail = async (userCode) => {
    try {
      setIsLoading(true);
      setLoadingMessage('Verifying Code...');
      const token = await crud.post({
        path: '/auth',
        body: {
          email,
          code: userCode,
        },
      });

      await firebase.auth().signInWithCustomToken(token);
      if (loginredirect) {
        window.location.href = loginredirect;
      }
    } catch (error) {
      setIsLoading(false);
      setAlert({
        type: 'alert',
        message: error.message || 'Unable to validate email',
      });
    }
  };

  const handleUserLogin = async () => {
    try {
      await signIn(loginEmail, loginPassword);
      window.location.href = loginredirect;
    } catch (error) {
      console.error(error);
      let alertMessage = error.code;
      if (
        error.code === 'auth/user-not-found' ||
        error.code === 'auth/wrong-password'
      ) {
        alertMessage = 'Invalid email or password.';
      }

      setAlert({
        type: 'notification',
        message: `Error: ${alertMessage}`,
        error,
      });
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (!registerEmail) {
      setAlert({
        type: 'notification',
        message: 'Enter a valid Email',
      });
      return;
    }

    if (registerPassword !== registerConfirmPassword) {
      setAlert({
        type: 'notification',
        message: 'Passwords do not match',
      });
      return;
    }

    try {
      await createNewUser(registerEmail, registerPassword);
      await crud.post({
        path: '/email',
        body: {
          registerEmail,
          messageId: 39,
          messageData: {},
        },
      });

      if (loginredirect !== '/embed/loginsuccess?embeeded=true') {
        history.push('/wallet');
      }

      window.location.href = loginredirect;
    } catch (error) {
      setAlert({
        type: 'notification',
        message: `Error: ${error.message}`,
        error,
      });
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      if (loginredirect !== '/embed/loginsuccess?embeeded=true') {
        history.push('/wallet');
      }
      window.location.href = loginredirect;
    } catch (error) {
      setAlert({
        type: 'notification',
        message: 'Unable to sign in with google',
        error,
      });
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      await signInWithFacebook();
      if (loginredirect !== '/embed/loginsuccess?embeeded=true') {
        history.push('/wallet');
      }
      window.location.href = loginredirect;
    } catch (error) {
      setAlert({
        type: 'notification',
        message: 'Unable to sign in with facebook',
        error,
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      if (initialEmail) {
        setEmail(initialEmail);
        handleSendEmail(initialEmail);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (isEmailSent) {
      setView('verifyCodeEmail');
    }
  }, [isEmailSent]);

  useEffect(() => {
    // send embed iframe width/height on each view change
    const loginEl = document.querySelector('.EmbedLogin');
    if (loginEl) {
      window.parent.postMessage(
        {
          type: 'login_iframe_size',
          width: loginEl.clientWidth,
          height: loginEl.clientHeight,
        },
        '*',
      );
    }
  }, [view, isLoading]);

  window.addEventListener('storage', () => {
    const dbRequest = window.indexedDB.open('firebaseLocalStorageDb', 1);

    dbRequest.onsuccess = async () => {
      const db = dbRequest.result;
      const stores = ['firebaseLocalStorage'];

      const tx = db.transaction(stores);
      const req = tx.objectStore('firebaseLocalStorage').getAll();
      req.onsuccess = () => {
        const localUserData = req.result.find(
          ({ fbase_key: fbBaseKey }) => fbBaseKey === fbKey,
        );
        if (localUserData) {
          window.location.href = loginredirect;
        }
      };
    };
  });

  return (
    <div className="EmbedLogin flex flex-col justify-between w-100 bg-cc-green">
      {isLoading ? (
        <Loader
          message={loadingMessage}
          className="text-center text-white py-40 px-3 bg-cc-green"
          whiteLoader
        />
      ) : (
        <>
          <SignInHeader view={view} />
          <Tab.Container activeKey={view}>
            <div
              className={`container-content-sm w-100 mx-auto pt-${isOfferEmbed === false ? '4' : '2'}`}
            >
              <Tab.Content>
                <Tab.Pane eventKey="emailForm">
                  <EmailForm
                    email={email}
                    setEmail={setEmail}
                    setView={setView}
                    onSubmit={handleSendEmail}
                    onGoogleSubmit={handleSignInWithGoogle}
                    onFacebookSubmit={handleSignInWithFacebook}
                    offerText={isOfferEmbed}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="verifyCodeEmail" className="pb-2">
                  <CodeForm
                    code={code}
                    setCode={setCode}
                    setView={setView}
                    onSubmit={handleVerifyCodeEmail}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="emailAndPassword" className="pb-2">
                  <EmailAndPasswordForm
                    email={loginEmail}
                    password={loginPassword}
                    setEmail={setLoginEmail}
                    setPassword={setLoginPassword}
                    onSubmit={handleUserLogin}
                    onBackClick={() => setView('emailForm')}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="createAccount" className="pb-2">
                  <CreateForm
                    email={registerEmail}
                    password={registerPassword}
                    confirmPassword={registerConfirmPassword}
                    setEmail={setRegisterEmail}
                    setPassword={setRegisterPassword}
                    setConfirmPassword={setRegisterConfirmPassword}
                    onSubmit={handleRegister}
                    onBackClick={() => setView('emailForm')}
                  />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
          <DisclaimerNote />
        </>
      )}
    </div>
  );
};

export default SignIn;
