import { isTrashieApp } from '../../../config/config';
import { FORDAYS_DOCUMENT_TITLE, FORDAYS_FAVICON, TRASHIE_DOCUMENT_TITLE } from '../config/config';

const setDocumentTitle = (page) => {
  let title = isTrashieApp() ? TRASHIE_DOCUMENT_TITLE : FORDAYS_DOCUMENT_TITLE;

  if (page) {
    title = `${title} - ${page}`;
  }

  document.title = title;
};

const setDocumentFavicon = () => {
  if (!isTrashieApp()) {
    const link = document.querySelector('link[rel="icon"]');

    if (link) {
      link.setAttribute('href', FORDAYS_FAVICON);
    }
  }
};

const getUserName = (user, emailDefault = true) => {
  const { firstName, lastName, displayName, email } = user;

  let userName = '';

  if (firstName && lastName) {
    userName = `${firstName} ${lastName}`;
  } else if (displayName) {
    userName = displayName;
  } else if (emailDefault && email) {
    [userName] = email.split('@');
  }

  return userName;
};

const isNullOrEmpty = (value) => value === null || value?.trim().length === 0;

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

function capitalizeWords(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export {
  setDocumentTitle,
  setDocumentFavicon,
  getUserName,
  isNullOrEmpty,
  isJson,
  capitalizeWords,
};
