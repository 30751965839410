import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContentWrapper from '../../global/ContentWrapper';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import Loader from '../../global/Loader';
import crud from '../../../api/crud';
import useBalance from '../../../hooks/useBalance';
import { formatMoney } from '../../../utils/formatMoney';

const LoginSuccess = () => {
  const [transactions, setTransactions] = useState(null);
  const { user } = useFirebaseAuth();
  const cashBalance = useBalance(transactions);
  const query = new URLSearchParams(useLocation().search);
  const isEmbed = query.get('embeeded') === 'true';

  useEffect(async () => {
    if (!user || !user.uid) {
      return;
    }

    const uData = await crud.get({
      path: '/users',
      options: {
        where: {
          uid: user.uid,
        },
      },
    });

    if (!uData.length) {
      await crud.post({
        path: '/users',
        body: {
          data: {
            email: user.email,
            uid: user.uid,
            meta: {
              migrated: false,
            },
          },
        },
      });
    }

    const fetchedTransactions = await crud.get({
      path: '/transactions',
      options: {
        where: {
          userId: user.uid,
        },
        select: {
          amount: true,
          typeId: true,
        },
      },
    });
    const transactionsToCashArray = fetchedTransactions.map((transaction) => {
      let newTransaction;
      if (transaction.typeId === 1) {
        newTransaction = { amount: transaction.amount, type: 'withdraw' };
      } else if (transaction.typeId === 2) {
        newTransaction = { amount: transaction.amount, type: 'deposit' };
      }
      return newTransaction;
    });
    await setTransactions(transactionsToCashArray);

    window.parent.postMessage(`Bearer:${user.Aa}`, '*');
    window.parent.postMessage(
      // eslint-disable-next-line no-useless-escape
      `identify:${user.uid}:${uData[0].affiliate_cid.replace(/\-/g, '')}`,
      '*',
    );
  }, [user]);

  useEffect(async () => {
    if (transactions === null) {
      return;
    }
    const cashBalanceFormatted = await formatMoney(cashBalance);
    window.parent.postMessage(`close ${cashBalanceFormatted}`, '*');
  }, [transactions]);

  useEffect(() => {
    // send embed iframe width/height on each view change
    const contentEl = document.querySelector('.embed-loader');
    if (contentEl) {
      window.parent.postMessage(
        {
          type: 'login_iframe_size',
          width: contentEl.clientWidth,
          height: contentEl.clientHeight,
        },
        '*',
      );
    }
  }, []);

  return (
    <ContentWrapper embeeded={isEmbed}>
      <div className="text-center">
        <Loader
          message="...logging in"
          className={
            isEmbed ? 'embed-loader h-100 text-center text-white py-40 px-3 bg-cc-green' : ''
          }
          whiteLoader={isEmbed}
        />
      </div>
    </ContentWrapper>
  );
};

export default LoginSuccess;
