import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import crud from '../../../api/crud';
import { useAlert } from '../../providers/AlertProvider';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import GiftCardPill from '../../global/GiftCardPill';
import ContentWrapper from '../../global/ContentWrapper';
import Loader from '../../global/Loader';

const GiftCards = () => {
  const { user } = useFirebaseAuth();
  const [giftCards, setGiftCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setAlert = useAlert();

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        setIsLoading(true);
        const userGiftCardData = await crud.get({
          path: '/giftCards',
          options: {
            where: {
              userId: user.uid,
            },
          },
        });

        if (mounted || false) {
          setGiftCards(userGiftCardData.filter((
            { initialBalance, closetCashApplied },
          ) => (initialBalance - closetCashApplied) >= 0));
        }
      } catch (error) {
        setAlert({
          type: 'notification',
          message: error.message,
          error,
        });
      } finally {
        setIsLoading(false);
      }
    };
    init();

    return () => { mounted = false; };
  }, []);

  if (isLoading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <Loader className="d-flex flex-column align-items-center" message="Loading Gift Cards" />
      </div>
    );
  }

  const handlePillClick = (giftCardCode) => {
    navigator.clipboard.writeText(giftCardCode);
    setAlert({
      type: 'notification',
      message: 'Gift Card Copied to Clipboard!',
      className: 'bg-success text-dark',
    });
  };

  return (
    <ContentWrapper>
      {giftCards?.length
        ? <p className="text-center mb-24">Click a Gift Card to copy to clipboard.</p>
        : <div className="py-16" />}
      <Row className="g-24">
        {giftCards.map((giftCardData) => (
          <Col xs={12} md={6} key={giftCardData.giftCardCode}>
            <GiftCardPill
              giftCardData={giftCardData}
              balance={giftCardData.closetCashApplied}
              key={`pill-${giftCardData.giftCardCode}`}
              onClick={handlePillClick}
            />
          </Col>
        ))}
      </Row>
      {!giftCards?.length && (
        <div className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3>No Gift Cards Found</h3>
          <a
            className="btn btn-dark rounded-pill border-dark mt-4 "
            href="https://fordays.com"
            target="_blank"
            rel="noreferrer"
          >
            Shop fordays.com
          </a>
          <a
            href="https://www.trashie.io/spend-offers"
            target="_blank"
            rel="noreferrer"
            className="btn btn-dark rounded-pill border-dark mt-4 "
          >
            Shop Rewards on Trashie.io
          </a>
        </div>
      )}
    </ContentWrapper>
  );
};

export default GiftCards;
