import { useEffect, useCallback, useState, useRef } from 'react';
import style from './TakeBackBags.module.scss';
import { setDocumentTitle } from '../../utils/utils';
import crud from '../../../../api/crud';
import ItemList from '../../global/ItemList';
import ListHeaders from '../../global/ListHeaders';
import LoadingBar from '../../global/LoadingBar';
import ListPaginator from '../../global/ListPaginator';
import { TBB_LIST_BY_USER } from '../../config/service';
import { useAlert } from '../../../providers/AlertProvider';
import TakeBackBagsNoData from '../../global/TakeBackBagsNoData';
import { ANCHOR_MY_TBB } from '../../config/routes';

const {
  main,
  container,
  listContainer,
} = style;
const TakeBackBags = () => {
  const timeoutRef = useRef(null);
  const PAGE_SIZE = 10;
  const [tbbData, setTbbData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const setAlert = useAlert();

  const userTbb = useCallback(
    async () => {
      setLoading(true);
      try {
        const { count, result } = await crud.get({ path: `${TBB_LIST_BY_USER}?page=${currentPage}&pageSize=${PAGE_SIZE}` });
        const data = result.map((redemption) => {
          let parsedRedemption;
          if (redemption.labeldata) {
            parsedRedemption = {
              ...redemption,
              labeldata: JSON.parse(redemption.labeldata),
            };
          } else {
            parsedRedemption = redemption;
          }
          return parsedRedemption;
        });
        setTbbData(data);
        setTotalRecords(count);
      } catch (error) {
        setAlert({
          type: 'alert',
          message: error.error.message,
          error: error.error.message });
      }
      setLoading(false);
      const mainElement = document.getElementById(ANCHOR_MY_TBB);

      if (mainElement) {
        timeoutRef.current = setTimeout(() => {
          mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
      }
    },
    [currentPage],
  );

  useEffect(() => {
    setDocumentTitle('Take Back Bags');
  }, []);

  useEffect(() => {
    userTbb();
  }, [currentPage]);

  return (
    <div id={ANCHOR_MY_TBB} className={main}>
      <div className={container}>
        <ListHeaders
          title="MY Take back bags"
          sortedText="Most recently registered"
        />
        {loading ? <LoadingBar /> : (
          <div className={listContainer}>
            {tbbData.length > 0 ? (
              <div>
                <ItemList dataList={tbbData} setTbbData={setTbbData} />
                <ListPaginator
                  pageSize={PAGE_SIZE}
                  totalItems={totalRecords}
                  currentPage={currentPage}
                  onPageSelection={(selectedPage) => {
                    setCurrentPage(selectedPage);
                  }}
                />
              </div>
            ) : (
              <TakeBackBagsNoData />
            )}
          </div>
        )}

      </div>
    </div>

  );
};

export default TakeBackBags;
