import { useMemo } from 'react';

const useBalance = (swaps) => {
  const walletBalance = useMemo(() => {
    let walletAmount = 0;
    if (swaps) {
      swaps.forEach((_swap) => {
        walletAmount += _swap.amount * (_swap.type === 'withdraw' ? -1 : 1);
      });
    }
    return walletAmount;
  }, [swaps]);

  return walletBalance;
};

export default useBalance;
