import { Button, Form } from 'react-bootstrap';

const CreateForm = ({
  email,
  password,
  confirmPassword,
  setEmail,
  setPassword,
  setConfirmPassword,
  onSubmit,
  onBackClick,
}) => (
  <div className="px-3">
    <h5 className="mb-24 text-white">Let&apos;s create your account</h5>
    <Form.Group>
      <Form.Control
        type="text"
        className="text-center rounded-3_5 mb-2"
        id="createEmail"
        value={email}
        placeholder="EMAIL"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Form.Control
        type="password"
        className="text-center rounded-3_5 mb-2"
        id="createPassword"
        value={password}
        placeholder="PASSWORD"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Form.Control
        type="password"
        className="text-center rounded-3_5"
        id="confirmPassword"
        value={confirmPassword}
        placeholder="RE-ENTER PASSWORD"
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button
        variant="dark"
        type="submit"
        className="mt-16 rounded-pill py-8 w-100"
        onClick={(e) => onSubmit(e)}
      >
        REGISTER
      </Button>
    </Form.Group>
    <Button
      variant="link"
      onClick={onBackClick}
      type="button"
      className="custom-button--small w-100 mt-16 text-cc-newsprint text-center text-decoration-none"
    >
      Back to Sign In
    </Button>
  </div>
);

export default CreateForm;
