import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import ContentWrapper from '../../../../global/ContentWrapper';
import LearnMore from '../../../../global/LearnMore';
import { useAlert } from '../../../../providers/AlertProvider';

const CouponDetailView = ({ onClose, coupon }) => {
  const [isCouponCopied, setIsCouponCopied] = useState(false);
  const setAlert = useAlert();

  const couponUse = () => {
    if (coupon.status === 'used') {
      return 'USED';
    }
    return `Created at: ${coupon.createdAt}`;
  };

  const handleCouponClick = () => {
    navigator.clipboard.writeText(coupon.code);
    setIsCouponCopied(true);
    setAlert({
      type: 'notification',
      message: 'Gift Card Copied to Clipboard!',
      className: 'bg-success text-white',
    });
  };

  return (
    <ContentWrapper>
      <div className="p-48 bg-white text-center shadow rounded-3">
        {isCouponCopied && (
          <div>Coupon Copied to Clipboard!</div>
        )}
        <div
          className="mb-16 text-dark text-center"
          onClick={() => handleCouponClick(coupon.code)}
          onKeyPress={() => handleCouponClick(coupon.code)}
          role="button"
          tabIndex={0}
        >
          <h2>
            {coupon.code}
            <FontAwesomeIcon
              className="ms-16 mt-16 me-8 selectable"
              size="xs"
              icon={faCopy}
              onClick={() => handleCouponClick(coupon.code)}
              onKeyPress={() => handleCouponClick(coupon.code)}
            />
          </h2>
        </div>
        <h6 className="text-center my-24">{coupon.summary}</h6>
        <p className="my-16 text-center">
          {couponUse()}
        </p>
        <Button variant="dark" className="d-block w-100 mb-8" type="button" href={`${coupon.storeUrl}/discount/${coupon.code}`} target="_blank" rel="noreferrer">
          Shop Store with Code
        </Button>
        <Button variant="outline-dark" className="d-block w-100 mb-8" type="button" onClick={onClose}>
          Close
        </Button>
        <LearnMore />
      </div>
    </ContentWrapper>
  );
};

export default CouponDetailView;
