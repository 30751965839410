import { useHistory } from 'react-router-dom';
import ContentWrapper from '../../global/ContentWrapper';
import ForgotPassword from '../../global/ForgotPassword';

const PasswordReset = () => {
  const history = useHistory();

  const handleViewLinkClick = (newView) => {
    if (newView === 'login') {
      history.push('/');
    }

    if (newView === 'register') {
      history.push('/signup');
    }
  };

  return (
    <ContentWrapper>
      <ForgotPassword onViewLinkClick={(newView) => handleViewLinkClick(newView)} />
    </ContentWrapper>
  );
};
export default PasswordReset;
