import { useMemo } from 'react';
import classNames from 'classnames';

import Card from '../../../../../global/Mui/Card';
import ShippingProviderLocations from '../ShippingProviderLocations';

import './ShippingProviderSelectItem.scss';

function ShippingProviderSelectItem({
  index,
  shippingProvider,
  selectedIndex,
  zipcode,
  onSelect,
}) {
  const {
    promotion,
    logoURL,
    name,
    locations,
  } = useMemo(() => shippingProvider, [shippingProvider]);

  const isCurrentItemSelected = useMemo(() => (index === selectedIndex), [index, selectedIndex]);

  return (
    <>
      <Card
        onClick={() => onSelect(index, name)}
        className={classNames('ShippingProviderSelectItem', {
          selectedCardContainer: isCurrentItemSelected,
        })}
      >
        <div className="ShippingProviderSelectItem__header">
          <img src={logoURL} alt={`${name} logo`} className="shippingProviderImage" />
          <div className="shippingProviderDescriptionContainer">
            <div className="shipingProviderNameContainer">
              <p className="shipingProviderNameText">
                {name}
              </p>
              <input
                type="radio"
                className="radioButton"
                readOnly
                checked={isCurrentItemSelected}
              />
            </div>
            {promotion && (
              <p className="shipingProviderPromotionText">
                {`Get an extra ${promotion.amount} TrashieCash™*`}
              </p>
            )}
          </div>
        </div>
        {locations?.enabled && (
          <ShippingProviderLocations
            locations={locations}
            zipcode={zipcode}
          />
        )}
      </Card>
      {promotion && (
        <p className="shipingProviderPromotionnNote">
          * Gets awarded upon bag registration.
        </p>
      )}
    </>
  );
}

export default ShippingProviderSelectItem;
