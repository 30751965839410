import { Button } from 'react-bootstrap';
import GoogleIcon from '../../icons/GoogleIcon';

const GoogleButton = ({ className, children, onClick }) => (
  <Button
    variant="secondary"
    className={
      className ? ''.concat(className) : 'mb-3 w-100 rounded-pill py-8'
    }
    onClick={onClick}
    type="button"
  >
    <div className="d-flex align-items-center justify-content-center">
      {children || (
        <>
          <GoogleIcon className="font-size-24 me-8" />
          <div>Sign in with Google</div>
        </>
      )}
    </div>
  </Button>
);

export default GoogleButton;
