const TbbSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M17.95 2.483H1.975v15.525H17.95V2.483ZM5.275 10.209h9.45M7.975 6.458h4.05M7.975 13.959h4.05" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M1 1.208h18v18H1z" />
      </clipPath>
    </defs>
  </svg>
);
export default TbbSvg;
