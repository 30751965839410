import classNames from 'classnames';

import GoogleLogo from '../../images/google-logo.svg';

import './GoogleButton.scss';

const GoogleButton = ({
  className,
  children,
  onClick,
  label = 'CONTINUE WITH GOOGLE',
}) => (
  <button
    className={classNames('GoogleButton', className)}
    onClick={onClick}
    type="button"
  >
    <div className="GoogleButton__content">
      {children || (
        <>
          <img src={GoogleLogo} alt="google" />
          <div>{label}</div>
        </>
      )}
    </div>
  </button>
);

export default GoogleButton;
