/* eslint-disable react/jsx-one-expression-per-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import poweredByFordays from '../../../images/redeem/powered_by_fordays.png';
import { useFirebaseAuth } from '../FirebaseProvider/FirebaseProvider';

const Menu = ({
  onMenuClosed,
  onLogout,
  cashBalance,
}) => {
  const { isSignedIn } = useFirebaseAuth();
  return (
    <div className="bg-white vh-100 vw-100 d-flex flex-column position-fixed" style={{ zIndex: 1050 }}>
      <div className="d-flex justify-content-end me-3 my-3">
        <FontAwesomeIcon
          onKeyDown={onMenuClosed}
          onClick={onMenuClosed}
          icon={faTimes}
          size="lg"
        />
      </div>
      <div className="h-75">
        <ul className="no-bullets">
          {isSignedIn ? (
            <>
              <li className="my-2 font-size-18"><a className="text-warning font-weight-bold font-500" href="/cash"><h5>Balance - {cashBalance}</h5></a></li>
              <hr />
              <li className="my-2 font-size-18"><a className="text-dark font-500" href="/bags">Take Back Bags</a></li>
              {/*
                <li className="my-2 font-size-18">
                  <a
                    className="text-dark font-500"
                    href="/closet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Closet
                  </a>
                </li>
                */}
              <li className="my-2 font-size-18"><a className="text-dark font-500" href="/coupons">Promo Codes</a></li>
              <li className="my-2 font-size-18"><a className="text-dark font-500" href="/giftcards">Gift Cards</a></li>
            </>
          ) : (
            <li role="menuitem" onKeyDown={() => onLogout()} onClick={() => onLogout()}><h4>Login</h4></li>
          )}
        </ul>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <img alt="powered by fordays" src={poweredByFordays} className="justify-self-end mb-3" />
      </div>
    </div>
  );
};

export default Menu;
