import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CardSection from '../../Components/CardSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { TbbCardBalanceSection } from '../../../../global/RegisterTbb/RewardCouponScreen';
import RewardCards from '../../../../images/rewardsCards.png';
import Dialog from '../../../../global/Mui/Dialog';
import Check from '../../../../images/check-green-white.svg';
import Send from '../../../../images/icon-send.svg';
import TCicon from '../../../../images/tc-icon-black-stroke.svg';
import { getNextButtonStyles, shippingMethodProperties } from '../../../../utils/tbbRegistration';
import { ROUTE_REWARDS } from '../../../../config/routes';

import './ShippingAndRewards.scss';

const ShippingAndReward = ({
  storeConfig,
  onClick,
  shippingMethod,
  creditAmt,
  couponData,
  promoCreditAmount,
  openModal,
  setOpenModal }) => {
  const history = useHistory();
  const { shippingAndRewardTitle, ctaLabel, ctaLabel2, findLocation } =
  useMemo(() => shippingMethodProperties[shippingMethod] ?? {}, [shippingMethod]);

  return (
    <div className="ShippingAndReward">
      <div className="ShippingAndReward__container">
        <CardSection title={shippingAndRewardTitle}>
          <div className="ShippingAndReward__container--shipping">
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onClick}
            >
              {ctaLabel}
            </Button>
            <Button
              type={BUTTON_TYPE.QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => window.open(findLocation, '_blank')}
            >
              {ctaLabel2}
            </Button>
          </div>
        </CardSection>

        {!storeConfig.isTrashie && (
        <CardSection title="YOUR Exclusive reward">
          <div className="ShippingAndReward__container--coupon">
            {storeConfig.registerTbbFlow.rewardStep?.couponDescription && (
              <div className="ShippingAndReward__container--coupon--description">
                {storeConfig.registerTbbFlow.rewardStep.couponDescription}
              </div>
            )}
            <TbbCardBalanceSection
              storeConfig={storeConfig}
              couponData={couponData}
              couponCreditAmt={storeConfig?.registerTbbFlow?.rewardStep?.cashValue}
            />
          </div>
        </CardSection>
        )}

        <CardSection
          title="trashiecash™ rewards"
          footer={<img src={RewardCards} alt="rewards" className="ShippingAndReward__container--rewardImage" />}
        >
          <div className="ShippingAndReward__container--reward">
            <div className="ShippingAndReward__container--reward--description">
              Explore exclusive rewards for everyday purchases
            </div>
            <Button
              type={BUTTON_TYPE.QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => history.push(ROUTE_REWARDS)}
            >
              view all rewards
            </Button>
          </div>
        </CardSection>
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <div className="ShippingAndReward__dialog">
          <div className="ShippingAndReward__dialog--header">
            <img src={Check} alt="check" className="ShippingAndReward__dialog--header--icon" />
          </div>
          <div className="ShippingAndReward__dialog--content">
            <div className="ShippingAndReward__dialog--content--title">
              BAG REGISTERED AND
              READY FOR DROP OFF!
            </div>
            <div className="ShippingAndReward__dialog--content--mesages">
              <div className="ShippingAndReward__dialog--content--mesages--message">
                <img src={Send} alt="send" />
                {' '}
                Label sent via email
              </div>
              <div className="ShippingAndReward__dialog--content--mesages--message">
                <img src={TCicon} alt="trashie-cash" />
                {' '}
                {Number(creditAmt) + Number(promoCreditAmount)}
                {' '}
                TrashieCash™ added to
                your balance
              </div>
            </div>
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => setOpenModal(false)}
              style={getNextButtonStyles(storeConfig)}
            >
              CONTINUE
            </Button>
          </div>
        </div>

      </Dialog>
    </div>
  );
};

export default ShippingAndReward;
