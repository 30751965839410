import { Link } from 'react-router-dom';

import trashieCoinLogo from '../../images/trashie-coin-logo.svg';
import instagramLogo from '../../images/instagram-logo.svg';
import tiktokLogo from '../../images/tiktok-logo.svg';
import facebookLogo from '../../images/facebook-logo.svg';

import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  TRASHIE_SOCIAL_NETWORKS,
  TAKE_BACK_BAG_URL,
  PARTNERSHIPS_URL,
  CONTACT_US_URL,
  SUPPORT_URL,
  BROWSER_EXTENSION_URL,
  HELP_URL,
  HOW_WE_RECYCLE_URL,
} from '../../config/externalURL';

import styles from './Footer.module.scss';

const {
  main,
  container,
  logo,
  img,
  resources,
  list,
  link,
  copy,
  footerColumn,
  columnTitle,
  columnsSection,
  /* subscribeDescription,
  subscribeEmailForm,
  subscribeButton,
  subscribeDisclaimer, */
  linkList,
  socialNetworks,
} = styles;

const Footer = () => (
  <div className={main}>
    <div className={container}>
      <div className={columnsSection}>
        <div className={footerColumn}>
          <Link className={logo} to="/">
            <img className={img} src={trashieCoinLogo} alt="logo" />
          </Link>
        </div>
        <div className={footerColumn}>
          <h3 className={columnTitle}>
            Trashie
          </h3>
          <ul className={linkList}>
            <li>
              <a className={link} href={TAKE_BACK_BAG_URL}>
                The take back bag
              </a>
            </li>
            <li>
              <a className={link} href={HOW_WE_RECYCLE_URL}>
                How we recycle
              </a>
            </li>
            <li>
              <a className={link} href={BROWSER_EXTENSION_URL}>
                The chrome extension
              </a>
            </li>
            <li>
              <a
                className={link}
                href="#accessibility"
                onClick={e => {
                  e.preventDefault();
                  window.interdeal?.a11y.openMenu();
                }}
              >
                Accessibility
              </a>
            </li>
          </ul>
        </div>
        <div className={footerColumn}>
          <h3 className={columnTitle}>
            Contact
          </h3>
          <ul className={linkList}>
            <li>
              <a className={link} href={SUPPORT_URL} target="_blank" rel="noreferrer">
                Support
              </a>
            </li>
            <li>
              <a className={link} href={HELP_URL} target="_blank" rel="noreferrer">
                Help center
              </a>
            </li>
            <li>
              <a className={link} href={CONTACT_US_URL} target="_blank" rel="noreferrer">
                Contact us
              </a>
            </li>
            <li>
              <a className={link} href={PARTNERSHIPS_URL} target="_blank" rel="noreferrer">
                Partnerships
              </a>
            </li>
          </ul>
        </div>
        {/* <div className={footerColumn}>
          <h3 className={columnTitle}>
            Subscribe
          </h3>
          <p className={subscribeDescription}>
            Join the trashie mailing list to stay looped in on amazing deals and ways to earn $$$
          </p>
          <div className={subscribeEmailForm}>
            <Input
              type="text"
              id="email"
              label="Email"
              placeholder="enter your email"
              value={emailRef.current.value}
              ref={emailRef}
            />
            <button
              type="button"
              className={subscribeButton}
              onClick={() => handleSendEmail()}
              disabled={!emailRef.current.value}
            >
              I Love Email
            </button>
          </div>
          <p className={subscribeDisclaimer}>
            <span>By subscribing you agree to with our </span>
            <a className={link} href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            <span> and provide consent to receive updates from our company.</span>
          </p>
        </div> */}
      </div>
      <div className={resources}>
        <div className={list}>
          <span className={copy}>
            © 2024 Trashie. All rights reserved.
          </span>
          <a className={link} href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <a className={link} href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
            Terms of Service
          </a>
        </div>
        <div className={socialNetworks}>
          <a className={logo} href={TRASHIE_SOCIAL_NETWORKS.INSTAGRAM} target="_blank" rel="noreferrer">
            <img className={img} src={instagramLogo} alt="instagram logo" />
          </a>
          <a className={logo} href={TRASHIE_SOCIAL_NETWORKS.TIKTOK} target="_blank" rel="noreferrer">
            <img className={img} src={tiktokLogo} alt="tiktok logo" />
          </a>
          <a className={logo} href={TRASHIE_SOCIAL_NETWORKS.FACEBOOK} target="_blank" rel="noreferrer">
            <img className={img} src={facebookLogo} alt="facebook logo" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
