import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import sendRedemptionEmail from '../../../utils/sendRedemptionEmail';
import { useAlert } from '../../providers/AlertProvider';

const QRCodeModal = ({ show, setShow, postageInfo: { code, qrUrl, labelUrl } }) => {
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const setAlert = useAlert();
  const handleSendMail = async () => {
    setLoadingSendMail(true);
    try {
      await sendRedemptionEmail(code);
      setAlert({
        type: 'bg-success',
        message: 'E-mail sent successfully!',
      });
    } catch {
      setAlert({
        type: 'notification',
        message: 'Unable to send email at this time. Please try again later.',
      });
    } finally { setLoadingSendMail(false); }
  };
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header className="border-0 mb-0 pb-0" closeButton />
      <Modal.Body>
        <div className="px-24 font-size-24 text-center mb-3">
          Show the mobile code at any USPS store to send back your take back
          bag.
        </div>
        <div>
          <img
            className="w-100"
            src={qrUrl}
            alt="qr-code"
          />
        </div>
        {code && (
          <div className="p-24 text-center">
            <Button onClick={handleSendMail} variant="transparent" className="p-0 m-0 border-0 cursor-pointer link-dark" disabled={loadingSendMail}>
              {loadingSendMail ? <Spinner animation="border" size="sm" /> : <u>Email me my options</u>}
            </Button>
          </div>
        )}
        {labelUrl && (
          <div className="p-24 text-center">
            <a href={labelUrl} target="_blank" className="link-dark" rel="noreferrer"><u>or Print Label</u></a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default QRCodeModal;
