import { getConfig } from '../../../../../config/config';

const DisclaimerNote = () => (
  <div className="disclaimer container-content-sm w-100 mx-auto text-center pt-1 px-3 pb-4">
    <small className="text-white">
      By continuing your agree to our
      <a
        className="mx-1 text-white"
        target="_blank"
        href={`${getConfig('WEB_URL')}/policies/terms-of-service`}
        rel="noreferrer"
      >
        <u>Terms and Services</u>
      </a>
      and our
      <a
        className="mx-1 text-white"
        target="_blank"
        href={`${getConfig('WEB_URL')}/pages/privacy-policy`}
        rel="noreferrer"
      >
        <u>Privacy Policy</u>
      </a>
    </small>
  </div>
);

export default DisclaimerNote;
