const LearnMore = () => (
  <p className="mt-16">
    New to Your Account?
    {' '}
    <a
      href="https://fordays.com/pages/earn-closet-cash"
      className="text-decoration-none link-warning"
      target="_blank"
      rel="noreferrer"
    >
      Learn More
    </a>
  </p>
);

export default LearnMore;
