const RewardSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
  >
    <mask id="a" fill="#fff">
      <rect width={18} height={14} x={1} y={3.208} rx={1.565} />
    </mask>
    <rect
      width={18}
      height={14}
      x={1}
      y={3.208}
      stroke={color}
      strokeWidth={3.6}
      mask="url(#a)"
      rx={1.565}
    />
    <path
      fill={color}
      d="M13.607 12.993a.666.666 0 0 1-.341-.088.594.594 0 0 1-.232-.242.756.756 0 0 1-.081-.356v-.228a2.895 2.895 0 0 1-1.276-.51c-.272-.194-.408-.42-.408-.675 0-.12.028-.23.084-.33.059-.101.138-.18.236-.24a.627.627 0 0 1 .327-.087c.143 0 .293.049.45.147.22.139.436.246.647.324.213.077.411.116.594.116.185 0 .323-.026.415-.078.091-.053.137-.127.137-.221a.214.214 0 0 0-.081-.18.584.584 0 0 0-.25-.094l-1.03-.208c-.454-.091-.794-.26-1.02-.506-.224-.248-.337-.577-.337-.984 0-.286.061-.544.183-.774.122-.232.297-.424.524-.576.227-.155.496-.26.805-.317v-.242c0-.132.027-.249.08-.352a.594.594 0 0 1 .233-.242.666.666 0 0 1 .34-.088c.13 0 .244.029.342.088a.57.57 0 0 1 .232.239.73.73 0 0 1 .084.355v.299c.317.082.614.218.893.407.263.178.394.41.394.693a.76.76 0 0 1-.07.33.561.561 0 0 1-.208.236.58.58 0 0 1-.316.084.89.89 0 0 1-.306-.056 2.084 2.084 0 0 1-.334-.165 3.86 3.86 0 0 0-.496-.243 1.261 1.261 0 0 0-.485-.102.604.604 0 0 0-.31.07.23.23 0 0 0-.115.212c0 .086.03.15.088.193.058.04.158.076.299.109l.977.225c.274.063.508.15.703.26.195.108.35.259.464.454.115.194.172.44.172.738 0 .41-.12.758-.358 1.044-.24.284-.57.48-.992.587v.288a.738.738 0 0 1-.084.359.57.57 0 0 1-.232.239.654.654 0 0 1-.341.088Z"
    />
  </svg>
);
export default RewardSvg;
