import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import GoogleButton from '../GoogleButton';

const Register = ({
  onSignInWithGoogle,
  onRegister,
  onBackClick,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState('');

  const handleRegistration = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setAlert('Passwords do not match');
      return;
    }

    await onRegister({ email, password });
  };

  return (
    <div className="container-content-xs mx-auto text-center mt-5 card rounded-0 border border-dark px-8 py-24 p-md-40">
      <img className="mx-auto mb-48" alt="logo" src="//cdn.shopify.com/s/files/1/0503/2601/2056/files/for-days-logo-black_140x.png?v=1614290300" style={{ width: '150px' }} />
      <h5 className="mb-24">Let&apos;s create your account</h5>
      <GoogleButton onClick={() => onSignInWithGoogle()} />
      <div className="mb-24">or</div>
      <Form onSubmit={handleRegistration}>
        <Form.Group>
          <Form.Control
            type="text"
            className="border-dark rounded-0 mb-8"
            id="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control
            type="password"
            className="border-dark rounded-0 mb-8"
            id="password"
            value={password}
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Control
            type="password"
            className="border-dark rounded-0"
            id="confirmPassword"
            value={confirmPassword}
            placeholder="Re-enter Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="dark"
          type="submit"
          className="mt-16 rounded-pill py-8 w-100"
          onClick={(e) => handleRegistration(e)}
        >
          Register
        </Button>
      </Form>
      <Button
        variant="link"
        onClick={onBackClick}
        type="button"
        className="mt-16 link-dark text-decoration-none fs-6"
      >
        Back to Sign In
      </Button>
      <p className="mb-0 text-danger">{alert}</p>
    </div>
  );
};

export default Register;
