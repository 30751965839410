import { useMemo } from 'react';
import Pill from '../../../../global/Pill/Pill';

const CouponPill = ({
  coupon, onCouponPillClick,
}) => {
  const ImageCap = useMemo(
    () => <div style={{ height: '6rem' }}><img src={coupon.imageUrl} alt="Card cap" className="py-32 mx-auto w-50" /></div>,
    [coupon],
  );
  const Footer = useMemo(() => (
    <>
      {coupon.status === 'used' && (
        <div className="text-secondary font-size-12">USED</div>
      )}
      <div>
        <small>
          Created At:
          {' '}
          {coupon.createdAt.split('T')[0]}
        </small>
      </div>
    </>
  ), [coupon]);

  return (
    <Pill
      Footer={Footer}
      ImageCap={ImageCap}
      value={coupon}
      disabled={false}
      onClick={onCouponPillClick}
      className="border text-center"
    >
      <div className="my-16">{coupon.code}</div>
      <h6 className="card-title my-8 text-warning">
        $
        {coupon.amount}
        {' '}
        off at
        <div className="font-weight-bold text-dark mt-16">
          {coupon.storeName.replace('closet-cash', 'TrashieCash™')}
        </div>
      </h6>
    </Pill>
  );
};

export default CouponPill;
