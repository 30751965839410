import React, { useState } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import ContentWrapper from '../../global/ContentWrapper';
import Coupons from './Coupons';
import Cash from './Cash';
import GiftCards from './GiftCards';

const Wallet = () => {
  const [tab, setTab] = useState('cash');
  const activeTab = 'text-white bg-dark rounded-pill';

  return (
    <div>
      <ContentWrapper>
        <div className="text-center d-flex justify-content-center">
          <div>
            <Button
              onClick={() => { setTab('cash'); }}
              className={`${tab === 'cash' ? activeTab : ''}text-dark py-2 px-2 px-lg-4 border-0 shadow-none`}
            >
              TrashieCash™
            </Button>
          </div>
          <div className="d-none d-lg-block mx-2 mx-md-3">
            <div className="bg-dark" style={{ width: '1px', height: '100%' }} />
          </div>
          <div>
            <Button
              onClick={() => { setTab('coupons'); }}
              className={`${tab === 'coupons' ? activeTab : ''}text-dark py-2 px-2 px-lg-4 mx-2 mx-lg-0 border-0 shadow-none`}
            >
              Discount Codes
            </Button>
          </div>
          <div className="d-none d-lg-block mx-2 mx-md-3">
            <div className="bg-dark" style={{ width: '1px', height: '100%' }} />
          </div>
          <div>
            <Button
              onClick={() => { setTab('giftcards'); }}
              className={`${tab === 'giftcards' ? activeTab : ''}text-dark py-2 px-2 px-lg-4 border-0 shadow-none`}
            >
              Gift Cards
            </Button>
          </div>
        </div>
        <Tabs activeKey={tab} className="border-0">
          <Tab eventKey="cash">
            <Cash />
          </Tab>
          <Tab eventKey="coupons">
            <Coupons />
          </Tab>
          <Tab eventKey="giftcards">
            <GiftCards />
          </Tab>
        </Tabs>
      </ContentWrapper>
    </div>
  );
};

export default Wallet;
