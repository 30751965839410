import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import firebase from 'firebase/app';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import Loader from '../../../global/Loader';
import { formatMoney } from '../../../../utils/formatMoney';
import Login from '../../../global/Login';
import { useAlert } from '../../../providers/AlertProvider';
import { SHOPIFY_STORES } from '../../../trashie/config/service';
import { getCCDiscountCodes, postCCDiscountCode } from '../../../trashie/utils/service';
import CloseCircledShadowIcon from '../../../../images/close-circled-shadow-icon.svg';
import TrashieCoin from '../../../trashie/images/TC-icon.svg';
import DollarSignBlackCircledIcon from '../../../../images/dollar-sign-black-circled-icon.svg';
import ForDaysLogoBlack from '../../../../images/for-days-logo-black.webp';
import InfoCircleIcon from '../../../../images/info-circle-icon.svg';
import ShoppingBagCircledIcon from '../../../../images/shopping-bag-circled-icon.svg';
import WarningGrayIcon from '../../../../images/warning-gray-icon.svg';
import { getConfig } from '../../../../config/config';

import './ShopifyCheckout.scss';

const CHECKOUT_TOKEN = 'checkoutToken';
const AUTH_TOKEN = 'authToken';
const TOTAL_TBB = 'totalTbb';
const SUB_TOTAL = 'realSubTotal';
const TOTAL_GIFT_CARDS = 'totalGiftCards';
const TOTAL_CC_DISCOUNTS = 'totalCCDiscounts';

const MAX_CART_TOTAL_APPLICABLE = 0.5;

const CONTENT_STEPS = {
  SELECT: 'select',
  CONFIRM: 'confirm',
};

const MAIN_ELEMENT_ID = 'ShopifyCheckout';

const ShopifyCheckout = () => {
  const timeoutRef = useRef();
  const { isSignedIn, user, isLoading, userData, signOut } = useFirebaseAuth();
  const setAlert = useAlert();
  const query = new URLSearchParams(useLocation().search);
  const subTotal = parseFloat(query.get(SUB_TOTAL) || 0);
  const checkoutToken = query.get(CHECKOUT_TOKEN) || '';
  const authToken = query.get(AUTH_TOKEN) || '';
  const totalTbb = parseFloat(query.get(TOTAL_TBB) || 0);
  const totalGiftCardsAmount = parseFloat(query.get(TOTAL_GIFT_CARDS) || 0);
  const totalCCDiscountsAmount = parseFloat(query.get(TOTAL_CC_DISCOUNTS) || 0);

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [contentStep, setContentStep] = useState(CONTENT_STEPS.SELECT);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [CCDiscountCodes, setCCDiscountCodes] = useState([]);
  const [discountCodeIndex, setDiscountCodeIndex] = useState(0);

  const currentBalance = useMemo(() => (
    parseFloat(userData?.balance?.currentBalance ?? 0)
  ), [userData?.balance?.currentBalance]);

  const selectedDiscountCode = useMemo(() => (
    CCDiscountCodes[discountCodeIndex] ?? {}
  ), [CCDiscountCodes, discountCodeIndex]);

  const maxApplicable = useMemo(() => (
    totalCCDiscountsAmount ? 0 : (
      (subTotal - totalGiftCardsAmount - totalTbb)
      * MAX_CART_TOTAL_APPLICABLE
    )
  ), [subTotal, totalGiftCardsAmount, totalCCDiscountsAmount, totalTbb]);

  const handleSignOut = useCallback(async () => {
    await signOut();

    if (authToken) {
      // eslint-disable-next-line prefer-destructuring
      window.location.href = window
        .location
        .toString()
        .split('authToken')[0]
        .split('email')[0];
    } else {
      window.location.reload();
    }
  }, [signOut, authToken]);

  const loginWithToken = useCallback(async () => {
    await firebase.auth().signInWithCustomToken(authToken);
  }, [authToken]);

  const fetchCCDiscountCodes = useCallback(async () => {
    setIsLoadingPage(true);

    const {
      data,
      error: CCDiscountCodesError,
    } = await getCCDiscountCodes(
      SHOPIFY_STORES.CLOSET_CASH_STORE,
      Math.min(maxApplicable, currentBalance),
    );

    if (CCDiscountCodesError) {
      setCCDiscountCodes([]);
      setAlert({
        type: 'alert',
        message: CCDiscountCodesError,
        error: CCDiscountCodesError,
      });
      setIsLoadingPage(false);
      return;
    }

    setCCDiscountCodes(data);
    setAlert(null);
    setIsLoadingPage(false);
  }, [maxApplicable, currentBalance]);

  const scrollToTop = useCallback(() => {
    const mainElement = document.getElementById(MAIN_ELEMENT_ID);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const handleModalClosed = useCallback(() => {
    window.parent.postMessage('close modal please', '*');
  }, []);

  const handleUpdateDiscount = useCallback(async () => {
    const discountCodeId = selectedDiscountCode.uid;

    if (!discountCodeId) {
      return;
    }

    setIsLoadingPage(true);

    const {
      data,
      error: CCDiscountCodeError,
    } = await postCCDiscountCode(discountCodeId, SHOPIFY_STORES.CLOSET_CASH_STORE);

    if (CCDiscountCodeError || !data.rewardCode) {
      setAlert({
        type: 'alert',
        message: 'Failed to generate code: Please Contact Support or try again later.',
        error: CCDiscountCodeError,
      });
      setIsLoadingPage(false);
      return;
    }

    window.parent.postMessage(`success:${data.code}`, '*');
  }, [selectedDiscountCode.uid]);

  const handleRangeOnChange = useCallback((e) => {
    setDiscountCodeIndex(e.target?.value ?? 0);
  }, []);

  const handleContentStepChange = useCallback((step) => {
    if (step === CONTENT_STEPS.SELECT) {
      setTermsAgreed(false);
    }

    setContentStep(step);
    scrollToTop();
  }, []);

  const getSummary = useCallback(() => {
    const CCApplied = totalGiftCardsAmount + totalCCDiscountsAmount
      + parseFloat(selectedDiscountCode.rewardAmount ?? 0);

    return (
      <div className="ShopifyCheckout__summary">
        <h3 className="ShopifyCheckout__summary--title">
          Summary
        </h3>
        <div className="ShopifyCheckout__summary--order">
          <img src={ShoppingBagCircledIcon} className="ShopifyCheckout__summary--icon" alt="shopping bag icon" />
          <p className="ShopifyCheckout__summary--order-label">
            Order Value
          </p>
          <p className="ShopifyCheckout__summary--order-value">
            {formatMoney(subTotal)}
          </p>
        </div>
        <div className="ShopifyCheckout__summary--applied">
          <img src={TrashieCoin} className="ShopifyCheckout__summary--icon" alt="closet cash icon" />
          <p className="ShopifyCheckout__summary--applied-label">
            TrashieCash™ Applied
          </p>
          <p className="ShopifyCheckout__summary--applied-value">
            {formatMoney(CCApplied)}
          </p>
        </div>
        <div className="ShopifyCheckout__summary--total">
          <img src={DollarSignBlackCircledIcon} className="ShopifyCheckout__summary--icon" alt="dollar sign icon" />
          <p className="ShopifyCheckout__summary--total-label">
            Order Total
          </p>
          <p className="ShopifyCheckout__summary--total-value">
            {formatMoney(subTotal - CCApplied)}
          </p>
        </div>
      </div>
    );
  }, [
    totalGiftCardsAmount,
    totalCCDiscountsAmount,
    totalTbb,
    selectedDiscountCode,
  ]);

  const getContentStep = useCallback(() => {
    switch (contentStep) {
      case CONTENT_STEPS.CONFIRM:
        return (
          <div key={`content_${contentStep}`} className="ShopifyCheckout__content">
            <div className="ShopifyCheckout__info">
              <h2 className="ShopifyCheckout__info--title">
                Ready to Check Out?
              </h2>
            </div>
            <div className="ShopifyCheckout__conditions">
              <div className="ShopifyCheckout__conditions--item">
                <img src={WarningGrayIcon} className="ShopifyCheckout__conditions--item-icon" alt="warning icon" />
                <p className="ShopifyCheckout__conditions--item-label">
                  This withdrawal from your TrashieCash™ balance
                  is permanent and cannot be refunded.
                </p>
              </div>
              <div className="ShopifyCheckout__conditions--item">
                <img src={WarningGrayIcon} className="ShopifyCheckout__conditions--item-icon" alt="warning icon" />
                <p className="ShopifyCheckout__conditions--item-label">
                  After clicking “confirm” a special discount code will be applied to your cart.
                </p>
              </div>
              <div className="ShopifyCheckout__conditions--item">
                <img src={WarningGrayIcon} className="ShopifyCheckout__conditions--item-icon" alt="warning icon" />
                <span className="ShopifyCheckout__conditions--item-label">
                  <span>
                    {`If you “confirm” but don’t check out now, 
                    you’ll find your special discount code matching your withdrawal amount in your `}
                  </span>
                  <a href={`${getConfig('BASE_URL')}/wallet`} target="_blank" rel="noopener noreferrer">
                    TrashieCash™ Account
                  </a>
                  <span>.</span>
                </span>
              </div>
              <div className="ShopifyCheckout__conditions--item">
                <img src={WarningGrayIcon} className="ShopifyCheckout__conditions--item-icon" alt="warning icon" />
                <p className="ShopifyCheckout__conditions--item-label">
                  TrashieCash™  is not stackable. One transaction per order.
                </p>
              </div>
            </div>
            {getSummary()}
            <div className="ShopifyCheckout__cta">
              <button
                type="button"
                className="ShopifyCheckout__back"
                onClick={() => handleContentStepChange(CONTENT_STEPS.SELECT)}
              >
                Go Back
              </button>
              <button
                type="button"
                className="ShopifyCheckout__submit"
                onClick={handleUpdateDiscount}
                disabled={!termsAgreed || !selectedDiscountCode.rewardAmount || !currentBalance}
              >
                Confirm
              </button>
            </div>
          </div>
        );
      case CONTENT_STEPS.SELECT:
      default: {
        const maxRewardAmount = CCDiscountCodes[CCDiscountCodes.length - 1]?.rewardAmount ?? 0;

        return (
          <div key={`content_${contentStep}`} className="ShopifyCheckout__content">
            <div className="ShopifyCheckout__info">
              <h2 className="ShopifyCheckout__info--title">
                Apply TrashieCash™ to Your Order
              </h2>
              <p className="ShopifyCheckout__info--description">
                {`Use up to ${formatMoney(parseFloat(maxRewardAmount) + totalCCDiscountsAmount)} off $${subTotal}`}
              </p>
            </div>
            {CCDiscountCodes.length > 1 && (
              <div className="ShopifyCheckout__selector">
                <p className="ShopifyCheckout__selector--label">
                  Select an amount
                </p>
                <div className="ShopifyCheckout__selector--range">
                  <input
                    type="range"
                    className="ShopifyCheckout__selector--range-input"
                    min={0}
                    max={CCDiscountCodes.length - 1}
                    step={1}
                    value={discountCodeIndex}
                    onChange={handleRangeOnChange}
                  />
                </div>
                <div className="ShopifyCheckout__selector--values">
                  <p className="ShopifyCheckout__selector--values-value">
                    {`$${CCDiscountCodes[0]?.rewardAmount ?? 0}`}
                  </p>
                  <OverlayTrigger
                    placement="left"
                    overlay={(
                      <Tooltip>
                        {`Apply up to ${formatMoney(maxRewardAmount)} off to your order value.`}
                        {totalTbb > 0 && (
                          <>
                            <br />
                            *Take Back Bag excluded
                          </>
                        )}
                      </Tooltip>
                    )}
                  >
                    <p className="ShopifyCheckout__selector--values-value">
                      {`$${maxRewardAmount}`}
                      <img src={InfoCircleIcon} alt="info icon" />
                    </p>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            <div className="ShopifyCheckout__selected">
              <div className="ShopifyCheckout__selected--amount">
                <p className="ShopifyCheckout__selected--amount-label">
                  Selected Amount
                </p>
                <p className="ShopifyCheckout__selected--amount-value">
                  {formatMoney(selectedDiscountCode.rewardAmount)}
                </p>
              </div>
              <div className="ShopifyCheckout__selected--balance">
                <p className="ShopifyCheckout__selected--balance-value">
                  Available TrashieCash™ Balance
                </p>
                <p className="ShopifyCheckout__selected--balance-value">
                  {formatMoney(currentBalance)}
                </p>
              </div>
            </div>
            <p className="ShopifyCheckout__note">
              *TrashieCash™ cannot be used towards the purchase of the Take Back Bag
            </p>
            {getSummary()}
            <FormCheck
              type="checkbox"
              id="terms-agreed"
              className="ShopifyCheckout__terms"
              label={(
                <>
                  <span>I agree to the </span>
                  <a href={`${getConfig('WEB_URL')}/pages/privacy-policy`} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  <span> and </span>
                  <a href={`${getConfig('WEB_URL')}/policies/terms-of-service`} target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                  <span>.</span>
                </>
              )}
              checked={termsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}
            />
            <button
              type="button"
              className="ShopifyCheckout__submit"
              onClick={() => handleContentStepChange(CONTENT_STEPS.CONFIRM)}
              disabled={!termsAgreed || !selectedDiscountCode.rewardAmount || !currentBalance}
            >
              Next
            </button>
          </div>
        );
      }
    }
  }, [
    userData,
    currentBalance,
    contentStep,
    CCDiscountCodes,
    selectedDiscountCode,
    termsAgreed,
    totalGiftCardsAmount,
    totalCCDiscountsAmount,
  ]);

  useEffect(() => {
    if (!isSignedIn || !user || !userData) {
      return;
    }

    if (checkoutToken === '') {
      window.parent.postMessage(`Bearer:${user.Aa}`, '*');
    }

    fetchCCDiscountCodes();
  }, [isSignedIn, user, userData]);

  useEffect(() => {
    if (authToken) {
      loginWithToken();
    }

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  if (!isSignedIn && !isLoading) {
    return (
      <Login loginredirect={`/embed/shopify/checkout?${query.toString()}`} />
    );
  }

  if (isLoadingPage) {
    return (
      <div className="w-100 vh-100 d-flex flex-column align-items-center justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <section id="shopify-embed-container">
      <div id="ShopifyCheckout" className="ShopifyCheckout">
        <button
          type="button"
          className="ShopifyCheckout__close"
          onClick={handleModalClosed}
        >
          <img src={CloseCircledShadowIcon} alt="close icon" />
        </button>
        <div className="ShopifyCheckout__logo">
          <img
            src={ForDaysLogoBlack}
            alt="fordays logo"
          />
        </div>
        {getContentStep()}
      </div>
      <div className="ShopifyCheckout__footer">
        <div className="ShopifyCheckout__footer--info">
          <span className="ShopifyCheckout__footer--info-label">Signed In</span>
          <span
            className="ShopifyCheckout__footer--info-email"
            title={userData?.email}
          >
            {userData?.email}
          </span>
        </div>
        <button
          type="button"
          className="ShopifyCheckout__footer--switch"
          onClick={handleSignOut}
        >
          Switch Account
        </button>
      </div>
    </section>
  );
};

export default ShopifyCheckout;
