/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { PostHogProvider } from 'posthog-js/react';
import LogRocket from 'logrocket';
import ShopifyCheckout from './components/embed/Checkout/Shopify';
import './App.css';
import './scss/style.scss';
import { FirebaseProvider } from './components/global/FirebaseProvider/FirebaseProvider';
import Home from './components/views/Home';
import Cash from './components/views/Cash';
import Settings from './components/views/Settings';
import PublicRoute from './components/global/Routes/PublicRoute';
import PrivateRoute from './components/global/Routes/PrivateRoute';
import Signup from './components/views/Signup';
import PasswordReset from './components/views/PasswordReset';
import WalletContainer from './components/containers/WalletContainer';
import AlertProvider from './components/providers/AlertProvider';
import SignUp from './components/embed/SignUp';
import Claim from './components/views/Claim';
import TakeBackBag from './components/views/TakeBackBag/TakeBackBag';
import LandingPage from './components/views/LandingPage';
import PartnershipPortal from './components/views/PartnershipPortal';
import ContactForm from './components/views/ContactForm';
import RedemptionLandingPage from './components/trashie/views/Register';
import RewardsPage from './components/views/RewardsPage';
import TbbContactForm from './components/views/TbbContactForm';
import Customers from './components/views/Customers/Customers';
import Orders from './components/views/Orders/Orders';
import Addresses from './components/views/Addresses/Addresses';
import Wallet from './components/views/Wallet/Wallet';
import SignIn from './components/embed/SignIn';
import LoginSuccess from './components/embed/LoginSuccess';
import Logout from './components/views/Logout';

// Trashie
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_LOGIN_WITH_LINK,
  ROUTE_LOGOUT,
  ROUTE_SIGNUP,
  ROUTE_SETTINGS,
  ROUTE_ADDRESSES,
  ROUTE_DASHBOARD,
  ROUTE_REDEEM,
  ROUTE_PROFILE,
  ROUTE_REGISTER,
  ROUTE_TAKE_BACK_BAGS,
  ROUTE_TAKE_BACK_BAGS_DETAILS,
  ROUTE_MY_REWARDS,
  ROUTE_MY_ACCOUNT,
  ROUTE_REWARD_ID,
  ROUTE_REWARD_INFO,
  ROUTE_DEAL_INFO,
  ROUTE_REWARDS_STORE_ID,
  ROUTE_REWARDS_STORE_REWARD_ID,
  ROUTE_REWARDS,
  ROUTE_TRANSACTIONS,
  ROUTE_OFFERS,
  ROUTE_REWARDS_OLD_STORE_ID,
  ROUTE_REWARDS_OLD_STORE_REWARD_ID,
  ROUTE_REWARDS_OLD,
  ROUTE_REWARD_OLD_INFO,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_ID,
  ROUTE_DEAL_STORE_REWARD_ID,
} from './components/trashie/config/routes';
import Container from './components/trashie/containers/Container';
import TrashieContainer from './components/trashie/containers/TrashieContainer';
import TrashieLogin from './components/trashie/views/Login';
import TrashieLoginWithEmailLink from './components/trashie/views/LoginWithEmailLink';
import TrashieLogout from './components/trashie/views/Logout';
import TrashieSettings from './components/trashie/views/Settings';
import TrashieProfile from './components/trashie/views/Profile';
import TrashieAddresses from './components/trashie/views/Addresses';
import TrashieDashboardNew from './components/trashie/views/DashboardNew';
import TrashieMyRewards from './components/trashie/views/MyRewards';
import TrashieTakeBackBags from './components/trashie/views/TakeBackBags';
import TrashieTakeBackBagsDetails from './components/trashie/views/TakeBackBagDetails';
import TrashieMyAccount from './components/trashie/views/MyAccount';
import TrashieOldReward from './components/trashie/views/OldReward';
import TrashieReward from './components/trashie/views/Reward';
import TrashieRewards from './components/trashie/views/Rewards';
import TrashieRewardInfo from './components/trashie/views/RewardInfo';
import TrashieTransactions from './components/trashie/views/Transactions';
import TrashieOffers from './components/trashie/views/Offers';
import TrashiePublicRoute from './components/trashie/global/Routes/PublicRoute';
import TrashiePrivateRoute from './components/trashie/global/Routes/PrivateRoute';
import EnvironmentBanner from './components/trashie/global/EnvironmentBanner';
import TrashieDeals from './components/trashie/views/Deals';
import ShopifyUpgradeBanner from './components/trashie/global/Banner/ShopifyUpgradeBanner';

import { getConfig, isProd, isTrashieApp } from './config/config';
import useScript from './hooks/useScript';
import useExternalScript from './hooks/useExternalScript';
import gaScript from './components/trashie/scripts/ga';
import northbeamScript from './components/trashie/scripts/northbeam';
import equalwebScript from './components/trashie/scripts/equalweb';
import metaPixelScript from './components/trashie/scripts/metaPixel';
import googlePlacesApi from './components/trashie/scripts/googlePlacesApi';

import {
  TRASHIE_POSTHOG_API_HOST,
  TRASHIE_POSTHOG_API_KEY,
  TRASHIE_LOGROCKET_APP_ID,
} from './components/trashie/config/config';
import { GOOGLE_TAG_MANAGER_URL } from './components/trashie/config/externalURL';
import { setDocumentFavicon, setDocumentTitle } from './components/trashie/utils/utils';

import MaintenanceBanner from './components/trashie/global/MaintenanceBanner';
import TrashieContainerPublic from './components/trashie/containers/TrashieContainerPublic/TrashieContainerPublic';
import useNoScript from './hooks/useNoScript';

TagManager.initialize({
  gtmId: 'G-TJF3HRLGW7',
});

const metaNoScript = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=7826850917342250&ev=PageView&noscript=1"/>';

const App = () => {
  useExternalScript(GOOGLE_TAG_MANAGER_URL);
  useScript(gaScript);
  useScript(northbeamScript);
  useScript(equalwebScript);
  useScript(metaPixelScript);
  useScript(null, googlePlacesApi);
  useNoScript(isTrashieApp() ? metaNoScript : null);

  useEffect(() => {
    setDocumentTitle();
    setDocumentFavicon();

    if (isTrashieApp()) {
      LogRocket.init(
        isProd() ? TRASHIE_LOGROCKET_APP_ID.PRODUCTION : TRASHIE_LOGROCKET_APP_ID.STAGING,
        {
          release: process.env.REACT_APP_VERSION,
        },
      );
    }
  }, []);

  return (
    <FirebaseProvider>
      <AlertProvider>
        <Router>
          <Switch>
            {isTrashieApp() ? (
              <PostHogProvider
                apiKey={isProd() ? (
                  TRASHIE_POSTHOG_API_KEY.PRODUCTION
                ) : (
                  TRASHIE_POSTHOG_API_KEY.STAGING
                )}
                options={{
                  api_host: TRASHIE_POSTHOG_API_HOST,
                }}
              >
                {!isProd() && <EnvironmentBanner />}
                <MaintenanceBanner />
                <Container>
                  <Route exact path={ROUTE_HOME}>
                    <Redirect to={ROUTE_DASHBOARD} />
                  </Route>
                  <Route exact path="/profile">
                    <Redirect to={ROUTE_PROFILE} />
                  </Route>
                  <TrashiePublicRoute
                    path={ROUTE_REDEEM}
                    exact
                    render={(location) => (
                      <Redirect to={`${ROUTE_REGISTER}${location.search}`} />
                    )}
                  />
                  <TrashiePublicRoute
                    path={ROUTE_SIGNUP}
                    exact
                    render={(location) => (
                      <Redirect to={`${ROUTE_LOGIN}${location.search}`} />
                    )}
                  />
                  <TrashiePublicRoute
                    path={ROUTE_REGISTER}
                    exact
                    handleRedirect={false}
                    component={RedemptionLandingPage}
                  />
                </Container>
                <TrashieContainerPublic>
                  <TrashiePublicRoute
                    path={ROUTE_LOGIN}
                    exact
                    handleRedirect={false}
                    component={TrashieLogin}
                  />
                  <TrashiePublicRoute
                    path={ROUTE_LOGIN_WITH_LINK}
                    exact
                    handleRedirect={false}
                    component={TrashieLoginWithEmailLink}
                  />
                </TrashieContainerPublic>
                <TrashieContainer>
                  <TrashiePrivateRoute path={ROUTE_LOGOUT} exact component={TrashieLogout} />
                  <TrashiePrivateRoute path={ROUTE_SETTINGS} exact component={TrashieSettings} />
                  <TrashiePrivateRoute path={ROUTE_PROFILE} exact component={TrashieProfile} />
                  <TrashiePrivateRoute path={ROUTE_ADDRESSES} exact component={TrashieAddresses} />
                  <TrashiePrivateRoute
                    path={ROUTE_DASHBOARD}
                    exact
                    component={TrashieDashboardNew}
                  />
                  <TrashiePrivateRoute path={ROUTE_MY_REWARDS} exact component={TrashieMyRewards} />
                  <TrashiePrivateRoute
                    path={ROUTE_TAKE_BACK_BAGS}
                    exact
                    component={TrashieTakeBackBags}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_TAKE_BACK_BAGS_DETAILS}
                    exact
                    component={TrashieTakeBackBagsDetails}
                  />
                  <TrashiePrivateRoute path={ROUTE_MY_ACCOUNT} exact component={TrashieMyAccount} />
                  <TrashiePrivateRoute path={ROUTE_REWARD_ID} exact component={TrashieOldReward} />
                  {/* Old rewards URL support - Start */}
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS_OLD_STORE_ID}
                    exact
                    component={TrashieReward}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS_OLD_STORE_REWARD_ID}
                    exact
                    component={TrashieReward}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARD_OLD_INFO}
                    exact
                    component={TrashieRewardInfo}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS_OLD}
                    exact
                    component={TrashieRewards}
                  />
                  {/* Old rewards URL support - End */}
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS_STORE_ID}
                    exact
                    component={TrashieReward}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS_STORE_REWARD_ID}
                    exact
                    component={TrashieReward}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARD_INFO}
                    exact
                    component={TrashieRewardInfo}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_DEAL_INFO}
                    exact
                    component={TrashieRewardInfo}
                    componentProps={{ isDeal: true }}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_REWARDS}
                    exact
                    component={TrashieRewards}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_TRANSACTIONS}
                    exact
                    component={TrashieTransactions}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_OFFERS}
                    exact
                    component={TrashieOffers}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_DEALS}
                    exact
                    component={TrashieDeals}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_DEAL_STORE_ID}
                    exact
                    component={TrashieReward}
                    componentProps={{ isDeal: true }}
                  />
                  <TrashiePrivateRoute
                    path={ROUTE_DEAL_STORE_REWARD_ID}
                    exact
                    component={TrashieReward}
                    componentProps={{ isDeal: true }}
                  />
                </TrashieContainer>
              </PostHogProvider>
            ) : (
              <>
                {!isProd() && <EnvironmentBanner />}
                <MaintenanceBanner />
                <ShopifyUpgradeBanner />
                <PublicRoute path="/embed/shopify/checkout" exact component={ShopifyCheckout} />
                <PublicRoute path="/contact" exact component={ContactForm} />
                <PublicRoute path="/tbbcontact" exact component={TbbContactForm} />
                <PublicRoute path="/landing" exact component={LandingPage} />
                <PublicRoute
                  path="/redeem"
                  exact
                  render={(location) => {
                    window.location.href = `${getConfig('BASE_URL_TRASHIE')}${ROUTE_REGISTER}${location.search}`;
                  }}
                />
                <PublicRoute path="/rewards" exact component={RewardsPage} />
                <PublicRoute path="/embed/loginsuccess" exact component={LoginSuccess} />
                <WalletContainer>
                  <PublicRoute path="/" exact component={Home} />
                  <PublicRoute path="/signup" exact component={Signup} />
                  <PublicRoute path="/reset" exact component={PasswordReset} />
                  <PublicRoute path="/claim" exact component={Claim} />
                  <PublicRoute path="/embed/signup" exact component={SignUp} />
                  <PublicRoute path="/embed/signin" exact component={SignIn} />
                  <PrivateRoute path="/partner" exact component={PartnershipPortal} />
                  <PrivateRoute path="/logout" exact component={Logout} />
                  <PrivateRoute path="/partner/customers" exact component={Customers} />
                  <PrivateRoute path="/cash" exact component={Cash} />
                  {/* <PrivateRoute path="/closet" exact component={Closet} /> */}
                  <PrivateRoute path="/settings" exact component={Settings} />
                  <PrivateRoute path="/bags" exact component={TakeBackBag} />
                  <PrivateRoute path="/orders" exact component={Orders} />
                  <PrivateRoute path="/addresses" exact component={Addresses} />
                  <PrivateRoute path="/wallet" exact component={Wallet} />
                  <Route exact path="/earnings">
                    <Redirect to="/wallet" />
                  </Route>
                </WalletContainer>
              </>
            )}
          </Switch>
        </Router>
      </AlertProvider>
      <div id="recaptcha-container" />
    </FirebaseProvider>
  );
};

export default App;
