import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import crud from '../../../api/crud';
import { useAlert } from '../../providers/AlertProvider';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import CouponPill from '../../redeem/qrCode/components/CouponPill';
import ContentWrapper from '../../global/ContentWrapper';
import Loader from '../../global/Loader';
import CouponDetailView from '../../redeem/qrCode/components/CouponDetailView';

const Coupons = () => {
  const { user } = useFirebaseAuth();
  const [coupons, setCoupons] = useState([]);
  const [isCouponDetailView, setIsCouponDetailView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState(null);
  const setAlert = useAlert();

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        setIsLoading(true);
        const couponTransactions = await crud.get({
          path: '/discountCodes',
          options: {
            where: {
              userId: user.uid,
            },
          },
        });
        if (mounted || false) {
          setCoupons(couponTransactions);
        }
      } catch (error) {
        setAlert({
          type: 'notification',
          message: error.message,
          error,
        });
      } finally {
        setIsLoading(false);
      }
    };
    init();

    return () => { mounted = false; };
  }, []);

  const handleCouponClick = (coupon) => {
    setIsCouponDetailView(true);
    setActiveCoupon(coupon);
  };

  if (isLoading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <Loader className="d-flex flex-column align-items-center" message="Loading Promo Codes" />
      </div>
    );
  }

  if (isCouponDetailView) {
    return (
      <ContentWrapper>
        <CouponDetailView
          onClose={() => setIsCouponDetailView(false)}
          coupon={activeCoupon}
        />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      {coupons?.length ? (
        <>
          <p className="mb-24 text-center">Click a coupon to to open in store</p>
          <Row className="g-24 mb-24">
            {coupons.map((coupon) => (
              <Col xs={12} md={6} key={coupon.code}>
                <CouponPill
                  onCouponPillClick={() => handleCouponClick(coupon)}
                  coupon={coupon}
                  key={coupon.code}
                />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h3>No Coupons Found</h3>
          <div className="mt-3">Click below to start shopping fordays to earn TrashieCash™</div>
          <a
            className="btn btn-dark rounded-pill border-dark mt-4 "
            href="https://fordays.com"
            target="_blank"
            rel="noreferrer"
          >
            Shop fordays.com
          </a>
          <a
            href="https://www.trashie.io/spend-offers"
            target="_blank"
            rel="noreferrer"
            className="btn btn-dark rounded-pill border-dark mt-4 "
          >
            Shop Rewards on Trashie.io
          </a>
        </div>
      )}
    </ContentWrapper>
  );
};

export default Coupons;
