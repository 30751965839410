import { Link } from 'react-router-dom';

import {
  HOW_WE_RECYCLE_URL,
  BROWSER_EXTENSION_URL,
  TAKE_BACK_BAG_COLLECTION_URL,
} from '../../config/externalURL';
import { ROUTE_HOME, ROUTE_REWARDS } from '../../config/routes';
import trashieCoinLogo from '../../images/trashie-coin-logo.svg';
import tCoinLogo from '../../images/t-coin-logo-black.svg';
import { isMobile } from '../../../../hooks/useBreakPoint';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import HeaderAccount from './HeaderAccount';

import styles from './Header.module.scss';

const {
  main,
  container,
  logo,
  img,
  ctaContainer,
  cta,
  logoDesktop,
  logoMobile,
} = styles;

const ctaItems = [
  {
    route: TAKE_BACK_BAG_COLLECTION_URL,
    label: 'THE TAKE BACK BAG',
    external: true,
  },
  {
    route: HOW_WE_RECYCLE_URL,
    label: 'HOW WE RECYCLE',
    external: true,
  },
  {
    route: ROUTE_REWARDS,
    label: 'TRASHIECASH™ REWARDS',
  },
  {
    route: BROWSER_EXTENSION_URL,
    label: 'CHROME EXTENSION',
    external: true,
  },
];

const Header = () => {
  const { isSignedIn, userData } = useFirebaseAuth();
  const mobile = isMobile();

  return (
    <div>
      <nav className={main}>
        <div className={container}>
          <Link className={logo} to={ROUTE_HOME}>
            <img className={`${img} ${logoDesktop}`} src={trashieCoinLogo} alt="logo" />
            <img className={`${img} ${logoMobile}`} src={tCoinLogo} alt="logo" />
          </Link>
          {isSignedIn && userData && (
          <>
            {!mobile && (
            <div className={ctaContainer}>
              {ctaItems.map(({ route, label, external }) => (external ? (
                <a className={cta} href={route} rel="noreferrer" key={`headerItem${label}`}>
                  {label}
                </a>
              ) : (
                <Link className={cta} to={route} key={`headerItem${label}`}>
                  {label}
                </Link>
              )))}
            </div>
            )}
            <HeaderAccount />
          </>
          )}
        </div>
      </nav>
    </div>

  );
};

export default Header;
