import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import ConfettiExplosion from 'react-confetti-explosion';
import BgContainer from '../../global/BgContainer/BgContainer';
import CircleArrows from '../../icons/CircleArrows';
import SmilingBagIcon from '../../icons/SmilingBagIcon';
import DropletIcon from '../../icons/DropletIcon';
import BagIcon from '../../icons/BagIcon';
import FootIcon from '../../icons/FootIcon';

const RewardsPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const viewMode = query.get('mode') || '';
  const [isShowSuccessModal, setIsShowSuccessModal] = useState();

  useEffect(() => {
    if (viewMode === 'tbb') {
      setIsShowSuccessModal(true);
    }
  }, []);

  return (
    <>
      <BgContainer bgSrc="images/langing-section-1-hero.png" bgClassName="pt-168 pb-96" containerClassName="d-flex justify-content-center justify-content-md-end">
        <div className="d-flex flex-column align-items-end flex-shrink-1">
          <div className="display-1 text-dark position-relative">
            <span>The Future is Circular</span>
            <CircleArrows className="d-none d-md-block position-absolute top-50 start-100" style={{ transform: 'translate(-100%, -50%)' }} />
          </div>
          <div className="d-flex flex-column col-12 col-md-6 mx-auto mt-96 gap-32">
            <Button variant="light" size="sm" href="/">
              Login
            </Button>
            <Button variant="dark" size="sm" href="/signup">
              Create Account
            </Button>
          </div>
        </div>
      </BgContainer>
      <BgContainer bgClassName="bg-white py-64 py-md-136">
        <Row xs={1} md={3} className="gy-64 gx-xxl-168">
          <Col>
            <div className="d-flex flex-column align-items-center text-center">
              <div className="rounded-circle bg-warning d-flex align-items-center justify-content-center">
                <div className="fs-square m-24 fs-2 text-light">1</div>
              </div>
              <div className="mt-24 mb-16 fs-2">Buy</div>
              <div>
                Everything at For Days comes with a guaranteed Closet Cash credit.
                This includes all of your new For Days’
                clothing and our Take Back Bags.
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column align-items-center text-center">
              <div className="rounded-circle bg-warning d-flex align-items-center justify-content-center">
                <div className="fs-square m-24 fs-2 text-light">2</div>
              </div>
              <div className="mt-24 mb-16 fs-2">Recycle</div>
              <div>
                Send your old crap back for recycling and your Closet Cash
                can be immediately used on new stuff.
              </div>
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column align-items-center text-center">
              <div className="rounded-circle bg-warning d-flex align-items-center justify-content-center">
                <div className="fs-square m-24 fs-2 text-light">3</div>
              </div>
              <div className="mt-24 mb-16 fs-2">Repeat</div>
              <div>
                Buy and Recycle. Over and over again.
                This is the closed loop we all want.
                It’s a revolution… literally.
              </div>
            </div>
          </Col>
        </Row>
      </BgContainer>
      <section className="bg-dark overflow-hidden py-24 text-warning">
        <h3 id="left-to-right">Save the planet. Save $$. Stay fresh and in style without giving into fast fashion. Zero waste. Zero worry. This is a Closed Loop.</h3>
      </section>
      <div className="bg-muted">
        <Row className="g-0">
          <Col>
            <div className="h-100 d-flex flex-column justify-content-center p-32 ps-md-64 pe-md-32 ps-xl-96 pe-xl-80">
              <div className="display-1">What we do with your stuff?</div>
              <p className="fs-3">
                We work with recycling partners to repurpose
                each item and divert them from landfills.
              </p>
              <p className="fs-3">
                Because non-For Days are an unknown blend of materials,
                they are recycled into things like insulation,
                rugs, or cleaning materials.
              </p>
            </div>
          </Col>
          <Col xs={12} md={5}>
            <img src="images/landing-section-2-hero.png" alt="hero" className="w-100 h-100" style={{ objectFit: 'cover' }} />
          </Col>
        </Row>
      </div>
      <BgContainer bgClassName="bg-white pt-32 py-md-80">
        <Row className="gx-xxl-80">
          <Col xs={{ span: 12, order: 'last' }} lg={{ span: 6, order: 'first' }}>
            <SmilingBagIcon width="100%" height="100%" />
          </Col>
          <Col className="d-flex flex-column gap-32 gap-xl-0 justify-content-evenly">
            <h1 className="text-center text-lg-start">You’re helping save</h1>
            <div className="d-flex flex-column gap-32 gap-xl-0 justify-content-evenly h-100">
              <Row className="gx-md-64">
                <Col xs="auto">
                  <DropletIcon className="font-size-140" />
                </Col>
                <Col className="d-flex flex-column justify-content-center">
                  <h1>27K gallons</h1>
                  <h3>of water</h3>
                </Col>
              </Row>
              <Row className="gx-md-64">
                <Col xs="auto">
                  <BagIcon className="font-size-140" />
                </Col>
                <Col className="d-flex flex-column justify-content-center">
                  <h1>25 pounds</h1>
                  <h3>of clothing from landfills</h3>
                </Col>
              </Row>
              <Row className="gx-md-64">
                <Col xs="auto">
                  <FootIcon className="font-size-140" />
                </Col>
                <Col className="d-flex flex-column justify-content-center">
                  <h1>197 pounds</h1>
                  <h3>of CO2 emissions</h3>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </BgContainer>
      <BgContainer bgClassName="bg-primary py-48">
        <Row xs={1} md={2} className="gy-32 gx-md-80 gx-lg-0 gx-xxl-80">
          <Col className="d-flex justify-content-center">
            <div className="col-md-6 pb-md-80">
              <div className="display-1">Join Now!</div>
              <h3>Start your journey in the ciruclar economy now!</h3>
            </div>
          </Col>
          <Col className="d-flex justify-content-center">
            <div className="col-12 col-md-auto d-flex flex-column justify-content-around">
              <div className="text-center">
                <h3>Already have an account?</h3>
                <Button variant="dark" size="sm" className="w-100 mt-8" href="/">
                  Log In
                </Button>
              </div>
              <div className="text-center">
                <div>or</div>
                <Button variant="danger" size="sm" className="w-100 mt-8" href="/signup">
                  Create Account
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </BgContainer>
      <Modal
        show={isShowSuccessModal}
        onHide={() => setIsShowSuccessModal(false)}
        className="overflow-hidden"
      >
        <div className="d-flex justify-content-center">
          {isShowSuccessModal && <ConfettiExplosion />}
        </div>
        <div className="position-relative" style={{ isolation: 'isolate' }}>
          <Modal.Header className="border-0 mb-0 pb-0 position-absolute w-100 d-flex justify-content-end" closeButton style={{ zIndex: '2' }} />
          <Modal.Body className="position-relative p-0">
            <CircleArrows width="100%" height="100%" className="position-absolute w-100 h-100" />
            <div className="position-relative py-40 px-32 text-center">
              <div className="display-2 mb-32">Congrats!</div>
              <h4 className="mb-24 font-600">Thank you for registering your Take Back Bag!</h4>
              <h4 className="font-600">Login to access your closet cash!</h4>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default RewardsPage;
