import { useState } from 'react';
import { Card, Image, Form, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useFirebaseAuth } from '../../global/FirebaseProvider/FirebaseProvider';
import ContentWrapper from '../../global/ContentWrapper';
import { useAlert } from '../../providers/AlertProvider';
import api from '../../../api/api';

const Claim = () => {
  const setAlert = useAlert();
  const query = new URLSearchParams(useLocation().search);
  const queryString = useLocation().search;
  const claimCode = query.get('code') || false;
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const { isSignedIn } = useFirebaseAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (email === '') {
        setAlert({
          type: 'notificaction',
          message: 'Email is required',
        });
        return;
      }
      if (!claimCode) {
        throw new Error('Missing Claim Code');
      }

      const { token } = await api.createClaim({ email, claimCode });

      if (isSignedIn) {
        setSuccessMessage('Your claim has been successfully registered!');
        return;
      }
      await firebase.auth().signInWithCustomToken(token);
      window.location.href = `/cash${queryString}`;
    } catch (error) {
      console.error(error);
      setAlert({
        message: error.message,
        type: 'notification',
      });
    }
  };

  return (
    <ContentWrapper size="xs">
      <Card className="text-center p-48">
        {!successMessage ? (
          <>
            <Image
              className="mx-auto mb-48"
              alt="logo"
              src="//cdn.shopify.com/s/files/1/0503/2601/2056/files/for-days-logo-black_140x.png?v=1614290300"
              style={{ width: '150px' }}
            />
            <h5 className="mb-24">Ready to Claim Your closet cash?</h5>
            <Form
              onSubmit={(e) => handleSubmit(e)}
              className="d-flex flex-column align-items-center w-100"
            >
              <Form.Control
                type="text"
                id="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                variant="dark"
                className="mt-24 w-100"
              >
                Claim
              </Button>
            </Form>
          </>
        ) : (
          <>
            <Image
              className="mx-auto mb-48"
              alt="logo"
              src="//cdn.shopify.com/s/files/1/0503/2601/2056/files/for-days-logo-black_140x.png?v=1614290300"
              style={{ width: '150px' }}
            />
            {successMessage}
            {isSignedIn && (
            <a href="/" className="btn btn-dark btn-block mt-4 mb-2 rounded-pill py-2 w-100">Go To Login</a>
            )}
          </>
        )}
      </Card>
    </ContentWrapper>
  );
};

export default Claim;
