import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { setDocumentTitle } from '../../utils/utils';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { formatMMDDYY } from '../../utils/dates';
import { getUserRewards } from '../../utils/service';
import LoadingBar from '../../global/LoadingBar';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import style from './MyAccount.module.scss';
import { ROUTE_TAKE_BACK_BAGS, ROUTE_PROFILE, ROUTE_MY_REWARDS } from '../../config/routes';
import ItemListContainer from '../../global/ItemListContainer/ItemListContainer';
import ItemList from '../../global/ItemList';
import TakeBackBagsNoData from '../../global/TakeBackBagsNoData';
import crud from '../../../../api/crud';
import { TBB_LIST_BY_USER } from '../../config/service';
import { useAlert } from '../../../providers/AlertProvider';
import MyRewardsList from '../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { LIST_SORT_DIRECTION, PREVIEW_LIST_PAGE_SIZE, INITIAL_PAGE } from '../../config/config';

const {
  main,
  container,
  headerInfo,
  userTitle,
  contentAccount,
  emailTitle,
  sinceTitle,
  button,
  redirectContainer,
  userInfo,
  name,
  tbbNoDataSection,
  sectionContainer,
  spliter,
} = style;

const MyAccount = () => {
  const { userData, isLoading: isLoadingFirebaseAuth } = useFirebaseAuth();
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [loadingBags, setLoadingBags] = useState(false);
  const [tbbData, setTbbData] = useState([]);
  const [listData, setListData] = useState([]);
  const history = useHistory();
  const setAlert = useAlert();

  const bagsData = useCallback(
    async () => {
      setLoadingBags(true);
      try {
        const { result } = await crud.get({ path: `${TBB_LIST_BY_USER}?page=${INITIAL_PAGE}&pageSize=${PREVIEW_LIST_PAGE_SIZE}` });
        const data = result.map((redemption) => {
          let parsedRedemption;
          if (redemption.labeldata) {
            parsedRedemption = {
              ...redemption,
              labeldata: JSON.parse(redemption.labeldata),
            };
          } else {
            parsedRedemption = redemption;
          }
          return parsedRedemption;
        });
        setTbbData(data);
      } catch (error) {
        setTbbData([]);
        setAlert({
          type: 'alert',
          message: error.error.message,
          error: error.error.message,
        });
      }
      setLoadingBags(false);
    },
    [],
  );

  const loadRewards = useCallback(async () => {
    setLoadingRewards(true);

    const { data: rewardsData, error: rewardsDataError } = await getUserRewards({
      pageSize: PREVIEW_LIST_PAGE_SIZE,
      page: INITIAL_PAGE,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
    });

    if (rewardsDataError) {
      setListData([]);
      setLoadingRewards(false);

      return;
    }

    const {
      result: rewards,
    } = rewardsData;

    setListData(rewards);
    setLoadingRewards(false);
  }, []);

  useEffect(() => {
    if (isLoadingFirebaseAuth) {
      return;
    }

    if (userData?.error) {
      setAlert(userData.error);
    }

    bagsData();
    loadRewards();
  }, [userData?.error, isLoadingFirebaseAuth]);

  useEffect(() => {
    setDocumentTitle('My Account');
  }, []);

  return (
    <div className={main}>
      <div className={container}>
        {isLoadingFirebaseAuth ? <LoadingBar /> : (
          <div className={contentAccount}>
            <div className={headerInfo}>
              <div>
                <div className={userTitle}>{userData.firstName ? `Hi, ${userData.firstName}` : 'My Account'}</div>
                <div className={emailTitle}>{userData.email}</div>
              </div>
              <div className={sinceTitle}>{`Member Since: ${formatMMDDYY(userData.memberSince)}`}</div>
              <div className={redirectContainer}>
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  size={BUTTON_SIZE.MEDIUM}
                  className={button}
                  onClick={() => history.push(ROUTE_MY_REWARDS)}
                >
                  MY Wallet
                </Button>
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  size={BUTTON_SIZE.MEDIUM}
                  className={button}
                  onClick={() => history.push(ROUTE_TAKE_BACK_BAGS)}
                >
                  My TAKE BACK BAGS
                </Button>
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  size={BUTTON_SIZE.MEDIUM}
                  className={button}
                  onClick={() => history.push(ROUTE_PROFILE)}
                >
                  account details
                </Button>
              </div>
            </div>

            <div className={sectionContainer}>
              <ItemListContainer title="My Wallet" redirectText="VIEW ALL" route={ROUTE_MY_REWARDS}>
                {!loadingRewards && !listData.length ? (
                  <ZeroState />
                ) : (
                  (loadingRewards && (
                    <LoadingBar />
                  )) || (
                    <MyRewardsList rewardsList={listData} />
                  )
                )}
              </ItemListContainer>
              <hr className={spliter} />
              <ItemListContainer title="my take back bags" redirectText="VIEW ALL" route={ROUTE_TAKE_BACK_BAGS}>
                {!loadingBags && !tbbData.length ? (
                  <div className={tbbNoDataSection}>
                    <TakeBackBagsNoData />
                  </div>
                ) : ((loadingBags && <LoadingBar />) || (
                <ItemList
                  dataList={tbbData}
                  setTbbData={setTbbData}
                />
                ))}
              </ItemListContainer>
              <hr className={spliter} />
              <ItemListContainer title="DETAILS">
                <div className={userInfo}>
                  <div className={name}>
                    UPDATE YOUR NAME OR PASSWORD
                  </div>
                  <Button
                    type={BUTTON_TYPE.SECONDARY}
                    size={BUTTON_SIZE.LARGE}
                    onClick={() => history.push(ROUTE_PROFILE)}
                  >
                    UPDATE NOW
                  </Button>
                </div>
              </ItemListContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
