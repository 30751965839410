import {
  ROUTE_SIGNUP,
  ROUTE_LOGIN,
  ROUTE_LOGIN_WITH_LINK,
  ROUTE_HOME,
  ROUTE_LOGOUT,
  ROUTE_SETTINGS,
  ROUTE_ADDRESSES,
  ROUTE_REDEEM,
  ROUTE_DASHBOARD,
  ROUTE_PROFILE,
  ROUTE_REGISTER,
  ROUTE_MY_REWARDS,
  ROUTE_TAKE_BACK_BAGS,
  ROUTE_TAKE_BACK_BAGS_DETAILS,
  ROUTE_MY_ACCOUNT,
  TYPE_SEARCH_PARAM,
  EMAIL_SEARCH_PARAM,
  ROUTE_REWARD,
  PROMO_ID_PARAM,
  ROUTE_REWARDS,
  ROUTE_STORE,
  WF_RETURN_URL_SEARCH_PARAM,
  CATEGORY_SEARCH_PARAM,
  ROUTE_TRANSACTIONS,
  ROUTE_OFFERS,
  SORT_SEARCH_PARAM,
  FILTER_SEARCH_PARAMS,
  ROUTE_REWARDS_OLD,
  ROUTE_DEALS,
  REGISTER_SIGNED_AT_PARAM,
  REGISTER_SIGN_PARAM,
  REGISTER_CODE_PARAM,
  REGISTER_DATA_PARAM,
} from '../config/routes';

const ROUTE_BASE_REWARD_INFO = `${ROUTE_REWARDS}${ROUTE_REWARD}`;
const ROUTE_BASE_REWARDS_STORE = `${ROUTE_REWARDS}${ROUTE_STORE}`;

const isLoginPath = (path) => path === ROUTE_LOGIN;

const isSignupPath = (path) => path === ROUTE_SIGNUP;

const isTrackedPath = (path) => !isLoginPath(path) && !isSignupPath(path);

const isRedeemPath = (path) => path === ROUTE_REDEEM || path === ROUTE_REGISTER;

const isContainerPath = (path) => (
  [
    ROUTE_HOME,
    ROUTE_REDEEM,
    ROUTE_REGISTER,
  ].includes(path)
);

const isTrashieContainerPublicPath = (path) => (
  [
    ROUTE_LOGIN,
    ROUTE_LOGIN_WITH_LINK,
    ROUTE_SIGNUP,
  ].includes(path)
);

const isTrashieContainerPath = (path) => (
  [
    ROUTE_LOGOUT,
    ROUTE_SETTINGS,
    ROUTE_ADDRESSES,
    ROUTE_DASHBOARD,
    ROUTE_PROFILE,
    ROUTE_MY_REWARDS,
    ROUTE_TAKE_BACK_BAGS,
    ROUTE_TAKE_BACK_BAGS_DETAILS,
    ROUTE_MY_ACCOUNT,
    ROUTE_REWARDS,
    ROUTE_REWARDS_OLD,
    ROUTE_TRANSACTIONS,
    ROUTE_OFFERS,
    ROUTE_DEALS,
  ].includes(path) ||
  path.match(ROUTE_REWARD) ||
  path.match(ROUTE_REWARDS) ||
  path.match(ROUTE_DEALS)
);

function getSearchParam(query, searchParam) {
  if (![
    TYPE_SEARCH_PARAM,
    EMAIL_SEARCH_PARAM,
    PROMO_ID_PARAM,
    WF_RETURN_URL_SEARCH_PARAM,
    CATEGORY_SEARCH_PARAM,
    SORT_SEARCH_PARAM,
    FILTER_SEARCH_PARAMS.TYPE,
    FILTER_SEARCH_PARAMS.MIN_VALUE,
    FILTER_SEARCH_PARAMS.MAX_VALUE,
    FILTER_SEARCH_PARAMS.OTHER,
    REGISTER_CODE_PARAM,
    REGISTER_SIGN_PARAM,
    REGISTER_SIGNED_AT_PARAM,
    REGISTER_DATA_PARAM,
  ].includes(searchParam)) {
    return '';
  }

  return query.has(searchParam) ? decodeURIComponent(query.get(searchParam)) : '';
}

const isAHiddenFooterPath = (path) => {
  const routes = [ROUTE_BASE_REWARDS_STORE, ROUTE_BASE_REWARD_INFO];
  return routes.some(route => path.includes(route));
};

export {
  isLoginPath,
  isSignupPath,
  isTrackedPath,
  isRedeemPath,
  isContainerPath,
  isTrashieContainerPublicPath,
  isTrashieContainerPath,
  getSearchParam,
  isAHiddenFooterPath,
};
